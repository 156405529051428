import React, { Component } from 'react';
import _ from 'lodash';
import update from 'immutability-helper';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withToast } from 'material-ui-toast-redux';

import { fetchAllergens } from 'actions/Allergens';
import { fetchDepartments } from 'actions/Departments';
import { fetchKitchens } from 'actions/Kitchens';

import { fetchIngredient, fetchIngredients } from 'actions/Ingredients';

import gastro from 'helpers/gastro';
import { get, post, put } from 'helpers/apiHelpers';
import { countDecimal, toDecimalPlaces } from 'helpers/helpers';

import defaultState from './defaultState';
import { withTranslation } from 'react-i18next';
import TOAST_DURATIONS from 'helpers/toastDurations';

const RecipesContainer = WrappedComponent =>
  class extends Component {
    state = {
      ...defaultState,
      isLoading: true,
      isCommonWot: false,
      commonWotValue: null,
    };

    recipeId = this.props.match.params.id;
    editing = !!this.props.match.params.id;

    componentDidMount = async () => {
      get('/tags', { pagination: false }).then(response => {
        var seenKeys = Object.create(null);
        var result = response['hydra:member'].filter(obj =>
          seenKeys[obj.value] ? false : (seenKeys[obj.value] = true)
        );
        this.setState({
          tags: result,
        });

        if (this.props.location.search.includes('savedRecipe')) {
          const savedRecipe = JSON.parse(localStorage.getItem('savedRecipe'));

          this.setState(savedRecipe);
          localStorage.removeItem('savedRecipe');
        }
      });

      this.props.fetchAllergens();
      this.props.fetchKitchens();
      this.props.fetchDepartments();
      this.props.fetchIngredients({
        pageSize: 30,
        pages: 1,
        filtered: [],
        sorted: [],
      });

      //Edit recipe
      this.editing && this.getRecipe();
      this.setState({ isLoading: false });
    };

    getRecipe = async () => {
      await gastro.get(`/recipes/${this.recipeId}`).then(async response => {
        const recipe = response.data;

        const IngredientsPromises = recipe.ingredients.map(async el => {
          const ingredient = await this.props.fetchIngredient(
            el.ingredient.split('/')[2]
          ); // get only Id

          return {
            ...ingredient,
            recipeIngredientId: el['@id'],
            quantity: el.quantity,
            thermalProcessing: parseFloat(el.thermalProcessing.toFixed(2)),
            workingOnMachining: parseFloat(el.workingOnMachining.toFixed(2)),
          };
        });

        const ingredients = await Promise.all(IngredientsPromises);

        this.setState({
          type: recipe.type,
          workNameState: recipe.workName,
          clientNameState: recipe.clientName,
          purposeState: recipe.purpose,
          difficultyState: recipe.difficulty,
          selectedTags: recipe.tags,
          recipeState: recipe.recipe,
          preparationTime: recipe.preparationTime,
          userIngredients: ingredients.map(ingredient => {
            const ingredientQuantity =
              countDecimal(ingredient.quantity) <=
              this.props.dishIngredientsDecimalPlaces
                ? ingredient.quantity
                : toDecimalPlaces(
                    ingredient.quantity,
                    this.props.dishIngredientsDecimalPlaces
                  );

            return {
              ...ingredient,
              weight: (ingredient.weight * ingredientQuantity).toFixed(2),
              weightDefault: ingredient.weight,
              userValue: ingredientQuantity,
              wpOM: (
                (ingredient.workingOnMachining / 100) *
                (ingredient.weight * ingredientQuantity)
              ).toFixed(2),
            };
          }),
          kitchenImageState: recipe.kitchenImage?.['@id'] || null,
          kitchenImageStateUrl: recipe.kitchenImage?.contentUrl || null,
          clientImageState: recipe.clientImage?.['@id'] || null,
          clientImageStateUrl: recipe.clientImage?.contentUrl || null,
          defaultWorker: recipe?.defaultWorker ?? null,
          departmentState: recipe?.department ?? null,
          kitchenState: recipe?.kitchen ?? null,
        });
      });
    };

    handleChange = event => {
      this.setState({ [event.target.name]: event.target.value });
    };

    handleTagChange = e => {
      this.setState({
        selectedTags: e,
      });
    };

    selectEmployee = e => {
      this.setState({
        defaultWorker: e,
      });
    };

    addIngredient = ingredientToAdd => {
      if (
        !this.state.userIngredients.some(
          ingredient => ingredient['@id'] === ingredientToAdd['@id']
        )
      ) {
        this.setState({
          userIngredients: [
            ...this.state.userIngredients,
            {
              ...ingredientToAdd,
              userValue: 1,
              weightDefault: ingredientToAdd.weight,
              wpOM: (
                (ingredientToAdd.workingOnMachining / 100) *
                ingredientToAdd.weight
              ).toFixed(2),
            },
          ],
        });
      } else {
        alert('Ten składnik został już dodany');
        return null;
      }
    };

    removeIngredient = ingredient => {
      this.setState({
        userIngredients: this.state.userIngredients.filter(
          el => el.id !== ingredient.id
        ),
      });
    };

    getNewWpOM = (index, newWeight, newWOM) => {
      const workingOnMachining =
        newWOM ?? this.state.userIngredients[index].workingOnMachining;

      return ((workingOnMachining / 100) * newWeight).toFixed(2);
    };

    handleUserValue = (index, event) => {
      const value = event.target.value;
      const newIserValue =
        countDecimal(value) <= this.props.dishIngredientsDecimalPlaces
          ? value
          : toDecimalPlaces(value, this.props.dishIngredientsDecimalPlaces);

      const weightDefault = this.state.userIngredients[index].weightDefault;

      const newWeight = (newIserValue * weightDefault).toFixed(2);
      const newWpOM = this.getNewWpOM(index, newWeight);

      this.setState({
        userIngredients: update(this.state.userIngredients, {
          [index]: {
            userValue: { $set: newIserValue },
            weight: { $set: newWeight },
            wpOM: { $set: newWpOM },
          },
        }),
      });
    };

    handleQuantity = (index, event) => {
      const weightDefault = this.state.userIngredients[index].weightDefault;

      const value = event.target.value;
      const newWeight =
        countDecimal(value) <= 2 ? value : toDecimalPlaces(value);

      const userValue = newWeight / weightDefault;
      const newUserValue =
        countDecimal(userValue) <= this.props.dishIngredientsDecimalPlaces
          ? userValue
          : toDecimalPlaces(userValue, this.props.dishIngredientsDecimalPlaces);

      const newWpOM = this.getNewWpOM(index, newWeight);

      this.setState({
        userIngredients: update(this.state.userIngredients, {
          [index]: {
            userValue: {
              $set: newUserValue,
            },
            weight: {
              $set: newWeight,
            },
            wpOM: {
              $set: newWpOM,
            },
          },
        }),
      });
    };

    handleThermalProcessing = (index, event) => {
      const value = event.target.value;
      const newValue =
        countDecimal(value) <= 2 ? value : toDecimalPlaces(value);

      this.setState({
        userIngredients: update(this.state.userIngredients, {
          [index]: {
            thermalProcessing: { $set: parseFloat(newValue) },
          },
        }),
      });
    };

    handleWorkingOnMachining = (index, event) => {
      const weight = this.state.userIngredients[index].weight;

      const value = event.target.value;
      const newWorkingOnMachining =
        countDecimal(value) <= 2 ? value : toDecimalPlaces(value);
      const newWpOM = this.getNewWpOM(index, weight, newWorkingOnMachining);

      this.setState({
        userIngredients: update(this.state.userIngredients, {
          [index]: {
            workingOnMachining: {
              $set: parseFloat(newWorkingOnMachining),
            },
            wpOM: { $set: newWpOM },
          },
        }),
      });
    };

    handleWpOM = (index, event) => {
      const value = event.target.value;
      const newWpOM = countDecimal(value) <= 2 ? value : toDecimalPlaces(value);

      const newWeight =
        newWpOM / (this.state.userIngredients[index].workingOnMachining / 100);

      const weightDefault = this.state.userIngredients[index].weightDefault;
      const newValue2 =
        countDecimal(newWeight) <= 2 ? newWeight : toDecimalPlaces(newWeight);

      const userValue = newValue2 / weightDefault;
      const newUserValue =
        countDecimal(userValue) <= this.props.dishIngredientsDecimalPlaces
          ? userValue
          : toDecimalPlaces(userValue, this.props.dishIngredientsDecimalPlaces);

      this.setState({
        userIngredients: update(this.state.userIngredients, {
          [index]: {
            userValue: {
              $set: newUserValue,
            },
            weight: {
              $set: newValue2,
            },
            wpOM: {
              $set: newWpOM,
            },
          },
        }),
      });
    };

    getAllergens = () =>
      _.uniq(
        _.concat(
          ...this.state.userIngredients.map(ingredient =>
            ingredient.allergens.map(allergen => allergen.value)
          )
        )
      );

    getImage = (stateName, data) => {
      this.setState({
        [stateName]: data?.['@id'] || null,
        [`${stateName}Url`]: data?.contentUrl || null,
      });
    };

    removeImage = stateName => {
      this.setState({
        [stateName]: null,
        [`${stateName}Url`]: null,
      });
    };

    filterIngredients = async e => {
      return this.props.fetchIngredients({
        pageSize: 30,
        page: 0,
        sorted: [],
        filtered: [
          {
            id: '_orX',
            value: [[{ id: e, name: e, workName: e }]],
          },
        ],
      });
    };

    validateIngredients = () => {
      return this.state.userIngredients.length > 0;
    };

    saveToLs = () => {
      const recipeToSave = JSON.parse(JSON.stringify(this.state));

      recipeToSave.userIngredients.forEach(
        ingredient => (ingredient.recipeIngredientId = null)
      );

      localStorage.setItem('savedRecipe', JSON.stringify(recipeToSave));
    };

    handleSubmit = () => {
      if (!this.validateIngredients()) {
        return this.props.openToast({
          messages: [
            this.props.t(
              'errors.recipeRequiresIngredients',
              'Aby stworzyć przepis, dodaj składniki'
            ),
          ],
          type: 'error',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      }

      if (!this.validateForm()) {
        return this.props.openToast({
          messages: [
            this.props.t(
              'errors.fillAllRequiredFields',
              'Wypelnij wszystkie obowiązkowe pola oznaczone gwiazdką'
            ),
          ],
          type: 'error',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      }

      const data = {
        type: this.state.type,
        workName: this.state.workNameState,
        clientName: this.state.clientNameState,
        difficulty: this.state.difficultyState,
        tags: this.state.selectedTags,
        recipe: this.state.recipeState,
        preparationTime: parseFloat(this.state.preparationTime),
        ingredients: this.state.userIngredients.map(ingredient => {
          return {
            '@id': ingredient.recipeIngredientId,
            ingredient: ingredient['@id'],
            quantity: parseFloat(ingredient.userValue),
            thermalProcessing:
              ingredient.thermalProcessing === null
                ? 0
                : ingredient.thermalProcessing,
            workingOnMachining:
              ingredient.workingOnMachining === null
                ? 0
                : ingredient.workingOnMachining,
          };
        }),
        kitchenImage: this.state.kitchenImageState,
        defaultWorker:
          this.state.defaultWorker?.['@id'] ?? this.state.defaultWorker,
        clientImage: this.state.clientImageState,
        department: this.state.departmentState,
        kitchen: this.state.kitchenState,
      };

      const isEdit = this.props.location.pathname.includes('edit');
      const action = isEdit
        ? put(`/recipes/${this.recipeId}`, data)
        : post('/recipes', data);

      action.then(() => this.props.history.push('/admin/recipes'));
    };

    validateForm = () => {
      return this.state.workNameState && this.state.difficultyState;
    };

    handleClickCommonWot = () => {
      this.setState({ isCommonWot: !this.state.isCommonWot });
    };

    handleChangeCommonWotValue = event => {
      const { value } = event.target;
      if (!value) return;
      this.setState(prevState => ({
        ...prevState,
        commonWotValue: value,
        userIngredients: prevState.userIngredients.map(obj => ({
          ...obj,
          thermalProcessing: parseFloat(value),
        })),
      }));
    };

    render() {
      return (
        <WrappedComponent
          {...this.state}
          recipeId={this.recipeId}
          allergens={this.props.allergens.map(allergen => ({
            ...allergen,
            name: allergen.value,
          }))}
          departments={this.props.departments.map(department => ({
            ...department,
            name: department.value,
          }))}
          kitchens={this.props.kitchens.map(kitchen => ({
            ...kitchen,
            name: kitchen.value,
          }))}
          newRecipe={this.newRecipe}
          ingredients={this.props.ingredients.map(ingredient => ({
            ...ingredient,
            label: ingredient.name,
            value: ingredient.name,
          }))}
          includeWorkName
          filter={this.filterIngredients}
          saveToLs={this.saveToLs}
          getImage={this.getImage}
          handleWpOM={this.handleWpOM}
          removeImage={this.removeImage}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          getAllergens={this.getAllergens()}
          addIngredient={this.addIngredient}
          selectEmployee={this.selectEmployee}
          handleQuantity={this.handleQuantity}
          renderDataTable={this.renderDataTable}
          handleTagChange={this.handleTagChange}
          handleUserValue={this.handleUserValue}
          removeIngredient={this.removeIngredient}
          handleThermalProcessing={this.handleThermalProcessing}
          handleWorkingOnMachining={this.handleWorkingOnMachining}
          isEdit={this.props.location.pathname.includes('edit')}
          handleHistory={() =>
            this.setState({ historyHidden: !this.state.historyHidden })
          }
          handleClickCommonWot={this.handleClickCommonWot}
          handleChangeCommonWotValue={this.handleChangeCommonWotValue}
        />
      );
    }
  };

const mapStateToProps = state => ({
  ingredients: state.Ingredients.ingredients,
  allergens: state.Allergens.allergens,
  purposes: state.Purposes.purposes,
  dishIngredientsDecimalPlaces:
    state?.Company?.dietarySetup?.dishIngredientsDecimalPlaces,
  departments: state.Departments.departments,
  kitchens: state.Kitchens.kitchens,
});

const mapDispatchToProps = {
  fetchIngredient,
  fetchIngredients,
  fetchAllergens,
  fetchDepartments,
  fetchKitchens,
};

export default compose(
  withToast,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  RecipesContainer
);
