import React, { useEffect, useState } from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { isGranted } from 'helpers/helpers';
import {
  ROLE_SHOW_IMPORTER_TASK,
  ROLE_CREATE_DISCOUNT_CODE,
  ROLE_CREATE_IMPORTER_TASK,
  ROLE_IMPORT_DISCOUNT_CODE,
} from 'helpers/roles';
import { connect } from 'react-redux';
import { fetchDiets } from '../../actions/Diets';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withToast } from 'material-ui-toast-redux';
import Button from 'components/CustomButtons/Button';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import InfoModal from 'components/InfoModal/InfoModal';
import MediaUpload from 'components/MediaUpload/MediaUpload';
import { post } from 'helpers/apiHelpers';
import gastro from 'helpers/gastro';
import importCSVExample from './importExample/import-discount-codes-csv-example.csv';
import importCSVExampleEn from './importExample/import-discount-codes-csv-example-en.csv';
import { currencyToSymbol } from 'utils/currencies';
import TOAST_DURATIONS from 'helpers/toastDurations';

const List = ({
  t,
  i18n,
  diets,
  classes,
  history,
  openToast,
  fetchDiets,
  multinational,
}) => {
  const { language } = i18n;
  const { currency } = multinational;

  const [state, setState] = useState({
    file: null,
    openedModal: false,
    isImporting: false,
    importButtonClicked: false,
  });

  useEffect(() => {
    fetchDiets();
  }, []);

  const toggleModal = () => {
    setState(prevState => ({
      ...prevState,
      openedModal: !prevState.openedModal,
    }));
  };

  const importIngredients = () => {
    setState(prev => ({ ...prev, importButtonClicked: true }));
    uploadFile(state.file).then(file => {
      postImporterTask(file, state.modalMode);
    });
  };

  const postImporterTask = (file, mode) => {
    setState(prev => ({ ...prev, isImporting: true }));

    const data = {
      type: 'DISCOUNT_CODES',
      file: file['@id'],
      config: {
        skipHeader: true,
        delimiter: ';',
      },
    };

    post('/importer-tasks', data).then(
      res => {
        setState(prev => ({
          ...prev,
          openedModal: false,
          file: null,
          importButtonClicked: false,
        }));
        setState(prev => ({ ...prev, isImporting: false }));
        return openToast({
          messages: [
            t('form.importerInfo26', 'Import został dodany do kolejki.'),
            t('form.importerInfo23', 'Po chwili, odśwież stronę.'),
          ],
          type: 'success',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      },
      error => {
        setState(prev => ({ ...prev, isImporting: false }));
        return openToast({
          messages: [
            t(
              'form.importerInfo25',
              'Nie udało się zaimportować kodów rabatowych'
            ),
          ],
          type: 'error',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      }
    );
  };

  const uploadFile = async file => {
    let data = new FormData();
    data.append('file', file);

    let response = await gastro.post('/media', data);

    return new Promise((resolve, reject) => {
      resolve(response.data);
    });
  };

  const loadExample = language => {
    switch (language) {
      case 'en':
        return importCSVExampleEn;
      default:
        return importCSVExample;
    }
  };

  const newSize = () => history.push(`${window.location.pathname}/add`);

  return (
    <AdminTable title={t('list.promoCodesList')}>
      <GridContainer>
        <GridItem sm={10}>
          {isGranted(ROLE_SHOW_IMPORTER_TASK) &&
            isGranted(ROLE_IMPORT_DISCOUNT_CODE) && (
              <Button
                style={{ marginTop: '10px' }}
                color={'info'}
                onClick={() => {
                  toggleModal();
                }}
              >
                {t('form.import.discountCodes', 'Importuj kody rabatowe')}
              </Button>
            )}
        </GridItem>
        <GridItem sm={2}>
          {isGranted(ROLE_CREATE_DISCOUNT_CODE) && (
            <FormControlButtons
              classes={classes}
              handleSubmit={newSize}
              submitText={t('list.generateCode')}
            />
          )}
        </GridItem>
      </GridContainer>
      <DataGrid
        refreshOnFetch
        actions={true}
        previewPath={`${window.location.pathname}/preview`}
        remove={true}
        export={true}
        editPath={`${window.location.pathname}/edit`}
        paginationTop={true}
        paginationBottom={false}
        url="/discount-codes"
        reportName={'discountcode'}
        columns={columnConfig(diets, t, openToast)}
        role="DISCOUNT_CODE"
        manipulateQuery={(_, query) => {
          if (query.discount) {
            query['discount[]'] = query.discount;
            query['discount[_operator]'] = 'eq';
          }

          return query;
        }}
        canRemoveChecker={(_, row) => row.orders.length === 0}
        defaultFiltered={[{ id: 'archived', value: false }]}
      />

      <InfoModal
        modalState={state.openedModal}
        handleClose={() => toggleModal()}
        modalContent={
          <div>
            <span>
              <h1>{t('discountcode.Import', 'Import kodów rabatowych')}</h1>
              <p style={{ textAlign: 'left' }}>
                <ul
                  dangerouslySetInnerHTML={{
                    __html: t('discountCode.importerModalInfo', {
                      importCSVExample: loadExample(language),
                      interpolation: { escapeValue: false },
                      currency: currencyToSymbol(currency),
                    }),
                  }}
                />
              </p>
            </span>

            <MediaUpload
              file={state}
              setFile={file => setState(prev => ({ ...prev, file: file }))}
              buttonText={t('form.selectCSVFile')}
              addButtonProps={{
                color: 'info',
                round: false,
              }}
              changeButtonProps={{
                color: 'info',
                round: false,
              }}
              removeButtonProps={{
                color: 'danger',
                round: false,
              }}
            />
            <div>
              {isGranted(ROLE_CREATE_IMPORTER_TASK) &&
                isGranted(ROLE_IMPORT_DISCOUNT_CODE) && (
                  <Button
                    color={state.file ? 'success' : 'default'}
                    disabled={
                      !state.file ||
                      state.isImporting ||
                      state.importButtonClicked
                    }
                    onClick={importIngredients}
                  >
                    {t('form.import')}
                  </Button>
                )}
              <Button onClick={() => toggleModal()}>{t('form.close')}</Button>
            </div>
          </div>
        }
        closeButtonText={t('form.close')}
        hideDefaultCloseButton
      />
    </AdminTable>
  );
};

const mapStateToProps = state => ({
  multinational: state.Brands.brand.multinational,
  diets: state.Diets.diets,
});

const mapDispatchToProps = dispatch => ({
  fetchDiets: () => dispatch(fetchDiets()),
});

const enhance = compose(
  withStyles(buttonsStyle),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withToast
);

export default enhance(List);
