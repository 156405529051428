import React from 'react';
import produce from 'immer';
import Dialog from '@material-ui/core/Dialog';
import Settings from '@material-ui/icons/Settings';
import PropTypes from 'prop-types';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { compose } from 'redux';
import { withToast } from 'material-ui-toast-redux';
import { withTranslation } from 'react-i18next';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import { get, post, put } from 'helpers/apiHelpers';
import { ROLE_EDIT_COMPANY } from 'helpers/roles';
import { combineStyles, isGranted } from 'helpers/helpers';

import Button from 'components/CustomButtons/Button';
import GridItem from 'components/Grid/GridItem';
import ModalButton from 'components/CustomButtons/Button';
import GridContainer from 'components/Grid/GridContainer';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import withErrorHandler from 'components/HOC/withErrorHandler';

import SelectInput from '../FormSelect/SelectInput';
import { connect } from 'react-redux';
import CardIcon from '../Card/CardIcon';
import Assignment from '@material-ui/icons/Assignment';
import CardHeader from '../Card/CardHeader';
import {
  goldColor,
  hexToRgb,
} from '../../assets/jss/material-dashboard-pro-react';
import Checkbox from 'components/CustomCheckbox/Checkbox';
import { Check } from '@material-ui/icons';
import { FormControlLabel } from '@material-ui/core';
import TOAST_DURATIONS from 'helpers/toastDurations';

export const ReportLabelDivider = ({ label, icon }) => {
  return (
    <CardHeader color="primary" icon>
      <CardIcon
        color="white"
        style={{
          background: goldColor,
          boxShadow:
            '0 4px 20px 0 rgba(' +
            hexToRgb(goldColor) +
            ',.14), 0 7px 10px -5px rgba(' +
            hexToRgb(goldColor) +
            ',.4)',
          justifyContent: 'left',
          display: 'flex',
          alignItems: 'center',
          padding: 5,
        }}
      >
        {icon ?? <Assignment />} {label}
      </CardIcon>
    </CardHeader>
  );
};

export const choiceTrueFalseField = ({ t, field, size = 12 }) => ({
  size,
  field,
  inputType: 'select',
  inputProps: {
    multiple: false,
    options: [
      {
        value: true,
        label: t(`common.yes`),
      },
      {
        value: false,
        label: t(`common.no`),
      },
    ],
  },
});

class ReportConfigurator extends React.Component {
  state = {
    modal: false,
    loading: true,
    errors: {},
    configurations: {},
  };
  toggleModal = () =>
    this.setState(prevState => ({ ...prevState, modal: !this.state.modal }));

  startLoading = () =>
    this.setState(prevState => ({ ...prevState, loading: true }));

  stopLoading = () =>
    this.setState(prevState => ({ ...prevState, loading: false }));

  getReportsConfigUrl = () =>
    `/company/${this.props.companyId}/reports-configuration`;

  getBrandConfig = () => `/brands/${this.props.brandId}`;

  updateReportsConfigUrl = () => `/company/reports-configuration`;

  setErrors = violations =>
    this.setState(prevState => ({
      ...prevState,
      errors: violations.reduce((prev, curr) => {
        prev[curr.propertyPath] = curr.message;
        return prev;
      }, {}),
    }));

  componentDidMount = () => {
    const { report } = this.props;

    if (isGranted(ROLE_EDIT_COMPANY)) {
      this.startLoading();

      get(this.getReportsConfigUrl()).then(data => {
        this.setState(
          produce(draft => {
            draft.configurations = data.reportsConfigurations;
          })
        );
        this.stopLoading();
      });
      get(this.getBrandConfig()).then(data => {
        this.setState(
          produce(draft => {
            if (draft.configurations[report]) {
              draft.configurations[report].codeSeparator = data?.codeSeparator;
              draft.configurations[report].allowPrivateDataInReport =
                data?.allowPrivateDataInReport;
            }
          })
        );
      });
    }
  };

  handleToggle = name => {
    const { report } = this.props;
    this.setState(
      produce(draft => {
        draft.configurations[report][name] =
          !this.state.configurations[report][name];
      })
    );
  };

  handleInputChange = ({ target: { value, name } }) => {
    const { report, fields } = this.props;

    if (fields.find(({ field }) => field === name).inputType === 'number') {
      value = parseInt(value) || 0;
    }

    this.setState(
      produce(draft => {
        draft.configurations[report][name] = value;
      })
    );
  };

  save = async () => {
    const { companyId, report } = this.props;
    this.props.errorHandlerResetState();
    this.startLoading();

    put(`/brands/${this.props.companyId}`, {
      codeSeparator: this.state.configurations[report].codeSeparator,
      allowPrivateDataInReport:
        this.state.configurations[report].allowPrivateDataInReport,
    }).then(response => {
      console.log(response);
    });

    try {
      await post(this.updateReportsConfigUrl(), {
        company: `/companies/${companyId}`,
        report: report,
        configuration: this.state.configurations[report],
      });

      this.props.openToast({
        messages: [this.props.t('notify.changesSaved')],
        type: 'success',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
      this.toggleModal();
    } catch (error) {
      this.props.errorHandlerCatch(error);
    }

    this.stopLoading();
  };

  fieldResolve = ({ field = '', size, inputType, inputProps = {}, name }) => {
    const { classes, report } = this.props;

    if (inputType === 'divider') {
      return <GridItem xs={size ?? 12}>{inputProps.content}</GridItem>;
    }

    if (inputType === 'select') {
      return (
        <GridItem xs={size ?? 12}>
          <SelectInput
            key={field}
            name={field}
            label={this.props.t(
              `configuration.reports.report.${report}.${field}`
            )}
            classes={classes}
            mapBy="label"
            trackBy="value"
            value={this.state.configurations[report][field]}
            handleChange={this.handleInputChange}
            {...inputProps}
          />
        </GridItem>
      );
    }

    if (inputType === 'checkbox') {
      return (
        <GridItem xs={size ?? 12}>
          <FormControlLabel
            control={
              <Checkbox
                name={field}
                style={{ marginLeft: '-14px' }}
                onClick={() => this.handleToggle(name)}
                checked={this.state.configurations[report][field]}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            label={this.props.t(
              `configuration.reports.report.${report}.${field}`
            )}
          />
        </GridItem>
      );
    }

    return (
      <GridItem xs={size ?? 12}>
        <FormTextInput
          key={field}
          label={this.props.t(
            `configuration.reports.report.${report}.${field}`
          )}
          classes={classes}
          name={field}
          value={this.state.configurations[report][field]}
          handleChange={this.handleInputChange}
          type={inputType}
          errors={this.props.errors}
        />
      </GridItem>
    );
  };

  render() {
    const { classes, report, fields } = this.props;
    if (this.state.configurations[report] === undefined) {
      return null;
    }

    if (this.props.forceOpen) {
      return (
        <AdminTable
          title={this.props.t(
            this.props.title || 'configuration.reports.title'
          )}
          isMarginLeft={true}
        >
          <GridContainer
            justify="flex-end"
            style={{ flexWrap: 'wrap', alignItems: 'end' }}
          >
            {fields.map(field => this.fieldResolve(field))}
          </GridContainer>
          <GridContainer justify="flex-start">
            <GridItem>
              <Button
                onClick={this.toggleModal}
                style={{ margin: '5px' }}
                disabled={this.state.loading}
                className={classes.marginRight}
              >
                {this.state.loading ? (
                  <CircularProgress />
                ) : (
                  this.props.t('form.cancel')
                )}
              </Button>
              <Button
                color={'success'}
                className={classes.marginLeft}
                onClick={this.save}
                disabled={this.state.loading}
              >
                {this.state.loading ? (
                  <CircularProgress />
                ) : (
                  this.props.t('form.save')
                )}
              </Button>
            </GridItem>
          </GridContainer>
        </AdminTable>
      );
    }

    return (
      <>
        <Dialog
          maxWidth={'lg'}
          open={this.state.modal}
          onClose={this.toggleModal}
          style={{ maxWidth: '580px', margin: '0 auto' }}
        >
          <DialogContent>
            <ModalButton
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                cursor: 'pointer',
              }}
              color={'transparent'}
              justIcon
              round
              onClick={this.toggleModal}
            >
              &times;
            </ModalButton>
          </DialogContent>
          <DialogContent>
            <AdminTable title={this.props.t('configuration.reports.title')}>
              <GridContainer
                justify="flex-start"
                style={{ flexWrap: 'wrap', alignItems: 'end' }}
              >
                {fields.map(field => this.fieldResolve(field))}
              </GridContainer>
              <GridContainer justify="flex-end">
                <GridItem>
                  <Button
                    onClick={this.toggleModal}
                    style={{ margin: '5px' }}
                    disabled={this.state.loading}
                    className={classes.marginRight}
                  >
                    {this.state.loading ? (
                      <CircularProgress />
                    ) : (
                      this.props.t('form.cancel')
                    )}
                  </Button>
                  <Button
                    color={'success'}
                    className={classes.marginLeft}
                    onClick={this.save}
                    disabled={this.state.loading}
                  >
                    {this.state.loading ? (
                      <CircularProgress />
                    ) : (
                      this.props.t('form.save')
                    )}
                  </Button>
                </GridItem>
              </GridContainer>
            </AdminTable>
          </DialogContent>
        </Dialog>
        <Settings
          onClick={this.toggleModal}
          style={{ verticalAlign: 'bottom', cursor: 'pointer' }}
        />
      </>
    );
  }
}

ReportConfigurator.propTypes = {
  forceOpen: PropTypes.bool,
  companyId: PropTypes.number.isRequired,
  report: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      size: PropTypes.number.isRequired,
      field: PropTypes.string.isRequired,
      inputProps: PropTypes.object,
      inputType: PropTypes.oneOf(['text', 'number', 'select']).isRequired,
    })
  ),
};

const mapStateToProps = state => {
  return {
    brandId: state.Brands.brand.id,
  };
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withStyles(combinedStyles),
  withToast,
  withErrorHandler,
  withTranslation(),
  connect(mapStateToProps)
);

export default enhance(ReportConfigurator);
