import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { withToast } from 'material-ui-toast-redux';
import produce from 'immer';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import { get, post, put } from 'helpers/apiHelpers';
import { combineStyles, trimSpaces } from 'helpers/helpers';

import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import BasicInfoCard from 'components/CMS/BasicInfoCard';
import DescriptionCard from 'components/CMS/DescriptionCard';
import ClientPanel from './ClientPanel';
import NavTabs from 'components/NavTabs/NavTabs';
import makeStyles from '@material-ui/styles/makeStyles';
import TOAST_DURATIONS from 'helpers/toastDurations';

const initialFormData = {
  active: true,
  category: null,
  clientCost: 0,
  clientVat: 0,
  code: '',
  contractor: null,
  cost: 0,
  defaultUsage: 'FOR_BAG',
  excludedPlaces: [],
  restrictedToDiets: [],
  image: null,
  imageUrl: null,
  name: '',
  notes: '',
  position: 0,
  shortDescription: '',
  stock: 0,
  volume: 0,
  weight: 0,
};

const schema = yup.object().shape({
  clientCost: yup.number().required(),
  clientVat: yup.number().required(),
  code: yup.string().required(),
  name: yup.string().required(),
});

const useStyles = makeStyles({
  root: { marginLeft: '5px', fontSize: '1rem' },
});

const AddonsForm = ({ classes, history, location, match, openToast }) => {
  const isEdit = location.pathname.includes('edit');
  const addonId = match.params.id;
  const { t } = useTranslation();

  const [formData, setFormData] = useState(initialFormData);
  const [categories, setCategories] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [diets, setDiets] = useState([]);
  const [addonSubpageData, setAddonSubpageData] = useState({
    urlSlug: '',
    metaTitle: '',
    metaDescription: '',
  });

  const tooltipStyles = useStyles();
  const [addonDescription, setAddonDescription] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get('/addon-categories', { pagination: false });
        setCategories(response['hydra:member']);
      } catch (error) {}
      try {
        const response = await get('/diets', { pagination: false });
        setDiets(response['hydra:member']);
      } catch (error) {}

      try {
        const response = await get('/contractors', { pagination: false });
        setContractors([
          {
            '@id': null,
            name: t('form.field.contractor.selectOption.noContractor'),
          },
          ...response['hydra:member'],
        ]);
      } catch (error) {}

      if (isEdit) {
        try {
          const response = await get(`/addons/${addonId}`);
          const {
            '@id': iriId,
            '@type': iriType,
            createdAt,
            updatedAt,
            category,
            image,
            ...onlyFormData
          } = response;

          setFormData({
            ...onlyFormData,
            category: category?.['@id'] || null,
            image: image?.['@id'] || null,
            imageUrl: image?.contentUrl || null,
          });

          setAddonSubpageData({
            urlSlug: response.pageSettings.slug ?? '',
            metaTitle: response.seo.title ?? '',
            metaDescription: response.seo.description ?? '',
          });
          setAddonDescription(response.pageSettings.content ?? '');
        } catch (error) {}
      }
    };

    fetchData();
  }, []);

  const handleChange = ({ target: { name, value } }) => {
    setFormData(
      produce(draft => {
        draft[name] = value;
      })
    );
  };

  const handleAddonSubpageInputChange = e => {
    setAddonSubpageData(prevData => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCheckboxClick = ({ target: { name } }) => {
    setFormData(
      produce(draft => {
        draft[name] = !draft[name];
      })
    );
  };

  const getImage = (stateName, data) => {
    setFormData(
      produce(draft => {
        draft[stateName] = data?.['@id'] || null;
        draft[`${stateName}Url`] = data?.contentUrl || null;
      })
    );
  };

  const removeImage = stateName => {
    setFormData(
      produce(draft => {
        draft[stateName] = null;
        draft[`${stateName}Url`] = null;
      })
    );
  };

  const handleSubmit = async () => {
    const isValid = schema.isValidSync(formData);
    if (!isValid) {
      return openToast({
        messages: [t('form.fillAllRequiredFields')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }

    try {
      const {
        clientCost,
        clientVat,
        cost,
        imageUrl,
        position,
        stock,
        volume,
        weight,
        ...restFormData
      } = formData;

      const data = {
        ...trimSpaces(restFormData),
        clientCost: parseFloat(clientCost),
        clientVat: parseFloat(clientVat),
        cost: parseFloat(cost),
        position: parseInt(position),
        stock: parseInt(stock),
        volume: parseFloat(volume),
        weight: parseFloat(weight),
        seo: {
          title: addonSubpageData.metaTitle,
          description: addonSubpageData.metaDescription,
        },
        pageSettings: {
          slug: addonSubpageData.urlSlug,
          content: addonDescription,
        },
      };

      isEdit
        ? await put(`/addons/${addonId}`, data)
        : await post('/addons', data);

      isEdit ? history.push('/admin/addons') : setFormData(initialFormData);

      !isEdit &&
        openToast({
          messages: [t('status.success.save')],
          type: 'success',
          autoHideDuration: TOAST_DURATIONS.SM,
        });

      history.push('/admin/addons');
    } catch ({ response: { data = {}, status } }) {
      if (status === 500) {
        return openToast({
          messages: [
            t('status.error.serverNotResponding'),
            t('status.error.tryAgainLater'),
          ],
          type: 'error',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      }

      openToast({
        messages: [
          t('status.error.somethingWentWrong'),
          t('status.error.fillCorrectForm'),
          '',
          ...(status === 400
            ? (data?.violations ?? []).map(
                ({ message, propertyPath }) => `${propertyPath}: ${message}`
              )
            : []),
        ],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }
  };

  return (
    <>
      <NavTabs
        title={
          isEdit
            ? t('views.addons.addons.editAddon')
            : t('views.addons.addons.newAddon')
        }
        tabs={[
          {
            tabButton: t('views.addons.clientPanel'),
            tabContent: (
              <ClientPanel
                classes={classes}
                tooltipStyles={tooltipStyles}
                formData={formData}
                handleChange={handleChange}
                handleCheckboxClick={handleCheckboxClick}
                categories={categories}
                getImage={getImage}
                removeImage={removeImage}
                contractors={contractors}
                diets={diets}
              />
            ),
          },
          {
            tabButton: t('views.addons.addonSubpage'),
            tabContent: (
              <>
                <BasicInfoCard
                  urlSlug={addonSubpageData.urlSlug}
                  metaTitle={addonSubpageData.metaTitle}
                  metaDescription={addonSubpageData.metaDescription}
                  handleChange={handleAddonSubpageInputChange}
                  subpage="/addons/"
                />
                <DescriptionCard
                  value={addonDescription}
                  setValue={setAddonDescription}
                  title={t('views.addons.addonSubpage.addonDescription')}
                />
              </>
            ),
          },
        ]}
      />

      <FormControlButtons
        classes={classes}
        discardText={t('form.cancel')}
        submitText={t('form.save')}
        cancelPath="/admin/addons"
        handleSubmit={handleSubmit}
      />
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);
const enhance = compose(withToast, withStyles(combinedStyles));

export default enhance(AddonsForm);
