import React from 'react';
import { isGranted, sortByKey } from 'helpers/helpers';
import roles, {
  ROLE_SHOW_MODULE_MENU_SELECTION_ASSISTANT,
} from 'helpers/roles';
//Components
import Dashboard from 'layouts/Dashboard';
import PowerBIStatistics from 'views/PowerBIStatistics';
import MyProfile from 'views/MyProfile/MyProfile';
import Ingredients from 'views/Ingredients';
import Recipes from 'views/Recipes';
import Meals from 'views/MealsNew';
import MealTypes from 'views/MealTypes';
import Archive from 'views/Archive';
import MealSizes from 'views/MealSizes';
import Diets from 'views/Diets';
import Variants from 'views/Variants';
import Packages from 'views/Packages';
import Overproduction from 'views/Overproduction/Overproduction';
import NewPlanner from 'views/MenuPlanner/NewPlanner/Planner';
import MenuCopying from 'views/MenuPlanner/MenuCopying';
import SubMenu from 'views/MenuPlanner/SubMenu/SubMenu.component';
import SubMenuWrapper from 'views/MenuPlanner/SubMenu';
import Summary from 'views/MenuPlanner/Summary/Summary';
import Dictionary from 'views/Dictionary';

import MyCompany from 'views/MyCompany/MyCompany';
import AdditionalOfferPage from 'views/AdditionalOffer/AdditionalOfferPage';
import Employees from 'views/Employees';
import Clients from 'views/Clients';
import Brands from 'views/Brands';
import Roles from 'views/Roles';
import AdminNavLinks from 'views/AdminNavLinks';
import PromoCodes from 'views/PromoCodes';
import MailReminders from 'views/MailReminders';
import SmsReminders from 'views/SmsReminders';
import MailNotifications from 'views/MailNotifications';
import SmsNotifications from 'views/SmsNotifications';
import Zones from 'views/Zones/Zones';
import ZonesCategories from 'views/Zones/ZonesCategories';
import Drivers from 'views/Drivers';
import MenuMessages from 'views/MenuPlanner/MenuMessages';
import PagesCreator from 'views/PagesCreator';
import MassSms from 'views/MassSms';
import MassEmail from 'views/MassEmail';
import Waste from './views/Waste/Waste';
import ReportView from './views/Waste/ReportView';
import Addons from 'views/Addons/Addons/Addons';
import AddonsCategories from 'views/Addons/AddonsCategories/AddonsCategories';
import Contractors from 'views/Contractors/Contractors';

import UsageOfDiscountCodes from 'views/Reports/UsageOfDiscountCodes';
import MoneyBoxTopUp from './views/Reports/MoneyBoxTopUp';

import AddonsReport from 'views/Reports/AddonsReport';
import AddonsByClientReport from 'views/Reports/AddonsByClientReport';
import ShoppingList from 'views/Reports/ShoppingList';
import ForDriver from 'views/Reports/ForDriver';
import OverproductionReport from 'views/Reports/Overproduction';
import LogisticsAddresses from 'views/LogisticsAddresses/List';
import BoxLabels from 'views/Reports/BoxLabels';
import BoxLabelsDateRange from 'views/Reports/BoxLabelsDateRange';
import BoxLabelsMini from 'views/Reports/BoxLabelsMini';
import PdfGenerator from 'views/Reports/PdfGenerator/';
import DishLabels from 'views/Reports/DishLabels';
import RecipeCards from 'views/Reports/RecipeCards';
import RecipeIngredients from 'views/Reports/RecipeIngredients';
import DishCards from 'views/Reports/DishCards';
import Containers from 'views/Reports/Containers';
import SentSms from 'views/Reports/SentSms';
import DishesToProduction from 'views/Reports/DishesToProduction';
import Orders from 'views/Orders/Orders';
import Baskets from 'views/Baskets';
import EcommerceDiets from 'views/Orders/Diets';
import Subscriptions from 'views/Orders/Subscriptions';
import Comments from 'views/Comments/Comments';
import EcommerceBags from 'views/EcommerceBags/EcommerceBags';
import MassActionDietElementsList from 'views/MassAction/DietElements/List';
import MassActionDietElementsForm from 'views/MassAction/DietElements/Form';
import EasyReports from 'components/EasyreportsRedirect/EasyreportsRedirect';
import DishLabelsSubBrands from './views/Reports/DishLabelsSubBrands';
import ActiveBrandRedirect from './views/Brands/ActiveBrandRedirect';
import RecipesChecklist from './views/Reports/RecipesChecklist';
import DishesChecklist from './views/Reports/DishesChecklist';
import FoodCost from './views/Reports/FoodCost';
import IncomeSummary from './views/Reports/IncomeSummary';
import FoodCostBags from './views/Reports/FoodCostBags';
import IncomingSubscriptions from './views/Reports/IncomingSubscriptions';
import ReportsGlobalConfiguration from './views/Reports/ReportsGlobalConfiguration';
import ActiveDiets from './views/Reports/ActiveDiets';
import OrderedDiets from './views/Reports/OrderedDiets';
import PersonalPickups from './views/Reports/PersonalPickups';
import Packers from './views/Reports/Packers';
import Disposal from './views/Reports/Disposal';
import RecipeStickers from './views/Reports/RecipeStickers';
import Shop from 'views/Shop';
import RoutePlanner from './views/RoutePlanner';
import MacroIngredients from './views/Reports/MacroIngredients';

import SettlementsSettings from './views/Settlements/Settings';
// Translations
import LanguageSettings from './views/Translations/LanguageSettings';
import TranslationsAdminPanel from './views/Translations/AdminPanel';
import TranslationsClientPanel from './views/Translations/ClientPanel';
import TranslationsAPI from './views/Translations/API';

// Brand configuration pages
// import BrandInformation from './views/BrandConfiguration/BrandInformation';
import PaymentMethods from './views/BrandConfiguration/PaymentMethods';
import DeliveriesAndOrders from './views/BrandConfiguration/DeliveriesAndOrders';
import DeliveryOptions from './views/BrandConfiguration/DeliveryOptions';
import LoginOptions from './views/BrandConfiguration/LoginOptions';
import EcoContainers from './views/BrandConfiguration/EcoContainers';
import MailAndSms from './views/BrandConfiguration/MailAndSms';
import AnalyticsTools from './views/BrandConfiguration/analyticsTools/AnalyticsTools';
import PowerBI from './views/BrandConfiguration/PowerBI';
// import TestDays from './views/BrandConfiguration/TestDays';
import PanelConfiguration from './views/BrandConfiguration/PanelConfiguration/PanelConfiguration.container';
import ClientPanelDesign from './views/BrandConfiguration/ClientPanelDesign';
import OrderForm from './views/BrandConfiguration/OrderForm';
import Accounting from './views/BrandConfiguration/Accounting';
import { default as MSA } from './views/MenuSelectionAssistant/MenuSelectionAssistant';
import AccessKeys from './views/BrandConfiguration/AccessKeys';
import ProductFeed from './views/BrandConfiguration/ProductFeed';
import {
  Kitchen,
  BagsPackhouse,
  TasksGenerator,
  DishesPackhouse,
} from './views/CateringsFlow';

import {
  AdditionalPrices,
  DietDelivery,
  DietDiscounts,
  DietPrices,
  MoneyBoxConfigurations,
} from 'views/Prices';
//Pages
import LoginPage from 'views/Pages/LoginPage';
import ResetPassword from 'views/Pages/ResetPassword';
import ErrorPage from 'views/Pages/ErrorPage';
import HomeCmsPage from './views/CMS/HomePage/HomePage';
import AddonsCmsPage from './views/CMS/Addons';
import DietsCmsPage from './views/CMS/Diets';
import MealsCmsPage from './views/CMS/Meals';
import ZonesCmsPage from './views/CMS/Zones';
// @material-ui/icons
import Announcement from '@material-ui/icons/Announcement';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Assignment from '@material-ui/icons/Assignment';
import LocalDining from '@material-ui/icons/LocalDining';
import LocalPlay from '@material-ui/icons/LocalPlay';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import Tune from '@material-ui/icons/Tune';
import ColorLens from '@material-ui/icons/ColorLens';
import CheckBox from '@material-ui/icons/CheckBox';
import LocalShipping from '@material-ui/icons/LocalShipping';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import RoomService from '@material-ui/icons/RoomService';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import BarChart from '@material-ui/icons/BarChart';
import TrendingUp from '@material-ui/icons/TrendingUp';
import ExtensionIcon from '@material-ui/icons/Extension';
import { AccountBalance, DeleteOutline, GraphicEq } from '@material-ui/icons';
import Chat from '@material-ui/icons/Chat';

import Book from '@material-ui/icons/Book';
import Settings from '@material-ui/icons/Settings';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import FlagIcon from '@material-ui/icons/Flag';
import { ReactComponent as CalculateIcon } from 'assets/img/CalculateIcon.svg';
// Private route imports
import './i18n';
import AddressRequests from './views/AddressRequest/AddressRequests';
import DietsChecklist from './views/Reports/DietsChecklist';
import AlteredDietsChecklist from './views/Reports/AlteredDietsChecklist';
import TotalBel from './views/Reports/TotalBel';
import EmployeesReport from './views/Reports/EmployeesReport/EmployeesReport';
import BillingReport from './views/Reports/BillingReport';
import AccountantReports from './views/Reports/Accountant';

import DICTIONARIES_TYPES from 'views/Dictionary/dictionariesTypes.const';
import Products from './views/Products';
import RouteManager from 'views/RouteManager';
import BlogCreator from 'views/CMS/Blog/index';
import SummaryV2 from 'views/MenuPlanner/SummaryV2/SummaryV2';
import DishesPhotoGallery from 'views/DishesPhotoGallery/DishesPhotoGallery';
import ActiveDietsTable from './views/Reports/ActiveDietsTable';
import SelectionsV3Codes from 'views/Reports/SelectionsV3Codes';
import ClientsBmi from 'views/ClientsBmi/ClientsBmi';
import FilterLineDiets from 'views/FilterLineDiets';
import CopyingBrandSettings from 'views/CopyingBrandSettings';
import ReportsBoxLabelsBreadcrumbAdditionalContent from 'components/BreadcrumbAdditionalContent/ReportsBoxLabelsBreadcrumbAdditionalContent';
import MailRemindersBreadcrumbAdditionalContent from 'components/BreadcrumbAdditionalContent/MailRemindersBreadcrumbAdditionalContent';

const CalcluateIconTest = () => {
  return (
    <CalculateIcon
      style={{
        fill: 'currentColor',
        top: '3px',
        color: 'inherit',
        width: '30px',
        float: 'left',
        height: '24px',
        opacity: '0.8',
        position: 'inherit',
        textAlign: 'center',
        marginRight: '15px',
        verticalAlign: 'middle',
      }}
    />
  );
};

const dynamicEnv = {
  REACT_APP_ENABLE_EASY_REPORTS: process.env.REACT_APP_ENABLE_EASY_REPORTS,
};

const routes = [
  {
    path: '/dashboard',
    name: 'common.mainMenu.userPanelCaption',
    nameFallback: 'Panel statystyk',
    icon: <DashboardIcon />,
    component: Dashboard,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/my-profile',
    name: 'common.mainMenu.myProfile',
    nameFallback: 'Edycja profilu',
    component: MyProfile,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/login',
    name: 'common.mainMenu.login',
    nameFallback: 'Panel administracyjny Caterings',
    component: props => <LoginPage {...props} />,
    layout: '/auth',
    invisible: true,
  },
  {
    path: '/reset-password/:token',
    name: 'common.mainMenu.passwordReset',
    nameFallback: 'Resetowanie hasła',
    component: props => <ResetPassword {...props} />,
    layout: '/auth',
    invisible: true,
  },
  {
    path: '/error',
    name: 'common.mainMenu.error',
    nameFallback: 'Błąd',
    component: props => <ErrorPage {...props} />,
    layout: '/admin',
    invisible: true,
  },
  {
    collapse: true,
    name: 'common.mainMenu.dashboard',
    nameFallback: 'Dashboard',
    icon: <DashboardIcon />,
    state: 'dashboard',
    views: sortByKey(
      [
        {
          path: '/dashboard',
          name: 'common.mainMenu.dashboardStatistics',
          mini: 'PS',
          nameFallback: 'Panel Statystyk',
          component: Dashboard,
          layout: '/admin',
          invisible: false,
        },
        {
          path: '/powerBIStatistics',
          name: 'common.mainMenu.powerBIStatistics',
          nameFallback: 'Microsoft PowerBI',
          mini: 'MPBI',
          component: PowerBIStatistics,
          layout: '/admin',
          invisible: false,
          role: roles.ROLE_SHOW_BRAND_POWER_BI,
        },
      ],
      'name'
    ),
  },
  {
    collapse: true,
    name: 'common.mainMenu.dictionariesCaption',
    nameFallback: 'Słowniki',
    icon: <Book />,
    state: 'dictionaries',
    views: sortByKey(
      [
        {
          path: '/containers',
          name: 'common.mainMenu.containers',
          nameFallback: 'Pojemniki',
          mini: 'common.mainMenu.containersMini',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.CONTAINERS,
            valueUrl: 'containers',
            tableName: 'common.mainMenu.containerTableName',
            tableTitle: 'common.mainMenu.containerTableTitle',
            customRemoveBody: true,
          },
        },
        {
          path: '/home-requirements',
          name: 'common.mainMenu.homeRequirements',
          nameFallback: 'Wymagany sprzęt domowy',
          mini: 'common.mainMenu.homeRequirementsMini',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.REGULAR,
            tableName: 'common.mainMenu.homeRequirementsName',
            tableTitle: 'common.mainMenu.homeRequirementsTitle',
            valueUrl: 'home-requirements',
          },
        },
        {
          path: '/serving-suggestions',
          name: 'common.mainMenu.servingSuggestions',
          nameFallback: 'Propozycje podania',
          mini: 'common.mainMenu.servingSuggestionsMini',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.REGULAR,
            tableName: 'common.mainMenu.servingSuggestionsTableName',
            tableTitle: 'common.mainMenu.servingSuggestionsTableTitle',
            valueUrl: 'serving-suggestions',
            translatable: true,
          },
        },
        {
          path: '/allergens',
          name: 'common.mainMenu.allergens',
          nameFallback: 'Alergeny',
          mini: 'common.mainMenu.allergensMini',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.WITH_ANTI_PREFERENCES,
            tableName: 'common.mainMenu.allergensTableName',
            tableTitle: 'common.mainMenu.allergensTableTitle',
            valueUrl: 'allergens',
            translatable: true,
            antiPreferencesKey: 'allergens',
          },
        },
        {
          path: '/bag-containers',
          name: 'common.mainMenu.bagContainers',
          nameFallback: 'Torby',
          mini: 'common.mainMenu.bagContainersMini',
          component: Dictionary,
          role: roles.ROLE_SHOW_BAG_CONTAINER,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.BAG_CONTAINERS,
            tableName: 'common.mainMenu.bagContainersName',
            tableTitle: 'common.mainMenu.bagContainersTitle',
            valueUrl: 'bag-containers',
            showRole: roles.ROLE_SHOW_BAG_CONTAINER,
            editRole: roles.ROLE_EDIT_BAG_CONTAINER,
            createRole: roles.ROLE_CREATE_BAG_CONTAINER,
          },
        },
        {
          path: '/delivery-types',
          name: 'common.mainMenu.deliveryTypes',
          nameFallback: 'Typy dostawy',
          mini: 'common.mainMenu.deliveryTypesMini',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.REGULAR,
            tableName: 'common.mainMenu.deliveryTypesTableName',
            tableTitle: 'common.mainMenu.deliveryTypesTableTitle',
            valueUrl: 'delivery-types',
          },
        },
        {
          path: '/ingredient-categories',
          name: 'common.mainMenu.ingredientCategories',
          nameFallback: 'Kategorie składników',
          mini: 'common.mainMenu.ingredientCategoriesMini',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.WITH_ANTI_PREFERENCES,
            tableName: 'common.mainMenu.ingredientCategoriesTableName',
            tableTitle: 'common.mainMenu.ingredientCategoriesTableTitle',
            valueUrl: 'ingredient-categories',
            antiPreferencesKey: 'ingredientCategories',
            translatable: true,
          },
        },
        {
          path: '/units',
          name: 'common.mainMenu.units',
          nameFallback: 'Jednostki"',
          mini: 'common.mainMenu.unitsMini',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.REGULAR,
            tableName: 'common.mainMenu.unitsTableName',
            tableTitle: 'common.mainMenu.unitsTableTitle',
            valueUrl: 'units',
          },
        },
        {
          path: '/tags',
          name: 'common.mainMenu.tagsForProduction',
          nameFallback: 'Tagi dla produkcji',
          mini: 'common.mainMenu.tagsForProductionMini',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.REGULAR,
            tableName: 'common.mainMenu.tagsForProductionTableName',
            tableTitle: 'common.mainMenu.tagsForProductionTableTitle',
            valueUrl: 'tags',
          },
        },
        {
          path: '/client-tags',
          name: 'common.mainMenu.tagsForClients',
          nameFallback: 'Tagi dla klientów',
          mini: 'common.mainMenu.tagsForClientsMini',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.CLIENT_TAGS,
            tableName: 'common.mainMenu.tagsForClientsTableName',
            tableTitle: 'common.mainMenu.tagsForClientsTableTitle',
            valueUrl: 'client-tags',
            antiPreferencesKey: 'clientTags',
            translatable: true,
          },
        },
        {
          path: '/sms-client-tags',
          name: 'common.mainMenu.tagsForClientsSms',
          nameFallback: 'Tagi sms dla klientów',
          mini: 'common.mainMenu.tagsForClientsSmsMini',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.REGULAR,
            tableName: 'common.mainMenu.tagsForClientsTableNameSms',
            tableTitle: 'common.mainMenu.tagsForClientsTableTitleSms',
            valueUrl: 'sms-client-tags',
          },
        },
        {
          path: '/eco-containers',
          name: 'common.mainMenu.ecoContainers',
          nameFallback: 'Pojemniki EKO',
          mini: 'common.mainMenu.ecoContainersMini',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.CONTAINERS,
            valueUrl: 'eco-containers',
            tableName: 'common.mainMenu.ecoContainersTableName',
            tableTitle: 'common.mainMenu.ecoContainersTableTitle',
            customRemoveBody: true,
          },
        },
        {
          path: '/wholesalers',
          name: 'common.mainMenu.wholesalers',
          nameFallback: 'Hurtownicy',
          mini: 'common.mainMenu.wholesalersMini',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.REGULAR,
            tableName: 'common.mainMenu.wholesalersTableName',
            tableTitle: 'common.mainMenu.wholesalersTableTitle',
            valueUrl: 'wholesalers',
          },
        },
        {
          path: '/sexes',
          name: 'common.mainMenu.sex',
          nameFallback: 'Płeć',
          mini: 'common.mainMenu.sexMini',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.WITH_POSITION,
            tableName: 'common.mainMenu.sexTableName',
            tableTitle: 'common.mainMenu.sexTableTitle',
            valueUrl: 'sexes',
            translatable: true,
          },
        },
        {
          path: '/how-knows',
          name: 'common.mainMenu.howKnow',
          nameFallback: 'Skąd o nas wiesz?',
          mini: 'common.mainMenu.howKnowMini',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.WITH_POSITION,
            tableName: 'common.mainMenu.howKnowTableName',
            tableTitle: 'common.mainMenu.howKnowTableTitle',
            valueUrl: 'how-knows',
          },
        },
        {
          path: '/sources',
          name: 'common.mainMenu.sources',
          nameFallback: 'Źródła',
          mini: 'common.mainMenu.sourcesMini',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.REGULAR,
            tableName: 'common.mainMenu.sourcesTableName',
            tableTitle: 'common.mainMenu.sourcesTableTitle',
            valueUrl: 'sources',
          },
        },
        {
          path: '/kitchens',
          name: 'common.mainMenu.kitchen',
          nameFallback: 'Kuchnie',
          mini: 'common.mainMenu.kitchenMini',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            tableName: 'common.mainMenu.kitchenTableName',
            tableTitle: 'common.mainMenu.kitchenTableTitle',
            valueUrl: 'kitchens',
          },
        },
        {
          path: '/meal-categories',
          name: 'common.mainMenu.mealCategory',
          nameFallback: 'Kategoria posiłków',
          mini: 'common.mainMenu.mealCategoryMini',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            tableName: 'common.mainMenu.mealCategory',
            tableTitle: 'common.mainMenu.mealCategory',
            valueUrl: 'meal-categories',
          },
        },
        {
          path: '/departments',
          name: 'common.mainMenu.department',
          nameFallback: 'Działy',
          mini: 'common.mainMenu.departmentMini',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            tableName: 'common.mainMenu.departmentTableName',
            tableTitle: 'common.mainMenu.departmentTableTitle',
            valueUrl: 'departments',
          },
        },
        {
          path: '/stowages',
          name: 'common.mainMenu.stowage',
          nameFallback: 'Pakownia',
          mini: 'common.mainMenu.stowageMini',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            tableName: 'common.mainMenu.stowageTableName',
            tableTitle: 'common.mainMenu.stowageTableTitle',
            valueUrl: 'stowages',
          },
        },
        {
          path: '/contractors',
          name: 'common.mainMenu.contractors',
          nameFallback: 'Kontrahenci',
          mini: 'common.mainMenu.contractorsMini',
          component: Contractors,
          role: roles.ROLE_SHOW_CONTRACTOR,
          layout: '/admin',
        },
      ],
      'name'
    ),
  },
  {
    collapse: true,
    name: 'common.mainMenu.companyOffer',
    nameFallback: 'Oferta firmy',
    icon: <BusinessCenter />,
    state: 'offerCollapse',
    views: [
      {
        path: '/sizes',
        name: 'common.mainMenu.sizes',
        nameFallback: 'Wielkości',
        mini: 'common.mainMenu.sizesMini',
        component: MealSizes,
        role: roles.ROLE_SHOW_SIZE,
        layout: '/admin',
        componentProps: {
          valueUrl: '/sizes',
        },
      },
      {
        path: '/types',
        name: 'common.mainMenu.typesOfMeals',
        nameFallback: 'Typy posiłków',
        mini: 'common.mainMenu.typesOfMealsMini',
        component: MealTypes,
        role: roles.ROLE_SHOW_MEAL_TYPE,
        layout: '/admin',
        componentProps: {
          valueUrl: '/types',
        },
      },
      {
        path: '/diets',
        name: 'common.mainMenu.diets',
        nameFallback: 'Diety',
        mini: 'common.mainMenu.dietsMini',
        component: Diets,
        role: roles.ROLE_SHOW_DIET,
        layout: '/admin',
        componentProps: {
          valueUrl: '/diets',
        },
      },
      {
        path: '/variants',
        name: 'common.mainMenu.variants',
        nameFallback: 'Warianty',
        mini: 'common.mainMenu.variantsMini',
        component: Variants,
        role: roles.ROLE_SHOW_VARIANT,
        layout: '/admin',
        componentProps: {
          valueUrl: '/variants',
        },
      },
      {
        path: '/packages',
        name: 'common.mainMenu.dietFilters',
        nameFallback: 'Filtry diet',
        mini: 'common.mainMenu.dietFiltersMini',
        component: Packages,
        role: roles.ROLE_SHOW_PACKAGE,
        layout: '/admin',
        componentProps: {
          valueUrl: '/packages',
        },
      },
      {
        path: '/filter-line-diets',
        name: 'common.mainMenu.dietLineFilters',
        nameFallback: 'Filtry diet',
        mini: 'common.mainMenu.dietLineFiltersMini',
        component: FilterLineDiets,
        role: roles.ROLE_SHOW_PACKAGE_LINE_FILTER,
        layout: '/admin',
        componentProps: {
          valueUrl: '/packages',
        },
      },
      {
        path: '/mass-actions/diet-elements',
        name: 'massAction.dietElements.title',
        nameFallback: 'Masowa edycja oferty firmy',
        mini: 'MD',
        component: MassActionDietElementsList,
        role: roles.ROLE_SHOW_MASS_ACTION_DIET_ELEMENTS,
        layout: '/admin',
      },
      {
        path: '/mass-actions/diet-elements-add',
        name: 'massAction.dietElements.title',
        nameFallback: 'Masowa edycja oferty firmy',
        mini: 'MDA',
        component: MassActionDietElementsForm,
        role: roles.ROLE_CREATE_MASS_ACTION_DIET_ELEMENTS,
        layout: '/admin',
        invisible: true,
      },
    ],
  },
  {
    collapse: true,
    name: 'common.mainMenu.recipesDatabase',
    nameFallback: 'Baza Przepisów',
    icon: <Assignment />,
    state: 'recipesDatabase',
    views: [
      {
        path: '/products',
        name: 'common.mainMenu.products',
        nameFallback: 'Produkty',
        mini: 'common.mainMenu.productsMini',
        component: Products,
        role: roles.ROLE_SHOW_PRODUCT,
        layout: '/admin',
        componentProps: {
          valueUrl: '/products',
        },
      },
      {
        path: '/ingredients',
        name: 'common.mainMenu.ingredients',
        nameFallback: 'Składniki',
        mini: 'common.mainMenu.ingredientsMini',
        component: Ingredients,
        role: roles.ROLE_SHOW_INGREDIENT,
        layout: '/admin',
        componentProps: {
          valueUrl: '/ingredients',
        },
      },
      {
        path: '/recipes',
        name: 'common.mainMenu.recipes',
        nameFallback: 'Przepisy',
        mini: 'common.mainMenu.recipesMini',
        component: Recipes,
        role: roles.ROLE_SHOW_RECIPE,
        layout: '/admin',
        componentProps: {
          valueUrl: '/recipes',
        },
      },
      {
        path: '/meals',
        name: 'common.mainMenu.meals',
        nameFallback: 'Dania',
        mini: 'common.mainMenu.mealsMini',
        component: Meals,
        role: roles.ROLE_SHOW_DISH,
        layout: '/admin',
        componentProps: {
          valueUrl: '/meals',
        },
      },
      {
        path: '/dish-photo-gallery',
        name: 'dishes.gallery.title',
        nameFallback: 'Zdjęcia dań',
        mini: 'ZD',
        component: DishesPhotoGallery,
        role: roles.ROLE_SHOW_DISH_PHOTO_GALLERY,
        layout: '/admin',
      },
    ],
  },
  {
    path: '/menu-summary/:date/:variant',
    name: 'common.mainMenu.menuSummary',
    nameFallback: 'Podsumowanie menu',
    mini: 'common.mainMenu.menuSummaryMini',
    invisible: true,
    component: Summary,
    role: roles.ROLE_SHOW_MENU_SUMMARY,
    layout: '/admin',
  },
  {
    path: '/seccond-menu-summary/:date/:variant',
    name: 'common.mainMenu.menuSummary.v2',
    nameFallback: 'Podsumowanie menu II',
    mini: 'common.mainMenu.menuSummaryMini.v2',
    invisible: true,
    component: SummaryV2,
    role: roles.ROLE_SHOW_MENU_SUMMARY,
    layout: '/admin',
  },
  {
    name: 'common.mainMenu.menuPlanner',
    nameFallback: 'Planowanie menu',
    mini: 'P',
    icon: <LocalDining />,
    collapse: true,
    state: 'menuPlanner',
    granted: () => {
      let satisfyingRoles = [
        roles.ROLE_SHOW_MENU_PLANNING,
        roles.ROLE_SHOW_MENU_SUMMARY,
        roles.ROLE_SHOW_SHOW_MENU_PAGE,
        roles.ROLE_SHOW_NOTIFICATION,
        roles.ROLE_SHOW_HOVER,
      ];

      return satisfyingRoles.some(role => isGranted(role));
    },
    layout: '/admin',
    views: [
      {
        path: '/menu-planner/:date',
        sidebarPath: '/menu-planner',
        name: 'common.mainMenu.menu',
        nameFallback: 'Menu',
        mini: 'common.mainMenu.menuMini',
        component: NewPlanner,
        role: roles.ROLE_SHOW_MENU_PLANNING,
        layout: '/admin',
        invisible: true,
      },

      {
        path: '/menu-planner',
        name: 'common.mainMenu.menu',
        nameFallback: 'Menu',
        mini: 'common.mainMenu.menuMini',
        component: NewPlanner,
        role: roles.ROLE_SHOW_MENU_PLANNING,
        layout: '/admin',
      },
      {
        path: `/menu-summary`,
        name: 'common.mainMenu.menuSummary',
        nameFallback: 'Podsumowanie menu',
        mini: 'common.mainMenu.menuSummaryMini',
        component: Summary,
        role: roles.ROLE_SHOW_MENU_SUMMARY,
        layout: '/admin',
      },
      {
        path: '/seccond-menu-summary',
        name: 'common.mainMenu.menuSummary.v2',
        nameFallback: 'Podsumowanie menu II',
        mini: 'common.mainMenu.menuSummaryMini.v2',
        component: SummaryV2,
        role: roles.ROLE_SHOW_MENU_SUMMARY,
        layout: '/admin',
      },
      {
        path: '/menu-copying',
        sidebarPath: '/menu-copying',
        name: '$*common.mainMenu.menu.copying',
        nameFallback: 'Kopiowanie menu',
        mini: '$*common.mainMenu.menuMini.copying',
        component: MenuCopying,
        role: roles.ROLE_CREATE_MENU_PLANNING,
        layout: '/admin',
      },
    ],
  },
  {
    name: 'common.mainMenu.additionalOrders',
    nameFallback: 'Zam. dodatkowe',
    mini: 'common.mainMenu.additionalOrdersMini',
    icon: <TrendingUp />,
    collapse: true,
    state: 'overproduction',
    role: roles.ROLE_SHOW_OVERPRODUCTION,
    layout: '/admin',
    views: [
      {
        path: '/sub-brands',
        name: 'common.mainMenu.subBrands',
        nameFallback: 'Paramarki',
        mini: 'P',
        component: Dictionary,
        role: roles.ROLE_SHOW_SUB_BRAND,
        layout: '/admin',
        componentProps: {
          type: DICTIONARIES_TYPES.REGULAR,
          tableName: 'common.mainMenu.subBrandsTableName',
          tableTitle: 'common.mainMenu.subBrandsTableTitle',
          valueUrl: 'sub-brands',
          valueKey: 'name',
          valueAccessor: 'name',
          showRole: roles.ROLE_SHOW_SUB_BRAND,
          createRole: roles.ROLE_CREATE_SUB_BRAND,
          removeRole: roles.ROLE_REMOVE_SUB_BRAND,
          editRole: roles.ROLE_EDIT_SUB_BRAND,
          roleName: 'SUB_BRAND',
        },
      },
      {
        path: '/overprod-planner',
        name: 'common.mainMenu.newOrders',
        nameFallback: 'Nowe zamówienia',
        mini: 'common.mainMenu.newOrdersMini',
        component: Overproduction,
        role: roles.ROLE_SHOW_OVERPRODUCTION,
        layout: '/admin',
      },
    ],
  },
  {
    path: '/menu-summary',
    name: 'common.mainMenu.menuSummary',
    nameFallback: 'Podsumowanie menu',
    mini: 'P',
    invisible: true,
    component: Summary,
    role: roles.ROLE_SHOW_MENU_PLANNING,
    layout: '/admin',
  },
  {
    name: 'common.mainMenu.waste',
    nameFallback: 'Straty',
    mini: 'ST',
    icon: <DeleteOutline />,
    collapse: true,
    state: 'waste',
    role: roles.ROLE_SHOW_OVERPRODUCTION,
    layout: '/admin',
    views: [
      {
        path: '/add-waste',
        name: 'common.mainMenu.addWaste',
        nameFallback: 'Dodaj straty',
        mini: 'common.mainMenu.addWasteMini',
        component: Waste,
        role: roles.ROLE_SHOW_WASTE,
        layout: '/admin',
      },
      {
        path: '/waste-report',
        name: 'common.mainMenu.wasteReport',
        nameFallback: 'Raporty strat',
        mini: 'common.mainMenu.wasteReportMini',
        component: ReportView,
        role: roles.ROLE_SHOW_REPORT_WASTE,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'common.mainMenu.priceLists',
    nameFallback: 'Cenniki',
    icon: <ShoppingCart />,
    state: 'prices',
    views: [
      {
        path: '/prices',
        name: 'common.mainMenu.dietPrices',
        nameFallback: 'Diety',
        mini: 'common.mainMenu.dietPricesMini',
        component: DietPrices,
        role: roles.ROLE_SHOW_COST,
        layout: '/admin',
        componentProps: {
          tableName: 'common.mainMenu.dietPricesTableName',
          tableTitle: '',
          valueUrl: '/prices/diets',
        },
      },
      {
        path: '/delivery',
        name: 'common.mainMenu.delivery',
        nameFallback: 'Dostawy',
        mini: 'common.mainMenu.deliveryMini',
        component: DietDelivery,
        role: roles.ROLE_SHOW_COST,
        layout: '/admin',
        componentProps: {
          tableName: 'common.mainMenu.deliveryTableName',
          tableTitle: '',
          valueUrl: '/prices/delivery',
        },
      },
      {
        path: '/discounts',
        name: 'common.mainMenu.discounts',
        nameFallback: 'Rabaty',
        mini: 'common.mainMenu.discountsMini',
        component: DietDiscounts,
        role: roles.ROLE_SHOW_COST,
        layout: '/admin',
        componentProps: {
          tableName: 'common.mainMenu.discountsTableName',
          tableTitle: '',
          valueUrl: '/prices/discounts',
        },
      },
      {
        path: '/additionals',
        name: 'common.mainMenu.additionalPrices',
        nameFallback: 'Ceny dodatkowe',
        mini: 'common.mainMenu.additionalPricesMini',
        component: AdditionalPrices,
        role: roles.ROLE_SHOW_COST,
        layout: '/admin',
        componentProps: {
          tableName: 'common.mainMenu.additionalPricesTableName',
          tableTitle: '',
          valueUrl: '/prices/additionals',
        },
      },
      {
        path: '/money-box-config',
        name: 'common.mainMenu.moneyBoxConfig',
        nameFallback: 'Konfiguracja skarbonki',
        mini: 'common.mainMenu.moneyBoxConfigMini',
        component: MoneyBoxConfigurations,
        role: roles.ROLE_SHOW_MONEY_BOX_CONFIGURATION,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'common.mainMenu.addons',
    nameFallback: 'Dodatki',
    icon: <ExtensionIcon />,
    state: 'addon',
    views: [
      {
        path: '/addons',
        name: 'common.mainMenu.addonsSubmenu',
        nameFallback: 'Dodatki',
        mini: 'common.mainMenu.addonsMini',
        component: Addons,
        role: roles.ROLE_SHOW_ADDON,
        layout: '/admin',
      },
      {
        path: '/addon-categories',
        name: 'common.mainMenu.addonsCategories',
        nameFallback: 'Kategorie dodatków',
        mini: 'common.mainMenu.addonsCategoriesMini',
        component: AddonsCategories,
        role: roles.ROLE_SHOW_ADDON,
        layout: '/admin',
      },
    ],
  },
  {
    path: '/messages',
    name: 'common.mainMenu.messages',
    nameFallback: 'Komunikaty',
    mini: 'common.mainMenu.messagesMini',
    icon: <Announcement />,
    component: MenuMessages,
    role: roles.ROLE_SHOW_NOTIFICATION,
    layout: '/admin',
    componentProps: {
      tableName: 'Komunikaty',
      tableTitle: '',
      valueUrl: '/messages',
    },
  },
  {
    path: '/discount-codes',
    name: 'common.mainMenu.discountCodes',
    nameFallback: 'Kody rabatowe',
    mini: 'common.mainMenu.discountCodesMini',
    icon: <LocalPlay />,
    component: PromoCodes,
    role: roles.ROLE_SHOW_DISCOUNT_CODE,
    layout: '/admin',
    componentProps: {
      valueUrl: '/discount-codes',
    },
  },
  {
    collapse: true,
    name: 'common.mainMenu.roomService',
    nameFallback: 'Obsługa klienta',
    icon: <RoomService />,
    state: 'orders',
    views: [
      {
        path: '/clients',
        name: 'common.mainMenu.clients',
        nameFallback: 'Klienci',
        mini: 'common.mainMenu.clientsMini',
        component: Clients,
        role: roles.ROLE_SHOW_USER,
        layout: '/admin',
        componentProps: {
          valueUrl: '/clients',
        },
      },
      {
        path: '/baskets',
        name: 'basket.menu',
        nameFallback: 'Koszyki',
        mini: 'basket.menuMini',
        component: Baskets,
        role: roles.ROLE_SHOW_BASKET,
        layout: '/admin',
        componentProps: {
          tableName: 'common.mainMenu.baskets',
          tableTitle: 'common.mainMenu.baskets',
          valueUrl: '/baskets',
        },
      },
      {
        path: '/orders',
        name: 'common.mainMenu.orders',
        nameFallback: 'Zamówienia',
        mini: 'common.mainMenu.ordersMini',
        component: Orders,
        role: roles.ROLE_SHOW_ORDER,
        layout: '/admin',
        componentProps: {
          tableName: 'common.mainMenu.orders',
          tableTitle: 'common.mainMenu.orders',
          valueUrl: '/orders',
        },
      },
      {
        path: '/ecommerce-diets',
        name: 'common.mainMenu.dietList',
        nameFallback: 'Lista diet',
        mini: 'LD',
        component: EcommerceDiets,
        role: roles.ROLE_SHOW_ORDER,
        layout: '/admin',
        componentProps: {
          tableName: 'common.mainMenu.orders',
          tableTitle: 'common.mainMenu.orders',
          valueUrl: '/ecommerce-diets',
        },
      },
      {
        path: '/clients-bmi',
        name: 'common.mainMenu.clientsBmi',
        nameFallback: 'Wyniki BMI',
        mini: 'BMI',
        component: ClientsBmi,
        role: roles.ROLE_SHOW_CLIENT_METRIC,
        layout: '/admin',
        componentProps: {
          tableTitle: 'common.mainMenu.clientsBmi',
          valueUrl: '/clients-bmi',
        },
      },
      {
        path: '/ecommerce-bags',
        name: 'ecommerceBags.name',
        nameFallback: 'Lista toreb',
        mini: 'T',
        component: EcommerceBags,
        role: roles.ROLE_SHOW_BAG,
        layout: '/admin',
      },
      {
        path: '/subscriptions',
        name: 'common.mainMenu.subscriptions',
        nameFallback: 'Subskrypcje',
        mini: 'common.mainMenu.subscriptionsMini',
        component: Subscriptions,
        role: roles.ROLE_SHOW_ORDER,
        layout: '/admin',
        componentProps: {
          tableName: 'common.mainMenu.subscriptions',
          tableTitle: 'common.mainMenu.subscriptions',
          valueUrl: '/subscription',
        },
      },
      {
        path: '/rates',
        name: 'common.mainMenu.clientComments',
        nameFallback: 'Komentarze klientów',
        mini: 'common.mainMenu.clientCommentsMini',
        component: Comments,
        role: roles.ROLE_SHOW_RATE,
        layout: '/admin',
      },
      {
        path: '/mass-sms',
        name: 'common.mainMenu.sms',
        nameFallback: 'Wysyłka SMS',
        mini: 'SMS',
        component: MassSms,
        role: roles.ROLE_SHOW_MASS_SMS,
        layout: '/admin',
      },
      {
        path: '/mass-mails',
        name: 'common.mainMenu.mail',
        nameFallback: 'Wysyłka Email',
        mini: 'Email',
        component: MassEmail,
        role: roles.ROLE_SHOW_MASS_MAIL,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'common.mainMenu.menuSelectionAssistant',
    nameFallback: 'Moduł Kasia',
    icon: <Chat />,
    state: 'MenuSelectionAssistant',
    views: [
      {
        path: '/menu-selection-assistant',
        name: 'common.mainMenu.menuSelectionAssistant.settings',
        nameFallback: 'Ustawienia',
        mini: 'common.mainMenu.menuSelectionAssistant.settingsMini',
        component: MSA,
        role: ROLE_SHOW_MODULE_MENU_SELECTION_ASSISTANT,
        layout: '/admin',
        componentProps: {
          valueUrl: '/menu-selection-assistant',
        },
      },
    ],
  },
  {
    collapse: true,
    name: 'common.mainMenu.logistics',
    nameFallback: 'Logistyka',
    icon: <LocalShipping />,
    state: 'logistics',
    views: [
      {
        path: '/zone-categories',
        name: 'common.mainMenu.zoneCategories',
        nameFallback: 'Kategorie stref',
        mini: 'common.mainMenu.zoneCategoriesMini',
        component: ZonesCategories,
        role: roles.ROLE_SHOW_ZONE_CATEGORY,
        layout: '/admin',
        componentProps: {
          tableName: 'zoneCategories.add',
          tableTitle: 'zoneCategories.list',
          valueUrl: 'zone-categories',
        },
      },
      {
        path: '/zones',
        name: 'common.mainMenu.zones',
        nameFallback: 'Strefy',
        mini: 'common.mainMenu.zonesMini',
        component: Zones,
        role: roles.ROLE_SHOW_ZONE,
        layout: '/admin',
        componentProps: {
          valueUrl: '/zones',
        },
      },
      {
        path: '/logistics-addresses',
        name: 'common.mainMenu.addresses',
        nameFallback: 'Adresy',
        mini: 'common.mainMenu.addressesMini',
        component: LogisticsAddresses,
        role: roles.ROLE_SHOW_ADDRESS,
        layout: '/admin',
        componentProps: {
          valueUrl: '/logistics-addresses',
        },
      },
      {
        path: '/drivers',
        name: 'common.mainMenu.drivers',
        nameFallback: 'Kierowcy',
        mini: 'common.mainMenu.driversMini',
        component: Drivers,
        role: roles.ROLE_SHOW_DRIVER,
        layout: '/admin',
        componentProps: {
          valueUrl: '/drivers',
        },
      },
      {
        path: '/pick-up-points',
        name: 'common.mainMenu.pickupPoints',
        nameFallback: 'Punkty Odbioru',
        mini: 'common.mainMenu.pickupPointsMini',
        component: Dictionary,
        role: roles.ROLE_SHOW_DICTIONARY,
        layout: '/admin',
        componentProps: {
          type: DICTIONARIES_TYPES.REGULAR,
          tableName: 'pickupPoints.add',
          tableTitle: 'pickupPoints.list',
          valueUrl: 'pick-up-points',
        },
      },
      {
        path: '/route-planner',
        name: 'routePlanner.menuTitle',
        nameFallback: 'Planer tras',
        mini: 'common.mainMenu.driversMini',
        component: RoutePlanner,
        role: roles.ROLE_SHOW_LOGISTIC_ROUTES,
        layout: '/admin',
        componentProps: {
          valueUrl: '/route-planner',
        },
      },
      {
        path: '/route-manager',
        name: 'routeManager.menuTitle',
        nameFallback: 'Manager tras',
        mini: 'common.mainMenu.driversMini',
        component: RouteManager,
        role: roles.ROLE_SHOW_LOGISTIC_ROUTE_MANAGER,
        layout: '/admin',
        componentProps: {
          valueUrl: '/route-manager',
        },
      },
      {
        path: '/address-requests',
        name: 'common.mainMenu.addressRequests',
        nameFallback: 'Zapytania klientów',
        mini: 'common.mainMenu.addressRequestsMini',
        component: AddressRequests,
        role: roles.ROLE_SHOW_ADDRESS_REQUESTS,
        layout: '/admin',
        componentProps: {
          valueUrl: '/address-requests',
        },
      },
    ],
  },
  {
    collapse: true,
    name: 'common.mainMenu.caterings.productions',
    nameFallback: 'Raporty produkcyjnye',
    icon: <PlaylistAddCheck />,
    state: 'reports',
    views: [
      {
        icon: <Settings />,
        path: '/reports/reports-configuration',
        name: 'common.mainMenu.reportsGlobalConfig',
        nameFallback: 'Ustawienia',
        mini: 'LD',
        role: roles.ROLE_EDIT_COMPANY,
        component: ReportsGlobalConfiguration,
        layout: '/admin',
      },
      {
        path: '/reports/shopping',
        name: 'common.mainMenu.reportsShoppingList',
        nameFallback: 'Lista zakupów',
        mini: 'common.mainMenu.reportsShoppingListMini',
        role: roles.ROLE_SHOW_REPORT_SHOPPING,
        component: ShoppingList,
        layout: '/admin',
      },
      {
        path: '/reports/purchased-addons',
        name: 'common.mainMenu.reportsAddonsList.newTranslate',
        nameFallback: 'Lista sprzedanych dodatków',
        mini: 'LD',
        role: roles.ROLE_SHOW_REPORT_PURCHASED_ADDONS,
        component: AddonsReport,
        layout: '/admin',
      },
      {
        path: '/reports/client-purchased-addons',
        name: 'common.mainMenu.reportsAddonsByClientList',
        nameFallback: 'Lista sprzedanych dodatków per klient',
        mini: 'LD',
        role: roles.ROLE_SHOW_REPORT_PURCHASED_ADDONS_BY_CLIENT,
        component: AddonsByClientReport,
        layout: '/admin',
      },
      {
        path: '/reports/containers',
        name: 'common.mainMenu.reportsContainers',
        nameFallback: 'Raport pojemników',
        mini: 'common.mainMenu.reportsContainersMini',
        role: roles.ROLE_SHOW_REPORT_CONTAINERS,
        component: Containers,
        layout: '/admin',
        componentProps: {
          isDetailed: false,
        },
      },
      {
        path: '/reports/detailed-containers',
        name: 'common.mainMenu.reportsDetailedContainers',
        nameFallback: 'Raport pojemników (szczegółowy)',
        mini: 'common.mainMenu.reportsDetailedContainersMini',
        role: roles.ROLE_SHOW_REPORT_CONTAINERS_DETAIL,
        component: Containers,
        layout: '/admin',
        componentProps: {
          isDetailed: true,
        },
      },
      {
        path: '/reports/checklist-recipes',
        name: 'common.mainMenu.reportsRecipesChecklist',
        nameFallback: 'Checklista przepisów',
        mini: 'common.mainMenu.reportsRecipesChecklistMini',
        role: roles.ROLE_SHOW_REPORT_CHECKLIST_RECIPES,
        component: RecipesChecklist,
        layout: '/admin',
      },
      {
        path: '/reports/recipe-cards',
        name: 'common.mainMenu.reportsRecipeCards',
        nameFallback: 'Karty przepisów',
        mini: 'common.mainMenu.reportsRecipeCardsMini',
        role: roles.ROLE_SHOW_REPORT_RECIPES_CARD,
        component: RecipeCards,
        layout: '/admin',
      },
      {
        path: '/reports/recipe-ingredients',
        name: 'common.mainMenu.reportsRecipeIngredients',
        nameFallback: 'Karty przepisów - elementy',
        mini: 'common.mainMenu.reportsRecipeIngredientsMini',
        role: roles.ROLE_SHOW_REPORT_RECIPES_INGREDIENTS,
        component: RecipeIngredients,
        layout: '/admin',
      },
      {
        path: '/reports/checklist-dishes',
        name: 'common.mainMenu.reportsDishesChecklist',
        nameFallback: 'Checklista dań',
        mini: 'common.mainMenu.reportsDishesChecklistMini',
        role: roles.ROLE_SHOW_REPORT_CHECKLIST_DISHES,
        component: DishesChecklist,
        layout: '/admin',
      },
      {
        path: '/reports/dish-cards',
        name: 'common.mainMenu.reportsDishCards',
        nameFallback: 'Karty dań',
        mini: 'common.mainMenu.reportsDishCardsMini',
        role: roles.ROLE_SHOW_REPORT_DISHES_CARD,
        component: DishCards,
        layout: '/admin',
      },
      {
        path: '/reports/dish-labels',
        name: 'common.mainMenu.reportsDishLabels',
        nameFallback: 'Naklejki na dania',
        mini: 'common.mainMenu.reportsDishLabelsMini',
        role: roles.ROLE_SHOW_REPORT_DISH_STICKERS,
        component: DishLabels,
        layout: '/admin',
      },
      {
        path: '/reports/overprod-dish-stickers',
        name: 'common.mainMenu.reportsDishLabelsAdditional',
        nameFallback: 'Naklejki na dania (Zam. dodatkowe)',
        mini: 'common.mainMenu.reportsDishLabelsAdditionalMini',
        roles: [
          roles.ROLE_SHOW_REPORT_DISH_STICKERS,
          roles.ROLE_SHOW_REPORT_OVERPRODUCTION,
        ],
        component: DishLabelsSubBrands,
        layout: '/admin',
      },
      {
        path: '/reports/mini-box-labels',
        name: 'common.mainMenu.reportsBoxLabels-Mini',
        nameFallback: 'Etykiety na torby MINI',
        mini: 'common.mainMenu.reportsBoxLabels-MiniMini',
        role: roles.ROLE_SHOW_REPORT_BAG_STICKERS_MINI,
        component: BoxLabelsMini,
        layout: '/admin',
      },
      {
        path: '/reports/dishes-to-production',
        name: 'common.mainMenu.reportsDishesToProduction',
        nameFallback: 'Dania do produkcji',
        mini: 'common.mainMenu.reportsDishesToProductionMini',
        role: roles.ROLE_SHOW_REPORT_DISH_LIST,
        component: DishesToProduction,
        layout: '/admin',
      },
      {
        path: '/reports/checklist-diets',
        name: 'common.mainMenu.reportsDietsChecklist',
        nameFallback: 'Checklista diet',
        mini: 'common.mainMenu.reportsDietsChecklistMini',
        role: roles.ROLE_SHOW_REPORT_CHECKLIST_DIETS,
        component: DietsChecklist,
        layout: '/admin',
      },
      {
        path: '/reports/checklist-altered-diets',
        name: 'common.mainMenu.reportsAlteredDietsChecklist',
        nameFallback: 'Checklista wyborów',
        mini: 'common.mainMenu.reportsAlteredDietsChecklistMini',
        role: roles.ROLE_SHOW_REPORT_CHECKLIST_ALTERED_DIETS,
        component: AlteredDietsChecklist,
        layout: '/admin',
      },
      {
        path: '/reports/active-diets',
        name: 'common.mainMenu.reportActiveDiets',
        nameFallback: 'Raport Diet',
        mini: 'common.mainMenu.reportActiveDietsMini',
        role: roles.ROLE_SHOW_REPORT_ACTIVE_DIET,
        component: ActiveDiets,
        layout: '/admin',
      },
      {
        path: '/reports/table-active-diets',
        name: 'common.mainMenu.reportActiveDietsTable',
        nameFallback: 'Raport Diet',
        mini: 'common.mainMenu.reportActiveDietsMini',
        role: roles.ROLE_SHOW_REPORT_ACTIVE_DIET,
        component: ActiveDietsTable,
        layout: '/admin',
      },
      {
        path: '/reports/box-labels',
        name: 'common.mainMenu.reportsBoxLabels',
        nameFallback: 'Etykiety na torby',
        mini: 'common.mainMenu.reportsBoxLabelsMini',
        breadcrumbAdditionalContent: (
          <ReportsBoxLabelsBreadcrumbAdditionalContent />
        ),
        role: roles.ROLE_SHOW_REPORT_BAG_STICKERS,
        component: BoxLabels,
        layout: '/admin',
      },
      {
        path: '/reports/box-date-range-labels',
        name: 'common.mainMenu.reportsBoxLabelsDateRange',
        nameFallback: 'Etykiety na torby (zakres)',
        mini: 'common.mainMenu.reportsBoxLabelsDateRangeMini',
        role: roles.ROLE_SHOW_REPORT_BAG_STICKERS,
        component: BoxLabelsDateRange,
        layout: '/admin',
      },
      {
        path: '/reports/personal-pickups',
        name: 'common.mainMenu.reportPersonalPickups',
        nameFallback: 'Odbiory osobiste',
        mini: 'common.mainMenu.reportPersonalPickupsMini',
        role: roles.ROLE_SHOW_REPORT_PERSONAL_PICKUPS,
        component: PersonalPickups,
        layout: '/admin',
      },
      {
        path: '/reports/packers',
        name: 'common.mainMenu.reportPackers',
        nameFallback: 'Dla pakowni v2',
        mini: 'DP',
        role: roles.ROLE_SHOW_REPORT_PACKERS,
        component: Packers,
        layout: '/admin',
      },
      {
        path: '/reports/for-driver',
        name: 'common.mainMenu.reportsForDriver',
        nameFallback: 'Dla kierowcy',
        mini: 'common.mainMenu.reportsForDriverMini',
        role: roles.ROLE_SHOW_REPORT_FOR_DRIVER,
        component: ForDriver,
        layout: '/admin',
      },
      {
        path: '/reports/stickers-recipe',
        name: 'common.mainMenu.reportRecipeStickers',
        nameFallback: 'Naklejki na przepisy',
        mini: 'DP',
        role: roles.ROLE_SHOW_REPORT_RECIPE_STICKERS,
        component: RecipeStickers,
        layout: '/admin',
      },
      {
        path: '/reports/total-bel',
        name: 'common.mainMenu.totalBelReport',
        nameFallback: 'Total Bel',
        mini: 'common.mainMenu.totalBelReportMini',
        role: roles.ROLE_SHOW_REPORT_TOTAL_BEL,
        component: TotalBel,
        layout: '/admin',
      },
      {
        path: '/reports/pdf-generator',
        name: 'common.mainMenu.reportsPdfGenerator',
        nameFallback: 'PDF generator',
        mini: 'common.mainMenu.reportsPdfGeneratorMini',
        role: roles.ROLE_SHOW_REPORT_ADVANCED_DISH_PDF,
        component: PdfGenerator,
        layout: '/admin',
      },
      {
        path: '/reports/selections',
        name: 'common.mainMenu.reportsSelections',
        nameFallback: 'Kody V3 wybory',
        mini: 'common.mainMenu.reportsSelectionsMini',
        role: roles.ROLE_SHOW_REPORT_SELECTIONS,
        component: SelectionsV3Codes,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'common.mainMenu.caterings.analitics',
    nameFallback: 'Raporty analityczne',
    icon: <BarChart />,
    state: 'reports',
    views: [
      {
        path: '/reports/income-summary',
        name: 'common.mainMenu.reportsIncomeSummary',
        nameFallback: 'Przychód',
        mini: 'IS',
        role: roles.ROLE_SHOW_REPORT_INCOME_SUMMARY,
        component: IncomeSummary,
        layout: '/admin',
      },
      {
        path: '/reports/bags-food-cost',
        name: 'common.mainMenu.reportsFoodCostBags',
        nameFallback: 'FoodCost Toreb',
        mini: 'common.mainMenu.reportsFoodCostBagsMini',
        role: roles.ROLE_SHOW_REPORT_FOOD_COST_BAGS,
        component: FoodCostBags,
        layout: '/admin',
      },
      {
        path: '/reports/food-cost',
        name: 'common.mainMenu.reportsFoodCost',
        nameFallback: 'FoodCost Diet',
        mini: 'common.mainMenu.reportsFoodCostMini',
        role: roles.ROLE_SHOW_REPORT_FOOD_COST,
        component: FoodCost,
        layout: '/admin',
      },
      {
        path: '/reports/ordered-diets',
        name: 'common.mainMenu.reportOrderedDiets',
        nameFallback: 'Raport zamówionych diet',
        mini: 'common.mainMenu.reportOrderedDietsMini',
        role: roles.ROLE_SHOW_REPORT_ORDERED_DIET,
        component: OrderedDiets,
        layout: '/admin',
      },
      {
        path: '/reports/usage-of-discount-codes',
        name: 'common.mainMenu.reportsDiscountCodes',
        nameFallback: 'Kody rabatowe',
        mini: 'common.mainMenu.reportsDiscountCodesMini',
        role: roles.ROLE_SHOW_REPORT_USAGE_DISCOUNT_CODES,
        component: UsageOfDiscountCodes,
        layout: '/admin',
      },
      {
        path: '/reports/money-box-top-up',
        name: 'common.mainMenu.reportsMoneyboxTopUp',
        nameFallback: 'Doładowania skarbonki',
        mini: 'common.mainMenu.reportsMoneyboxTopUpMini',
        role: roles.ROLE_SHOW_REPORT_MONEY_BOX_TOP_UP,
        component: MoneyBoxTopUp,
        layout: '/admin',
      },
      {
        path: '/reports/incoming-subscriptions',
        name: 'common.mainMenu.reportsIncomingSubscriptions',
        nameFallback: 'Nadchodzące odnowienia',
        mini: 'common.mainMenu.reportsIncomingSubscriptionsMini',
        role: roles.ROLE_SHOW_REPORT_INCOMING_SUBSCRIPTION,
        component: IncomingSubscriptions,
        layout: '/admin',
      },
      {
        path: '/reports/sent-sms',
        name: 'reports.reportSentSms',
        nameFallback: 'Raport wysłanych smsów',
        mini: 'SMS',
        role: roles.ROLE_SHOW_REPORT_SENT_SMS,
        component: SentSms,
        layout: '/admin',
        componentProps: {
          isDetailed: false,
        },
      },
      {
        path: '/reports/macro-ingredients',
        name: 'common.mainMenu.macroIngredientsReport',
        nameFallback: 'Raport - Wartości odżywcze',
        mini: 'common.mainMenu.macroIngredientsReportMini',
        role: roles.ROLE_SHOW_REPORT_MACRO_INGREDIENTS,
        component: MacroIngredients,
        layout: '/admin',
      },

      {
        path: '/reports/employees',
        name: 'common.mainMenu.employeesReport',
        nameFallback: 'Raport pracowników',
        mini: 'common.mainMenu.employeesReportMini',
        role: roles.ROLE_SHOW_REPORT_EMPLOYEE_TIME_ENTRIES,
        component: EmployeesReport,
        layout: '/admin',
      },
      {
        path: '/reports/billing',
        name: 'common.mainMenu.reportsBilling',
        nameFallback: 'Raport połączeń telefonicznych',
        mini: 'common.mainMenu.reportsBillingMini',
        role: roles.ROLE_SHOW_CLIENT_CALL,
        component: BillingReport,
        layout: '/admin',
      },
      {
        path: '/reports/overproduction',
        name: 'common.mainMenu.reportsOverProduction',
        nameFallback: 'Zam. dodatkowe',
        mini: 'common.mainMenu.reportsOverProductionMini',
        role: roles.ROLE_SHOW_REPORT_OVERPRODUCTION,
        component: OverproductionReport,
        layout: '/admin',
      },
      {
        path: '/easyreports',
        granted: () => {
          return dynamicEnv.REACT_APP_ENABLE_EASY_REPORTS === 'true';
        },
        name: 'Easyreports',
        mini: 'ER',
        component: EasyReports,
        layout: '/admin',
      },
      {
        path: '/reports/employees',
        granted: () => {
          return roles.ROLE_SHOW_REPORT_EMPLOYEES === 'true';
        },
        name: 'Easyreports',
        mini: 'ER',
        component: EasyReports,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'reports.accountant',
    nameFallback: 'Raporty księgowe',
    icon: <AccountBalance />,
    state: 'reports',
    views: [
      {
        path: '/reports/accountant/income',
        name: 'reports.accountant.income',
        nameFallback: 'Przychody',
        mini: 'KP',
        role: roles.ROLE_SHOW_REPORT_ACCOUNTANT_INCOME,
        component: AccountantReports.Income,
        layout: '/admin',
      },
      {
        path: '/reports/accountant/deposit-and-refunds',
        name: 'reports.accountant.depositAndRefunds',
        nameFallback: 'Wpłaty i zwroty',
        mini: 'KWZ',
        role: roles.ROLE_SHOW_REPORT_ACCOUNTANT_DEPOSIT_AND_REFUNDS,
        component: AccountantReports.DepositAndRefunds,
        layout: '/admin',
      },
      {
        path: '/reports/accountant/new-accounts',
        name: 'reports.accountant.newAccounts',
        nameFallback: 'Nowe konta',
        mini: 'KNK',
        role: roles.ROLE_SHOW_REPORT_ACCOUNTANT_NEW_ACCOUNTS,
        component: AccountantReports.NewAccounts,
        layout: '/admin',
      },

      {
        path: '/reports/accountant/disposal',
        name: 'common.mainMenu.reportsDisposal',
        nameFallback: 'Utylizacja',
        mini: 'RU',
        role: roles.ROLE_SHOW_REPORT_DISPOSAL,
        component: Disposal,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'common.mainMenu.cateringsFlow',
    nameFallback: 'Caterings Flow',
    icon: <CheckBox />,
    state: 'cateringsFlow',
    views: [
      {
        path: '/tasks-generator',
        name: 'common.mainMenu.tasksGenerator',
        nameFallback: 'Generator zadań',
        mini: 'common.mainMenu.tasksGeneratorMini',
        component: TasksGenerator,
        role: roles.ROLE_SHOW_TASKS_GENERATOR,
        layout: '/admin',
      },
      {
        path: '/kitchen',
        name: 'common.mainMenu.kitchen',
        nameFallback: 'Kuchnia',
        mini: 'common.mainMenu.kitchenMini',
        component: Kitchen,
        role: roles.ROLE_SHOW_KITCHEN,
        layout: '/admin',
      },
      {
        path: '/dishes-packhouse',
        name: 'common.mainMenu.dishesPackhouse',
        nameFallback: 'Pakownia dań',
        mini: 'common.mainMenu.dishesPackhouseMini',
        component: DishesPackhouse,
        role: roles.ROLE_SHOW_DISH_PACKHOUSE,
        layout: '/admin',
      },
      {
        path: '/bags-packhouse',
        name: 'common.mainMenu.bagsPackhouse',
        nameFallback: 'Pakownia toreb',
        mini: 'common.mainMenu.bagsPackhouseMini',
        component: BagsPackhouse,
        role: roles.ROLE_SHOW_BAGS_PACKHOUSE,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'common.mainMenu.myCompany',
    nameFallback: 'Moja firma',
    icon: <Settings />,
    state: 'settings',
    views: [
      {
        path: '/company',
        name: 'common.mainMenu.myCompanySettings',
        nameFallback: 'Ustawienia',
        mini: 'common.mainMenu.myCompanySettingsMini',
        component: MyCompany,
        role: roles.ROLE_EDIT_COMPANY,
        layout: '/admin',
      },
      {
        path: '/brands',
        name: 'common.mainMenu.myCompanyBrands',
        nameFallback: 'Marki',
        mini: 'common.mainMenu.myCompanyBrandsMini',
        component: Brands,
        role: roles.ROLE_SHOW_BRAND,
        layout: '/admin',
        invisible: true,
        componentProps: {
          valueUrl: '/brands',
        },
      },
      {
        path: '/roles',
        name: 'common.mainMenu.myCompanyRoles',
        nameFallback: 'Role',
        mini: 'common.mainMenu.myCompanyRolesMini',
        component: Roles,
        role: roles.ROLE_SHOW_ROLE,
        layout: '/admin',
        componentProps: {
          valueUrl: '/roles',
        },
      },
      {
        path: '/employees',
        name: 'common.mainMenu.myCompanyEmployees',
        nameFallback: 'Pracownicy',
        mini: 'common.mainMenu.myCompanyEmployeesMini',
        component: Employees,
        role: roles.ROLE_SHOW_EMPLOYEE,
        layout: '/admin',
        componentProps: {
          valueUrl: '/employees',
        },
      },
      {
        path: '/admin-nav-links',
        name: 'common.mainMenu.myCompanyManageNavLinks',
        nameFallback: 'Zarządzaj skrótami',
        mini: 'common.mainMenu.myCompanyManageNavLinksMini',
        component: AdminNavLinks,
        role: roles.ROLE_SHOW_ADMIN_NAV_LINK,
        layout: '/admin',
      },
      {
        path: '/report-archive',
        name: 'common.mainMenu.archive',
        nameFallback: 'Archiwum',
        mini: 'common.mainMenu.archiveMini',
        component: Archive,
        role: roles.ROLE_SHOW_REPORT_ARCHIVE,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'common.mainMenu.myCompanyActiveBrand',
    nameFallback: 'Konfiguruj markę',
    icon: <Tune />,
    state: 'activeBrand',
    views: [
      {
        path: '/copy-brand-settings',
        name: 'common.mainMenu.brandConfiguration.copySettings',
        nameFallback: 'Kopiowanie ustawień marki',
        component: CopyingBrandSettings,
        role: roles.ROLE_SHOW_COPY_BRAND_CONFIGURATION,
        mini: 'common.mainMenu.brandConfiguration.copySettingsMini',
        layout: '/admin',
        invisible: true,
      },
      {
        path: '/active-brand',
        name: 'common.mainMenu.brandConfiguration.information',
        nameFallback: 'Informacje',
        component: ActiveBrandRedirect,
        role: roles.ROLE_SHOW_BRAND,
        mini: 'common.mainMenu.brandConfiguration.informationMini',
        layout: '/admin',
        componentProps: {
          valueUrl: '/active-brand',
        },
      },
      {
        path: '/client-panel-design',
        name: 'common.mainMenu.brandConfiguration.generalAppearance',
        nameFallback: 'Wygląd ogólny',
        component: ClientPanelDesign,
        mini: 'common.mainMenu.brandConfiguration.clientPanelDesignMini',
        role: roles.ROLE_SHOW_BRAND,
        layout: '/admin',
      },
      {
        path: '/order-form',
        name: 'common.mainMenu.brandConfiguration.orderForm',
        nameFallback: 'Formularz zamówienia',
        component: OrderForm,
        mini: 'common.mainMenu.brandConfiguration.orderFormMini',
        role: roles.ROLE_SHOW_BRAND,
        layout: '/admin',
      },
      {
        path: '/panel-configuration',
        name: 'common.mainMenu.brandConfiguration.panelClient',
        nameFallback: 'Panel klienta',
        component: PanelConfiguration,
        mini: 'common.mainMenu.brandConfiguration.panelConfigurationMini',
        role: roles.ROLE_SHOW_BRAND,
        layout: '/admin',
      },
      {
        path: '/shop',
        name: 'common.mainMenu.shop',
        nameFallback: 'Sklep',
        component: Shop,
        mini: 'S',
        role: roles.ROLE_SHOW_MODULE_SHOP,
        layout: '/admin',
      },
      // {
      //   path: '/test-days',
      //   name: 'common.mainMenu.brandConfiguration.testDays',
      //   mini: 'common.mainMenu.brandConfiguration.testDaysMini',
      //   component: TestDays,
      //   role: roles.ROLE_SHOW_BRAND,
      //   layout: '/admin',
      // },
      {
        path: '/payment-methods',
        name: 'common.mainMenu.brandConfiguration.paymentMethods',
        nameFallback: 'Metody płatności',
        component: PaymentMethods,
        mini: 'common.mainMenu.brandConfiguration.paymentMethodsMini',
        role: roles.ROLE_SHOW_BRAND,
        layout: '/admin',
      },
      {
        path: '/deliveries-and-orders',
        name: 'common.mainMenu.brandConfiguration.deliveriesAndOrder',
        nameFallback: 'Dostawy i zamówienia',
        component: DeliveriesAndOrders,
        mini: 'common.mainMenu.brandConfiguration.deliveriesAndOrderMini',
        role: roles.ROLE_SHOW_BRAND,
        layout: '/admin',
      },
      {
        path: '/delivery-options',
        name: 'common.mainMenu.brandConfiguration.deliveryOptions',
        nameFallback: 'Opcje dostawy',
        component: DeliveryOptions,
        mini: 'common.mainMenu.brandConfiguration.deliveryOptionsMini',
        role: roles.ROLE_SHOW_BRAND,
        layout: '/admin',
      },
      {
        path: '/login-options',
        name: 'common.mainMenu.brandConfiguration.loginOptions',
        nameFallback: 'Opcje logowania',
        component: LoginOptions,
        mini: 'common.mainMenu.brandConfiguration.loginOptionsMini',
        role: roles.ROLE_SHOW_BRAND,
        layout: '/admin',
      },
      {
        path: '/eco-containers-settings',
        name: 'common.mainMenu.brandConfiguration.ecoContainers',
        nameFallback: 'Eko pojemniki',
        component: EcoContainers,
        mini: 'common.mainMenu.brandConfiguration.ecoContainersMini',
        role: roles.ROLE_SHOW_BRAND,
        layout: '/admin',
      },
      {
        path: '/email-and-sms',
        name: 'common.mainMenu.brandConfiguration.mailAndSms',
        nameFallback: 'Mailing i SMS',
        component: MailAndSms,
        mini: 'common.mainMenu.brandConfiguration.mailAndSmsMini',
        role: roles.ROLE_SHOW_BRAND,
        layout: '/admin',
      },
      {
        path: '/mail-notifications',
        name: 'common.mainMenu.myCompanyMailNotifications',
        nameFallback: 'Powiadomienia mailowe',
        component: MailNotifications,
        mini: 'common.mainMenu.myCompanyMailNotificationsMini',
        role: roles.ROLE_SHOW_MAIL_CONFIGURATION,
        layout: '/admin',
      },
      {
        path: '/sms-notifications',
        name: 'common.mainMenu.myCompanySmsNotification',
        nameFallback: 'Powiadomienia SMS',
        component: SmsNotifications,
        mini: 'common.mainMenu.myCompanySmsNotificationsMini',
        role: roles.ROLE_SHOW_SMS_CONFIGURATION,
        layout: '/admin',
      },
      {
        path: '/mail-reminders',
        name: 'common.mainMenu.myCompanyMailReminders',
        nameFallback: 'Przypomnienia mailowe',
        component: MailReminders,
        mini: 'common.mainMenu.myCompanyMailRemindersMini',
        breadcrumbAdditionalContent: (
          <MailRemindersBreadcrumbAdditionalContent />
        ),
        role: roles.ROLE_SHOW_END_OF_DIET_REMINDER,
        layout: '/admin',
      },
      {
        path: '/sms-reminders',
        name: 'common.mainMenu.myCompanySmsReminders',
        nameFallback: 'Przypomnienia SMS',
        component: SmsReminders,
        mini: 'common.mainMenu.myCompanySmsRemindersMini',
        role: roles.ROLE_SHOW_END_OF_DIET_REMINDER,
        layout: '/admin',
      },
      {
        path: '/accounting',
        name: 'common.mainMenu.brandConfiguration.accounting',
        nameFallback: 'Księgowość',
        component: Accounting,
        mini: 'common.mainMenu.brandConfiguration.accountingMini',
        role: roles.ROLE_SHOW_BRAND,
        layout: '/admin',
      },
      {
        path: '/power-bi',
        name: 'common.mainMenu.brandConfiguration.powerBiLabel',
        nameFallback: 'PowerBI',
        component: PowerBI,
        mini: 'PBI',
        role: roles.ROLE_SHOW_BRAND_POWER_BI,
        layout: '/admin',
      },
      {
        path: '/access-keys',
        name: 'brandCfg.accessKeysLabel',
        nameFallback: 'Klucze dostępu',
        component: AccessKeys,
        mini: 'KD',
        role: roles.ROLE_SHOW_BRAND_ACCESS_KEY,
        layout: '/admin',
      },
      {
        path: '/product-feed',
        name: 'brandCfg.productFeedLabel',
        nameFallback: 'Product Feed',
        component: ProductFeed,
        mini: 'PF',
        role: roles.ROLE_SHOW_FEED,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'common.mainMenu.settlements',
    nameFallback: 'Rozliczenia',
    icon: <CalcluateIconTest />,
    state: 'settlements',
    views: [
      {
        path: '/settlements/settings',
        name: 'common.mainMenu.myCompanySettings',
        nameFallback: 'Ustawienia',
        component: SettlementsSettings,
        mini: 'common.mainMenu.myCompanySettingsMini',
        role: roles.ROLE_SHOW_MODULE_SETTLEMENTS,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'CMS',
    nameFallback: 'CMS',
    icon: <ColorLens />,
    state: 'cms',
    views: [
      {
        path: '/cms-home',
        name: 'common.mainMenu.CMS.homePage',
        nameFallback: 'Strona główna',
        component: HomeCmsPage,
        mini: 'common.mainMenu.myCompanyPagesCreatorMini',
        role: roles.ROLE_EDIT_HOME_PAGE,
        layout: '/admin',
        componentProps: {
          tableName: 'Podstrony',
          tableTitle: '',
          valueUrl: '/cms-home',
        },
      },
      {
        path: '/cms-addons',
        name: 'common.mainMenu.CMS.addons',
        nameFallback: 'Dodatki',
        component: AddonsCmsPage,
        mini: 'common.mainMenu.myCompanyPagesCreatorMini',
        role: roles.ROLE_EDIT_CMS_ADDON,
        layout: '/admin',
        componentProps: {
          tableName: 'Podstrony',
          tableTitle: '',
          valueUrl: '/cms-addons',
        },
      },
      {
        path: '/cms-diets',
        name: 'common.mainMenu.CMS.diets',
        nameFallback: 'Diety',
        component: DietsCmsPage,
        mini: 'common.mainMenu.myCompanyPagesCreatorMini',
        role: roles.ROLE_EDIT_CMS_DIET,
        layout: '/admin',
        componentProps: {
          tableName: 'Podstrony',
          tableTitle: '',
          valueUrl: '/cms-diets',
        },
      },
      {
        path: '/cms-meals',
        name: 'common.mainMenu.CMS.dishes',
        nameFallback: 'Dania',
        component: MealsCmsPage,
        mini: 'common.mainMenu.myCompanyPagesCreatorMini',
        role: roles.ROLE_EDIT_CMS_DISH,
        layout: '/admin',
        componentProps: {
          tableName: 'Podstrony',
          tableTitle: '',
          valueUrl: '/cms-meals',
        },
      },
      {
        path: '/cms-zones',
        name: 'common.mainMenu.CMS.zone',
        nameFallback: 'Strefy dostaw',
        component: ZonesCmsPage,
        mini: 'common.mainMenu.myCompanyPagesCreatorMini',
        role: roles.ROLE_EDIT_CMS_ZONE,
        layout: '/admin',
        componentProps: {
          tableName: 'Podstrony',
          tableTitle: '',
          valueUrl: '/cms-zones',
        },
      },
      {
        path: '/subpage-menu',
        name: 'common.mainMenu.subpageMenu',
        nameFallback: 'Podstrona menu',
        mini: 'common.mainMenu.subpageMenuMini',
        component: SubMenuWrapper(SubMenu),
        role: roles.ROLE_EDIT_CMS_MENU,
        layout: '/admin',
      },
      {
        path: '/blog',
        name: 'common.mainMenu.blog',
        nameFallback: 'Blog',
        mini: 'common.mainMenu.blogMini',
        component: BlogCreator,
        role: roles.ROLE_SHOW_BLOG_POST,
        layout: '/admin',
        componentProps: {
          tableName: 'Blog',
          tableTitle: '',
          valueUrl: '/blog',
        },
      },
      {
        path: '/pages-creator',
        name: 'common.mainMenu.myCompanyPagesCreator',
        nameFallback: 'Kreator podstron',
        component: PagesCreator,
        mini: 'common.mainMenu.myCompanyPagesCreatorMini',
        role: roles.ROLE_SHOW_PAGE,
        layout: '/admin',
        componentProps: {
          tableName: 'Podstrony',
          tableTitle: '',
          valueUrl: '/pages-creator',
        },
      },
    ],
  },
  {
    collapse: true,
    name: 'common.mainMenu.translations',
    nameFallback: 'Tłumaczenia',
    icon: <FlagIcon />,
    state: 'translations',
    views: [
      {
        path: '/admin-panel',
        name: 'common.mainMenu.adminPanel',
        nameFallback: 'Panel administracyjny',
        component: TranslationsAdminPanel,
        mini: 'PA',
        role: roles.ROLE_SHOW_TRANSLATION,
        layout: '/admin',
      },
      {
        path: '/client-panel',
        name: 'common.mainMenu.clientPanel',
        nameFallback: 'Panel klienta',
        component: TranslationsClientPanel,
        mini: 'PK',
        role: roles.ROLE_SHOW_TRANSLATION,
        layout: '/admin',
      },
      {
        path: '/api',
        name: 'API',
        nameFallback: 'API',
        component: TranslationsAPI,
        mini: 'API',
        role: roles.ROLE_SHOW_TRANSLATION,
        layout: '/admin',
      },
      {
        path: '/language-settings',
        name: 'common.mainMenu.languageSettings',
        nameFallback: 'Ustawienia językowe',
        component: LanguageSettings,
        mini: 'UJ',
        role: roles.ROLE_EDIT_TRANSLATION,
        layout: '/admin',
      },
    ],
  },
  {
    path: '/analytics-tools',
    name: 'common.mainMenu.brandConfiguration.integrations',
    nameFallback: 'Integracje',
    component: AnalyticsTools,
    mini: 'common.mainMenu.brandConfiguration.integrationsMini',
    icon: <GraphicEq />,
    role: roles.ROLE_SHOW_BRAND,
    layout: '/admin',
  },
  {
    path: '/additional-offer',
    name: 'common.mainMenu.navLinks.additionalOffer',
    nameFallback: 'Oferta dodatkowa',
    icon: <AddCircleOutline />,
    mini: 'common.mainMenu.navLinks.additionalOfferMini',
    component: AdditionalOfferPage,
    role: roles.ROLE_SHOW_ADDITIONAL_OFFER,
    componentProps: {
      custom: true,
    },
    layout: '/admin',
  },
  {
    invisible: true,
    path: '/my-links',
    name: 'common.mainMenu.navLinks.myNavLinks',
    nameFallback: 'Moje skróty',
    component: AdminNavLinks,
    mini: 'common.mainMenu.navLinks.tabNameMini',
    componentProps: {
      custom: true,
    },
    layout: '/admin',
  },
];

export default routes;
