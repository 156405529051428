import React, { useState, useEffect, Fragment } from 'react';
import { compose } from 'redux';
import { withToast } from 'material-ui-toast-redux';
import produce from 'immer';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import ExtensionIcon from '@material-ui/icons/Extension';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import withStyles from '@material-ui/core/styles/withStyles';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import AdminTable from 'layouts/AdminTable';
import CustomInput from 'components/CustomInput/CustomInput';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { CustomTooltip } from 'components';

import { get, post, put } from 'helpers/apiHelpers';
import { combineStyles } from 'helpers/helpers';
import TOAST_DURATIONS from 'helpers/toastDurations';

const InvoiceOverrideTooltip = () => {
  const { t } = useTranslation();
  return (
    <CustomTooltip
      title={
        <div
          dangerouslySetInnerHTML={{
            __html: t(
              'views.addons.addonsCategories.form.field.invoiceOverride.tooltip',
              { interpolation: { escapeValue: false } }
            ),
          }}
        ></div>
      }
    />
  );
};

const initialFormData = {
  name: '',
  position: 0,
  limitForDiet: 0,
  invoiceOverride: false,
  invoiceOverrideName: null,
  active: true,
  isMarkedAsExcludedCategory: false,
};

const schema = yup.object().shape({
  name: yup.string().required(),
});

const AddonsCategoriesForm = ({
  classes,
  history,
  location,
  match,
  openToast,
}) => {
  const isEdit = location.pathname.includes('edit');
  const addonCategoryId = match.params.id;

  const { t } = useTranslation();
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    const fetchData = async () => {
      if (isEdit) {
        try {
          const response = await get(`/addon-categories/${addonCategoryId}`);
          const {
            '@id': iriId,
            '@type': iriType,
            createdAt,
            updatedAt,
            ...onlyFormData
          } = response;

          setFormData({
            ...onlyFormData,
          });
        } catch (error) {}
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (formData.invoiceOverride) {
      if (!formData.invoiceOverrideName) {
        setFormData(prevData => ({
          ...prevData,
          invoiceOverrideName: prevData.name,
        }));
      }
    } else {
      setFormData(prevData => ({ ...prevData, invoiceOverrideName: null }));
    }
  }, [formData.invoiceOverride]);

  const handleChange = ({ target: { name, value } }) => {
    setFormData(
      produce(draft => {
        draft[name] = value;
      })
    );
  };

  const handleCheckboxClick = ({ target: { name } }) => {
    setFormData(
      produce(draft => {
        draft[name] = !draft[name];
      })
    );
  };

  const handleSubmit = async () => {
    const isValid = schema.isValidSync(formData);
    if (!isValid) {
      return openToast({
        messages: [t('form.fillAllRequiredFields')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }

    try {
      const { position, limitForDiet, ...restFormData } = formData;
      const data = {
        ...restFormData,
        position: parseInt(position),
        limitForDiet: parseInt(limitForDiet),
      };

      isEdit
        ? await put(`/addon-categories/${addonCategoryId}`, data)
        : await post('/addon-categories', data);

      isEdit
        ? history.push('/admin/addon-categories')
        : setFormData(initialFormData);

      !isEdit &&
        openToast({
          messages: [t('status.success.save')],
          type: 'success',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
    } catch ({ response: { data = {}, status } }) {
      if (status === 500) {
        return openToast({
          messages: [
            t('status.error.serverNotResponding'),
            t('status.error.tryAgainLater'),
          ],
          type: 'error',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      }

      openToast({
        messages: [
          t('status.error.somethingWentWrong'),
          t('status.error.fillCorrectForm'),
        ],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }
  };

  return (
    <AdminTable
      title={
        isEdit
          ? t('views.addons.addonsCategories.editCategory')
          : t('views.addons.addonsCategories.newCategory')
      }
      isMarginLeft={true}
      iconComponent={<ExtensionIcon />}
    >
      <GridContainer>
        <GridItem sm={4}>
          <FormLabel className={classes.labelHorizontal}>
            {t('form.field.name.label')} *
          </FormLabel>
          <CustomInput
            formControlProps={{ fullWidth: true }}
            inputProps={{
              name: 'name',
              value: formData.name,
              onChange: handleChange,
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem sm={4}>
          <FormLabel className={classes.labelHorizontal}>
            {t('form.field.priority.label')}
            <CustomTooltip title={t('form.field.priority.tooltip')} />
          </FormLabel>
          <CustomInput
            formControlProps={{ fullWidth: true }}
            inputProps={{
              name: 'position',
              value: formData.position,
              onChange: handleChange,
              type: 'number',
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem sm={4}>
          <FormLabel className={classes.labelHorizontal}>
            {t('form.field.limitForDiet.label')}
            <CustomTooltip title={t('form.field.limitForDiet.tooltip')} />
          </FormLabel>
          <CustomInput
            formControlProps={{ fullWidth: true }}
            inputProps={{
              name: 'limitForDiet',
              value: formData.limitForDiet,
              onChange: handleChange,
              type: 'number',
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                name="active"
                onClick={handleCheckboxClick}
                checked={formData.active}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label={
              <Fragment>
                {t('form.field.active2.label')}
                <CustomTooltip
                  title={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'views.addons.addonsCategories.form.field.active.tooltip',
                          { interpolation: { escapeValue: false } }
                        ),
                      }}
                    ></div>
                  }
                />
              </Fragment>
            }
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                name="isMarkedAsExcludedCategory"
                onClick={handleCheckboxClick}
                checked={formData.isMarkedAsExcludedCategory}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label={t('form.field.isMarkedAsExcludedCategory.label')}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                name="invoiceOverride"
                onClick={handleCheckboxClick}
                checked={formData.invoiceOverride}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label={
              <>
                {formData.invoiceOverride && (
                  <GridItem
                    sm={12}
                    className={'pa-0'}
                    style={{ padding: '0px !important' }}
                  >
                    <FormLabel className={classes.labelHorizontal}>
                      {t(
                        'views.addons.addonsCategories.form.field.invoiceOverride.label'
                      )}{' '}
                      *
                      <InvoiceOverrideTooltip />
                    </FormLabel>
                    <CustomInput
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        name: 'invoiceOverrideName',
                        value: formData.invoiceOverrideName,
                        onChange: handleChange,
                      }}
                    />
                  </GridItem>
                )}
                {!formData.invoiceOverride && (
                  <>
                    {t(
                      'views.addons.addonsCategories.form.field.invoiceOverride.label'
                    )}
                    <InvoiceOverrideTooltip />
                  </>
                )}
              </>
            }
          />
        </GridItem>
      </GridContainer>
      <FormControlButtons
        classes={classes}
        discardText={t('form.cancel')}
        submitText={t('form.save')}
        cancelPath="/admin/addon-categories"
        handleSubmit={handleSubmit}
      />
    </AdminTable>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);
const enhance = compose(withToast, withStyles(combinedStyles));

export default enhance(AddonsCategoriesForm);
