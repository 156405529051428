const HELP_ARTICLE_URL = 'https://pomoc.caterings.pl/article';

const EXTERNAL_URLS = {
  howDeliveryDiscountWorks: `${HELP_ARTICLE_URL}/137-jak-dziala-rabat-za-wspolna-dostawe`,
  invoiceConfig: `${HELP_ARTICLE_URL}/187-konfiguracja-fakturowni`,
  payUPaymentConfig: `${HELP_ARTICLE_URL}/132-konfiguracja-sklepu-payu`,
  waysOfPackingBags: `${HELP_ARTICLE_URL}/80-sposoby-pakowania-toreb-z-caterings`,
  whyEndedDietIsStillActive: `${HELP_ARTICLE_URL}/225-dieta-sie-skonczyla-czemu-jest-aktywna`,
  whyCustomerAddressDoesntHaveAssignedZone: `${HELP_ARTICLE_URL}/207-dlaczego-adres-klienta-nie-ma-przypisanej-strefy`,
  recipes: `${HELP_ARTICLE_URL}/120-czym-jest-przepis-w-caterings`,
  foodsi: `${HELP_ARTICLE_URL}/344-foodsi-pakowanie-diety-do-kilku-toreb`,
  mailReminders: `${HELP_ARTICLE_URL}/240-przypomnienia-mailowe`,
  deactivateDiet: `${HELP_ARTICLE_URL}/222-jak-dezaktywowac-diete-kiedy-dieta-jest-aktywna-dla-klientow`,
};

export default EXTERNAL_URLS;
