import React, { Fragment } from 'react';
import { Button, TableCell } from '@material-ui/core';
import CustomInput from 'components/CustomInput/CustomInput';
import Editor from 'react-simple-code-editor';
import FormLabel from '@material-ui/core/FormLabel';
import Prism from 'prismjs';
import TOAST_DURATIONS from 'helpers/toastDurations';

const TableContentSection = ({
  mailingOption,
  t,
  openToast,
  classes,
  state,
  setState,
  renderAlert,
}) => {
  return (
    <TableCell>
      <div>
        <span
          onClick={() =>
            mailingOption.managedBySystem &&
            openToast({
              messages: [
                t(
                  'mailNotifications.thisIsASystemNotification',
                  'To powiadomienie, jest powiadomieniem systemowym.'
                ),
                t(
                  'mailNotifications.systemNotificationAvailableOnlyForAdministratrs',
                  'Powiadomienia systemowe są dostępne tylko dla administracji.'
                ),
              ],
              type: 'warning',
              autoHideDuration: TOAST_DURATIONS.LG,
            })
          }
        >
          {!mailingOption.enabledForOffice &&
          state.mailForAdmin &&
          mailingOption.mailKey !== 'employee_welcome' ? null : (
            <Button
              style={{
                background:
                  mailingOption.contentType === 'client'
                    ? '#00ACC2'
                    : '#828282',
                color: 'white',
                margin: '10px 0',
                padding: '3px 25px',
                fontSize: '12px',
              }}
              onClick={() => {
                const newMailing = [...state.mailing];
                const optionToChange = newMailing.find(
                  newMailingOption => newMailingOption.id === mailingOption.id
                );
                optionToChange.contentType = 'client';

                setState(prev => ({
                  ...prev,
                  mailing: newMailing,
                }));
              }}
            >
              {t('mailNotifications.forClientLabel', 'Dla klienta')}
            </Button>
          )}
        </span>
        <Button
          style={{
            background:
              mailingOption.contentType === 'office' ? '#00ACC2' : '#828282',
            color: 'white',
            margin: '10px 0 10px 5px',
            padding: '3px 25px',
            fontSize: '12px',
          }}
          onClick={() => {
            const newMailing = [...state.mailing];
            const optionToChange = newMailing.find(
              newMailingOption => newMailingOption.id === mailingOption.id
            );
            optionToChange.contentType = 'office';
            setState(prev => ({
              ...prev,
              mailing: newMailing,
            }));
          }}
        >
          {t('mailNotifications.forAdministators', 'Dla administracji')}
        </Button>
      </div>
      {mailingOption.contentType === 'client' ? (
        <Fragment>
          <FormLabel
            htmlFor="titleForClient"
            className={classes.labelHorizontal}
          >
            {t('mailNotifications.titleForClient', 'Tytuł dla klienta')}
          </FormLabel>
          <CustomInput
            formControlProps={{ fullWidth: true }}
            inputProps={{
              name: 'titleForClient',
              value: mailingOption.titleForClient,
              onChange: ev => {
                const newMailing = [...state.mailing];
                const optionToChange = newMailing.find(
                  newMailingOption => newMailingOption.id === mailingOption.id
                );
                optionToChange.titleForClient = ev.target.value;
                optionToChange.hasChanged = true;

                setState(prev => ({
                  ...prev,
                  mailing: newMailing,
                }));
              },
            }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '10px 0 5px 0',
              flexWrap: 'wrap',
            }}
          >
            <FormLabel className={classes.labelHorizontal}>
              {t('mailNotifications.contentForClient', 'Treść dla klienta')}
            </FormLabel>
            <div>
              <Button
                onClick={() => {
                  const newMailing = [...state.mailing];
                  const optionToChange = newMailing.find(
                    newMailingOption => newMailingOption.id === mailingOption.id
                  );
                  optionToChange.isHidden = !mailingOption.isHidden;

                  return setState(prev => ({
                    ...prev,
                    mailing: newMailing,
                  }));
                }}
              >
                {mailingOption.isHidden
                  ? t('mailNotifications.showCode', 'Pokaż kod')
                  : t('mailNotifications.hideCode', 'Ukryj kod')}
              </Button>
              <Button
                onClick={() => {
                  const win = window.open(
                    '',
                    t('mailNotifications.clientPreview', 'Podgląd Klient'),
                    'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes'
                  );
                  win.document.write(mailingOption.templateForClient);
                }}
              >
                {t('menuPlanner.action.template.use.preview', 'Podgląd')}
              </Button>
              <Button
                onClick={() => {
                  renderAlert(
                    () => {
                      const newMailing = [...state.mailing];
                      const optionToChange = newMailing.find(
                        newMailingOption =>
                          newMailingOption.id === mailingOption.id
                      );
                      optionToChange.templateForClient =
                        optionToChange.defaultTemplateForClient;
                      optionToChange.hasChanged = true;

                      setState(prev => ({ ...prev, confirmModal: null }));
                      return setState(prev => ({
                        ...prev,
                        mailing: newMailing,
                      }));
                    },
                    () => setState(prev => ({ ...prev, confirmModal: null }))
                  );
                }}
              >
                {t('common.restoreDefaultFem', 'Przywróć domyślną')}
              </Button>
            </div>
          </div>
          {!mailingOption.isHidden && (
            <div style={{ maxHeight: '70vh', overflowX: 'auto' }}>
              <Editor
                value={mailingOption.templateForClient}
                onValueChange={code => {
                  const newMailing = [...state.mailing];
                  const optionToChange = newMailing.find(
                    newMailingOption => newMailingOption.id === mailingOption.id
                  );
                  optionToChange.templateForClient = code;
                  optionToChange.hasChanged = true;

                  setState(prev => ({
                    ...prev,
                    mailing: newMailing,
                  }));
                }}
                highlight={code =>
                  Prism.highlight(code, Prism.languages.jsx, 'jsx')
                }
                padding={10}
                style={{
                  fontFamily: '"Fira code", "Fira Mono", monospace',
                  fontSize: 12,
                }}
              />
            </div>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <FormLabel
            htmlFor="titleForOffice"
            className={classes.labelHorizontal}
          >
            {t(
              'mailNotifications.titleForAdministration',
              'Tytuł dla administracji'
            )}
          </FormLabel>
          <CustomInput
            formControlProps={{ fullWidth: true }}
            inputProps={{
              name: 'titleForOffice',
              value: mailingOption.titleForOffice,
              onChange: ev => {
                const newMailing = [...state.mailing];
                const optionToChange = newMailing.find(
                  newMailingOption => newMailingOption.id === mailingOption.id
                );
                optionToChange.titleForOffice = ev.target.value;
                optionToChange.hasChanged = true;

                setState(prev => ({
                  ...prev,
                  mailing: newMailing,
                }));
              },
            }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '10px 0 5px 0',
            }}
          >
            <FormLabel className={classes.labelHorizontal}>
              {t(
                'mailNotifications.contentFormAdministration',
                'Treść dla administracji'
              )}
            </FormLabel>
            <div>
              <Button
                onClick={() => {
                  const newMailing = [...state.mailing];
                  const optionToChange = newMailing.find(
                    newMailingOption => newMailingOption.id === mailingOption.id
                  );
                  optionToChange.isHidden = !mailingOption.isHidden;

                  return setState(prev => ({
                    ...prev,
                    mailing: newMailing,
                  }));
                }}
              >
                {mailingOption.isHidden
                  ? t('mailNotifications.showCode', 'Pokaż kod')
                  : t('mailNotifications.hideCode', 'Ukryj kod')}
              </Button>
              <Button
                onClick={() => {
                  const win = window.open(
                    '',
                    'Podgląd Administracja',
                    'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes'
                  );
                  win.document.write(mailingOption.templateForOffice);
                }}
              >
                {t('menuPlanner.action.template.use.preview', 'Podgląd')}
              </Button>
              <Button
                onClick={() => {
                  renderAlert(
                    () => {
                      const newMailing = [...state.mailing];
                      const optionToChange = newMailing.find(
                        newMailingOption =>
                          newMailingOption.id === mailingOption.id
                      );
                      optionToChange.templateForOffice =
                        optionToChange.defaultTemplateForOffice;
                      optionToChange.hasChanged = true;

                      setState(prev => ({ ...prev, confirmModal: null }));
                      return setState(prev => ({
                        ...prev,
                        mailing: newMailing,
                      }));
                    },
                    () => setState(prev => ({ ...prev, confirmModal: null }))
                  );
                }}
              >
                {t('common.restoreDefaultFem', 'Przywróć domyślną')}
              </Button>
            </div>
          </div>
          {!mailingOption.isHidden && (
            <div style={{ maxHeight: '70vh', overflowX: 'auto' }}>
              <Editor
                value={mailingOption.templateForOffice}
                onValueChange={code => {
                  const newMailing = [...state.mailing];
                  const optionToChange = newMailing.find(
                    newMailingOption => newMailingOption.id === mailingOption.id
                  );
                  optionToChange.templateForOffice = code;
                  optionToChange.hasChanged = true;

                  setState(prev => ({ ...prev, mailing: newMailing }));
                }}
                highlight={code =>
                  Prism.highlight(code, Prism.languages.jsx, 'jsx')
                }
                padding={10}
                style={{
                  fontFamily: '"Fira code", "Fira Mono", monospace',
                  fontSize: 12,
                }}
              />
            </div>
          )}
        </Fragment>
      )}
    </TableCell>
  );
};

export default TableContentSection;
