import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import { withToast } from 'material-ui-toast-redux';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Check, Info } from '@material-ui/icons';
import RootCheckbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/styles/makeStyles';
import { combineStyles } from 'helpers/helpers';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';

import TOAST_DURATIONS from 'helpers/toastDurations';

import useGetPaymentTypesTranslations from './hooks/useGetPaymentTypesTranslations';

const useStyles = makeStyles({
  wrapper: {
    overflowX: 'auto',
  },
  selectCell: {
    minWidth: 280,
  },
  tooltipWrapper: {
    margin: '0 0 0 10px',
  },
  align: {
    display: 'flex',
    alignItems: 'center',
  },
});

const PaymentTypeConfig = ({
  classes: propClasses,
  openToast,
  modules,
  setModules,
  paymentTypes,
  setPaymentTypes,
}) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const paymentTypesTranslations = useGetPaymentTypesTranslations();

  const validateChangeStatusPaymentType = (value, type) => {
    if (
      (type === 'standard' &&
        value.paymentTypeStandard ===
          paymentTypes.standard.defaultPaymentType) ||
      (type === 'subscription' &&
        value.paymentTypeSubscription ===
          paymentTypes.subscription.defaultPaymentType)
    ) {
      return openToast({
        messages: [
          t(
            'errors.paymentMethod.dufaultPaymentMethod.description',
            'Domyślny tryb płatności nie może zostać wyłączony'
          ),
        ],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }
  };

  const validateSetDefaultPaymentTypes = (e, subscriptionEnabled = '') => {
    if (e.target.value === 'PAYPO') {
      return openToast({
        messages: [
          t(
            'errors.paymentMethod.dufaultPaymentMethod.paypo',
            'Typ płatności PayPo nie może zostać domyślnym typem płatności'
          ),
        ],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }

    if (
      (!subscriptionEnabled &&
        !paymentTypes.standard.enabledPaymentTypes.includes(e.target.value)) ||
      (subscriptionEnabled &&
        !paymentTypes.subscription.enabledPaymentTypes.includes(e.target.value))
    ) {
      return openToast({
        messages: [
          t(
            'errors.paymentMethod.dufaultPaymentMethod.regular.disabledPaymentType',
            'Wyłączony typ płatności nie może być domyślnym trybem płatności.'
          ),
        ],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }
  };

  const paymentTypeTranslationsEntries = Object.entries(
    paymentTypesTranslations
  );

  return (
    <div className={propClasses.wrapper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {t('paymentType.operator', 'Operator płatności')}
            </TableCell>
            <TableCell>{t('paymentType.method', 'Metoda płatności')}</TableCell>
            <TableCell>
              <Checkbox
                checked={modules.ConfigClientPanel.isOneTimePayPaymentMode}
                onChange={() => {
                  setModules(prev => ({
                    ...prev,
                    ConfigClientPanel: {
                      ...prev.ConfigClientPanel,
                      isOneTimePayPaymentMode:
                        !prev.ConfigClientPanel.isOneTimePayPaymentMode,
                    },
                  }));
                }}
                checkedIcon={<Check className={propClasses.checkedIcon} />}
                icon={<Check className={propClasses.uncheckedIcon} />}
                classes={{
                  checked: propClasses.checked,
                  root: propClasses.checkRoot,
                }}
                disabled={!modules.ConfigClientPanel.isSubscriptionPaymentMode}
              />
              {t('paymentType.oneTimePayment', 'Płatność jednorazowa')}
            </TableCell>
            <TableCell>
              <Checkbox
                checked={modules.ConfigClientPanel.isSubscriptionPaymentMode}
                onChange={() => {
                  setModules(prev => ({
                    ...prev,
                    ConfigClientPanel: {
                      ...prev.ConfigClientPanel,
                      isSubscriptionPaymentMode:
                        !prev.ConfigClientPanel.isSubscriptionPaymentMode,
                    },
                  }));
                }}
                checkedIcon={<Check className={propClasses.checkedIcon} />}
                icon={<Check className={propClasses.uncheckedIcon} />}
                classes={{
                  checked: propClasses.checked,
                  root: propClasses.checkRoot,
                }}
                disabled={!modules.ConfigClientPanel.isOneTimePayPaymentMode}
              />
              {t('paymentType.subscription', 'Subskrypcja')}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentTypeTranslationsEntries.map(([key, value], index) => {
            return (
              <TableRow key={key}>
                {index === 0 && (
                  <TableCell rowSpan={7}>{value.title}</TableCell>
                )}
                {index === 7 && (
                  <TableCell rowSpan={2}>{value.title}</TableCell>
                )}

                {index === 9 && (
                  <TableCell rowSpan={1}>{value.title}</TableCell>
                )}
                {index === 10 && (
                  <TableCell rowSpan={2}>{value.title}</TableCell>
                )}
                <TableCell>
                  <div className={classes.align}>
                    {value.method}
                    {value.tooltip && (
                      <div className={classes.tooltipWrapper}>
                        <Tooltip
                          title={<h4>{value.tooltip}</h4>}
                          placement="right"
                        >
                          <Info fontSize="small" />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </TableCell>

                <TableCell>
                  {value?.paymentTypeStandard ? (
                    <RootCheckbox
                      checked={
                        value.option === 'sandbox'
                          ? modules[value.title].sandbox
                          : paymentTypes?.standard?.enabledPaymentTypes?.includes(
                              value.paymentTypeStandard
                            )
                      }
                      onChange={() => {
                        //handle sandbox payment
                        if (value.option === 'sandbox') {
                          setModules(prev => ({
                            ...prev,
                            [value.title]: {
                              ...prev[value.title],
                              sandbox: !prev[value.title].sandbox,
                            },
                          }));
                          return null;
                        }

                        //handle standard payment types
                        if (
                          validateChangeStatusPaymentType(value, 'standard')
                        ) {
                          return null;
                        }
                        const newPaymentMethod = [
                          ...paymentTypes?.standard?.enabledPaymentTypes,
                        ];

                        if (
                          newPaymentMethod.includes(value.paymentTypeStandard)
                        ) {
                          newPaymentMethod.splice(
                            newPaymentMethod.indexOf(value.paymentTypeStandard),
                            1
                          );
                        } else {
                          newPaymentMethod.push(value.paymentTypeStandard);
                        }

                        setPaymentTypes(prev => ({
                          ...prev,
                          standard: {
                            ...prev?.standard,
                            enabledPaymentTypes: newPaymentMethod,
                          },
                        }));
                      }}
                      disabled={
                        !modules.ConfigClientPanel.isOneTimePayPaymentMode
                      }
                    />
                  ) : null}
                </TableCell>

                <TableCell>
                  {value?.paymentTypeSubscription ? (
                    <RootCheckbox
                      checked={paymentTypes?.subscription?.enabledPaymentTypes?.includes(
                        value.paymentTypeSubscription
                      )}
                      onChange={() => {
                        if (
                          validateChangeStatusPaymentType(value, 'subscription')
                        ) {
                          return null;
                        }

                        const newPaymentMethod = [
                          ...paymentTypes?.subscription?.enabledPaymentTypes,
                        ];

                        if (
                          newPaymentMethod.includes(
                            value.paymentTypeSubscription
                          )
                        ) {
                          newPaymentMethod.splice(
                            newPaymentMethod.indexOf(
                              value.paymentTypeSubscription
                            ),
                            1
                          );
                        } else {
                          newPaymentMethod.push(value.paymentTypeSubscription);
                        }

                        setPaymentTypes(prev => ({
                          ...prev,
                          subscription: {
                            ...prev?.subscription,
                            enabledPaymentTypes: newPaymentMethod,
                          },
                        }));
                      }}
                      disabled={
                        !modules.ConfigClientPanel.isSubscriptionPaymentMode
                      }
                    />
                  ) : null}
                </TableCell>
                <TableCell style={{ background: `${value.designation}` }} />
              </TableRow>
            );
          })}

          <TableRow>
            <TableCell className={classes.selectCell}>
              {t(
                'module.configClientPanel.defaultPaymentMode',
                'Domyślny tryb Płatności'
              )}
            </TableCell>
            <TableCell />
            <TableCell className={classes.selectCell}>
              <FormControl fullWidth className={propClasses.selectFormControl}>
                <Select
                  value={paymentTypes?.standard?.defaultPaymentType}
                  onChange={e => {
                    if (validateSetDefaultPaymentTypes(e)) {
                      return null;
                    }

                    setPaymentTypes(prev => ({
                      ...prev,
                      standard: {
                        ...prev.standard,
                        defaultPaymentType: e.target.value,
                      },
                    }));
                  }}
                  displayEmpty
                  name="dietStandard"
                  MenuProps={{ className: propClasses.selectMenu }}
                  classes={{ select: propClasses.select }}
                >
                  {paymentTypeTranslationsEntries.map(([key, value]) => {
                    if (value.method === 'Sandbox') {
                      return null;
                    }

                    return (
                      <MenuItem
                        key={key}
                        value={value.paymentTypeStandard}
                        classes={{
                          root: propClasses.selectMenuItem,
                          selected: propClasses.selectMenuItemSelectedMultiple,
                        }}
                      >
                        {value.method}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </TableCell>
            <TableCell className={classes.selectCell}>
              <FormControl fullWidth className={propClasses.selectFormControl}>
                <Select
                  value={paymentTypes?.subscription?.defaultPaymentType}
                  onChange={e => {
                    if (
                      validateSetDefaultPaymentTypes(e, 'subscriptionEnabled')
                    ) {
                      return null;
                    }
                    setPaymentTypes(prev => ({
                      ...prev,
                      subscription: {
                        ...prev.subscription,
                        defaultPaymentType: e.target.value,
                      },
                    }));
                  }}
                  displayEmpty
                  name="dietSubscription"
                  MenuProps={{ className: propClasses.selectMenu }}
                  classes={{ select: propClasses.select }}
                  disabled={
                    !modules.ConfigClientPanel.isSubscriptionPaymentMode
                  }
                >
                  {paymentTypeTranslationsEntries.map(([key, value]) => {
                    if (value.method === 'Sandbox') {
                      return null;
                    }

                    if (value?.paymentTypeSubscription) {
                      return (
                        <MenuItem
                          key={key}
                          value={value.paymentTypeSubscription}
                          classes={{
                            root: propClasses.selectMenuItem,
                            selected:
                              propClasses.selectMenuItemSelectedMultiple,
                          }}
                        >
                          {value.method}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
              </FormControl>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(withToast, withStyles(combinedStyles));

export default enhance(PaymentTypeConfig);
