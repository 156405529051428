import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

import Select from 'components/CustomSelect/Select';
import Checkbox from 'components/CustomCheckbox/Checkbox';

import { withToast } from 'material-ui-toast-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { sortByKey } from 'helpers/helpers';

import { grayColor } from 'assets/jss/material-dashboard-pro-react';

import BoxSection from '../BoxSection';
import AddonsSection from '../AddonsSection/AddonsSection';
import CalendarSection from '../CalendarSection/CalendarSection';
import DietNameSection from '../DietNameSection/DietNameSection';
import DietSummarySection from '../DietSummarySection/DietSummarySection';
import DeliveryAddressSection from '../DeliveryAddressSection/DeliveryAddressSection';
import { get } from 'helpers/apiHelpers';

import constants from 'helpers/constants';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import AdditionalMealTypeSection from '../AdditionalMealTypeSection/AdditionalMealTypeSection';
import SecuredView from 'components/Security/SecuredView';
import { ROLE_SHOW_ADDON } from 'helpers/roles';

import makeStyles from '@material-ui/styles/makeStyles';
import TOAST_DURATIONS from 'helpers/toastDurations';

const useStyles = makeStyles({
  dietConfigurationWrapper: {
    display: 'flex',
    width: '100%',
    margin: '0 -5px',
    flexFlow: 'column',
    '@media (min-width: 769px)': {
      flexFlow: 'row',
    },
  },
  dietConfigurationSectionsWrapper: {
    width: '100%',
    margin: '5px',
  },
  parentType: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '15px',
    justifyContent: 'space-between',
  },
  dietDetails: {
    width: '60%',
    marginLeft: '15px',
  },
  dietDetailsTest: {
    display: 'flex',
    alignItems: 'center',
  },
  infoIcon: {
    marginLeft: '-10px',
    color: 'grey',
    fontSize: '16px',
  },
  boxSection: {
    margin: '15px 0 0',
  },
  flex: { display: 'flex' },
  divider: {
    paddingBottom: '15px',
    borderBottom: `1px solid ${grayColor[4]}`,
  },
});

const DietConfigurationSection = ({
  diet = {},
  classes: propClasses,
  openToast,
  dietCost = {},
  pickUpPoints = [],
  deliveryTypes = [],
  selectedCustomer,
  calendarSettings,
  availableAddons = [],
  customerAddresses = [],
  useTestMode,
  isAllowedPremium,
  isAllowedTestDays,
  isMoreThanOneDiet,
  isAllowedEcoContainer,
  dietIndex,
  brandInfo,
  updateDiet = () => {},
  setUseTestMode = () => {},
  updateCustomerAddresses = () => {},
}) => {
  const { t } = useTranslation();

  const {
    selectedDiet,
    selectedVariant,
    selectedSuperVariant,
    useEcoContainers,
    optionChangeMenu,
    selectedCalorific,
  } = diet ?? {};

  const classes = useStyles();

  const [availableVariants, setAvailableVariants] = useState([]);
  const [availableCalorifics, setAvailableCalorifics] = useState([]);
  const [priceRecalculation, setPriceRecalculation] = useState(false);
  const [dietsCost, setDietsCost] = useState([]);
  const [additionalPrices, setAdditionalPrices] = useState([]);
  const [availableMealTypes, setAvailableMealTypes] = useState([]);
  const [superVariantMode, setSuperVariantMode] = useState(false);

  const [allowedMealTypes, setAllowedMealTypes] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [selectedDietAdditionalCalendar, setSelectedDietAdditionalCalendar] =
    useState([]);
  const [editAdditionalMealTypes, setEditAdditionalMealTypes] = useState('');
  const { diets, variants, mealTypes, currency } = useSelector(state => {
    const variants = sortByKey(state.Variants.variants, 'position').sort(
      (_, b) => (b.active ? 1 : -1)
    );
    const diets = sortByKey(state.Diets.diets, 'position')
      .sort((_, b) => (b.active ? 1 : -1))
      .map(diet => ({
        label: `${diet?.name} ${
          diet?.active ? '' : `(${t('$*common.inactive.diet', '$$Nieaktywna')})`
        }`,
        value: diet?.['@id'],
      }));

    const mealTypes = state.MealTypes.mealTypes;

    return {
      diets,
      variants,
      mealTypes,
      currency: state?.Brands?.brand?.multinational?.currency,
    };
  });

  useEffect(() => {
    get('/diet-costs', { pagination: false }).then(response => {
      setDietsCost(response['hydra:member']);
    });
    get('/additional-prices').then(response => {
      const additionalPrices = response['hydra:member'];
      get('/additional-price-types').then(response => {
        const additionalPricesTypes = response['hydra:member'];
        const additionalPricesWithAmount = additionalPrices?.map(item => {
          const types = additionalPricesTypes?.filter(
            type => type['@id'] === item?.additionalPriceType
          );
          return {
            systemValue: types[0]?.systemValue,
            price: item?.discount,
          };
        });
        setAdditionalPrices(additionalPricesWithAmount);
      });
    });
  }, []);

  useEffect(() => {
    handleSetCost();
  }, [priceRecalculation, useTestMode]);

  useEffect(() => {
    if (!isEmpty(selectedDiet)) {
      const filteredVariants = variants
        .filter(variant => (variant?.diets ?? []).includes(selectedDiet?.value))
        .sort((_, b) => (b.active ? 1 : -1))
        .map(variant => {
          return {
            value: variant?.['@id'],
            label: `${variant?.name} ${
              variant?.active
                ? ''
                : `(${t('$*common.inactive.variant', '$$Nieaktywny')})`
            }`,
            mealTypes: variant.mealTypes ?? [],
            calories: variant.calories ?? [],
            superVariantBeta: variant?.superVariantBeta,
            minPriceVariant: variant?.minPrice ?? null,
            minMealTypesVariant: variant.minMealTypes ?? null,
          };
        });
      setSuperVariantMode(false);
      if (
        filteredVariants.length === 1 &&
        filteredVariants[0].superVariantBeta
      ) {
        updateDiet({
          ...diet,
          selectedVariant: filteredVariants[0],
          selectedCalorific: null,
        });
        setSuperVariantMode(true);
      }
      setAvailableVariants(filteredVariants);
    }
  }, [selectedDiet]);

  const pricesChangeMenu = additionalPrices?.filter(
    el => el?.systemValue === 'CHANGE_MENU'
  );

  const pricesEcoContainer = additionalPrices?.filter(
    el => el?.systemValue === 'ECO_CONTAINER'
  );
  // Update available calorifics and set avalaibleMealTypes
  useEffect(() => {
    if (!isEmpty(selectedVariant)) {
      if (selectedVariant.superVariantBeta) {
        const findedVariant = variants.find(
          variant => variant['@id'] === selectedVariant.value
        );
        const mealTypeFullData = mealTypes.filter(mealType =>
          findedVariant.mealTypes.includes(mealType['@id'])
        );

        const mealTypesConverted = mealTypeFullData.map(el => {
          return {
            value: el['@id'],
            label: el.name,
          };
        });
        setAvailableMealTypes(mealTypesConverted);
      }

      const currentVariantCalories = variants.find(
        variant => variant?.['@id'] === selectedVariant?.value
      )?.calories ?? [{}];

      const formattedCalorifics = currentVariantCalories
        .sort((_, b) => (b.active ? 1 : -1))
        .map(calorific => ({
          value: calorific?.['@id'],
          label: `${calorific?.name} ${
            calorific?.active
              ? ''
              : `(${t('$*common.inactive.calorific', '$$Nieaktywna')})`
          }`,
        }));
      setAvailableCalorifics(formattedCalorifics);
    }
    setPriceRecalculation(!priceRecalculation);
  }, [selectedVariant, useTestMode]);

  useEffect(() => {
    if (mealTypes && selectedVariant && selectedCalorific) {
      const allowedAdditionalMealTypes = mealTypes
        .filter(mealType => selectedVariant.mealTypes.includes(mealType['@id']))
        .map(mealType => {
          const activeCalorific = selectedVariant.calories.filter(calories => {
            if (calories['@id'] === selectedCalorific.value) {
              return calories;
            }
          });

          const activeCalorificSize = activeCalorific[0].sizes.filter(sizes => {
            if (sizes.mealType === mealType['@id']) {
              return sizes;
            }
          });

          const activeMealTypeSize = mealType.sizes.filter(sizes => {
            if (sizes['@id'] === activeCalorificSize[0].size) {
              return sizes;
            }
          });

          return {
            value: mealType['@id'],
            label: mealType.name,
            price: activeMealTypeSize[0]?.defaultCostForShop,
            date: [],
          };
        });
      setAllowedMealTypes(allowedAdditionalMealTypes);
    }
    if (selectedCalorific === null) {
      setAllowedMealTypes([]);
    }
  }, [selectedCalorific]);

  useEffect(() => {
    updateDiet({
      ...diet,
      selectedSuperVariant: availableMealTypes,
    });
  }, [availableMealTypes]);

  const handleUpdateDiet = (key, value) => {
    updateDiet({ ...diet, [key]: value });
  };

  const handleSetCost = () => {
    const calorificsIri = availableCalorifics?.map(
      calorific => calorific?.value
    );
    const dietIri = selectedDiet?.value;

    let filteredDiet = dietsCost?.filter(diet => {
      return diet?.diet === dietIri && calorificsIri.includes(diet?.calorific);
    });
    const updatedData = availableCalorifics?.map(item => {
      const filteredCosts = filteredDiet?.filter(
        cost => cost?.calorific === item?.value
      );
      const price =
        filteredCosts[0]?.price === 999 ||
        filteredCosts[0]?.price === null ||
        filteredCosts[0]?.price === undefined
          ? t('orders.noPrice', 'brak ceny')
          : `(${
              useTestMode
                ? filteredCosts[0]?.priceForTest
                : filteredCosts[0]?.price
            } ${
              `${currency} ` +
              t(
                'orders.calorificDescriptionCurrencyPerDay',
                `brutto/ dzień - za pakiet wszystkich dań`
              )
            })`;

      return {
        ...item,
        label: `${item?.label} ${price}`,
      };
    });
    setAvailableCalorifics(updatedData);
  };

  const handleTestModeCheck = () => {
    if (isAllowedTestDays) {
      setUseTestMode(!useTestMode);
    }
  };

  return (
    <div className={classes.dietConfigurationWrapper}>
      <div className={classes.dietConfigurationSectionsWrapper}>
        <BoxSection title={t('orders.dietDetails', '$$Szczegóły diety')}>
          <div className={classes.parentType}>
            <p className={propClasses.labelBlack}>
              {t('orders.dietType', '$$Rodzaj diety')}*
            </p>
            <div className={classes.dietDetails} data-cy="diet-details_diets">
              <Select
                label={t('orders.select', '$$Wybierz')}
                value={selectedDiet}
                options={diets}
                handleChange={option => {
                  updateDiet({
                    ...diet,
                    selectedDiet: option,
                    selectedVariant: null,
                    selectedSuperVariant: null,
                    selectedCalorific: null,
                    selectedAdditionalMealTypes: [],
                  });
                  setAllowedMealTypes([]);
                  setSelectedOptions([]);
                }}
              />
            </div>
          </div>
          {!superVariantMode && (
            <div className={classes.parentType}>
              <p className={propClasses.labelBlack}>
                {t('orders.variant', '$$Wariant')}*
              </p>
              <div
                data-cy="diet-details_variants"
                className={classes.dietDetails}
                onClick={() =>
                  isEmpty(selectedDiet)
                    ? openToast({
                        messages: [
                          t(
                            'orders.firstSelectDate',
                            '$$Najpierw wybierz dietę'
                          ),
                        ],
                        type: 'info',
                        autoHideDuration: TOAST_DURATIONS.SM,
                      })
                    : null
                }
              >
                <Select
                  label={t('orders.select', '$$Wybierz')}
                  value={selectedVariant}
                  options={availableVariants}
                  isDisabled={isEmpty(selectedDiet)}
                  handleChange={option => {
                    setAllowedMealTypes([]);
                    setSelectedOptions([]);
                    updateDiet({
                      ...diet,
                      selectedVariant: option,
                      selectedCalorific: null,
                      selectedAdditionalMealTypes: [],
                    });
                  }}
                />
              </div>
            </div>
          )}
          {selectedVariant?.superVariantBeta && (
            <div className={classes.parentType}>
              <p className={propClasses.labelBlack}>
                {superVariantMode
                  ? t('orders.variant', '$$Wariant')
                  : t('orders.superVariantBeta', '$$Super wariant')}
                *
              </p>
              <div
                ata-cy="diet-details_calorific"
                className={classes.dietDetails}
                onClick={() =>
                  isEmpty(selectedDiet)
                    ? openToast({
                        messages: [
                          t(
                            'orders.firstSelectDate',
                            '$$Najpierw wybierz dietę'
                          ),
                        ],
                        type: 'info',
                        autoHideDuration: TOAST_DURATIONS.SM,
                      })
                    : null
                }
              >
                <Select
                  isClearable={false}
                  label={t('orders.select', '$$Wybierz')}
                  value={selectedSuperVariant}
                  options={availableMealTypes}
                  isDisabled={isEmpty(selectedDiet)}
                  multiple={true}
                  handleChange={option => {
                    updateDiet(
                      {
                        ...diet,
                        selectedSuperVariant: option,
                      },
                      dietIndex ? dietIndex : 0
                    );
                  }}
                />
              </div>
            </div>
          )}
          <div className={classes.parentType}>
            <p className={propClasses.labelBlack}>
              {t('orders.calorific', '$$Kaloryczność')}*
            </p>
            <div
              className={classes.dietDetails}
              onClick={() =>
                isEmpty(selectedVariant)
                  ? openToast({
                      messages: [
                        t(
                          'orders.firstSelectVariant',
                          '$$Najpierw wybierz wariant'
                        ),
                      ],
                      type: 'info',
                      autoHideDuration: TOAST_DURATIONS.SM,
                    })
                  : null
              }
            >
              <Select
                label={t('orders.select', '$$Wybierz')}
                value={selectedCalorific}
                options={availableCalorifics}
                isDisabled={isEmpty(selectedVariant)}
                handleChange={option => {
                  setSelectedOptions([]);
                  updateDiet({
                    ...diet,
                    selectedCalorific: option,
                    selectedAdditionalMealTypes: [],
                  });
                }}
              />
            </div>
          </div>
          <div className={classes.divider}></div>
          <DietNameSection
            diet={diet}
            classes={propClasses}
            updateDiet={updateDiet}
          />

          {isAllowedPremium === constants.PREMIUM ? (
            <div data-cy="diet-details_premium">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={optionChangeMenu}
                    onClick={e => {
                      handleUpdateDiet('optionChangeMenu', !optionChangeMenu);
                    }}
                  />
                }
                label={
                  t('orders.possibilitySelectMeals', '$$Wybór menu “Premium') +
                  ` (${
                    pricesChangeMenu[0]?.price === null ||
                    pricesChangeMenu[0]?.price === undefined
                      ? null
                      : pricesChangeMenu[0]?.price
                  }${t(
                    'orders.calorificDescriptionCurrencyPerDay',
                    'zł brutto/ dzień'
                  )})`
                }
              />
            </div>
          ) : null}

          {isAllowedEcoContainer ? (
            <div data-cy="diet-details_eco-container">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={useEcoContainers}
                    onClick={e => {
                      handleUpdateDiet('useEcoContainers', !useEcoContainers);
                    }}
                  />
                }
                label={
                  t(
                    'orders.newOrder.DietConfigurationSection.ecoBoxes',
                    'Eko pojemniki'
                  ) +
                  ` (${
                    pricesEcoContainer[0]?.price === null ||
                    pricesEcoContainer[0]?.price === undefined
                      ? null
                      : pricesEcoContainer[0]?.price
                  }${
                    ` ${currency} ` +
                    t(
                      'orders.ecoContainerDescriptionCurrencyPerDay',
                      'brutto/ dzień'
                    )
                  })`
                }
              />
            </div>
          ) : null}

          {!isMoreThanOneDiet && (
            <div
              data-cy="diet-details_test-diet"
              className={classes.dietDetailsTest}
            >
              <FormControlLabel
                disabled={!isAllowedTestDays}
                control={
                  <Checkbox
                    checked={useTestMode}
                    onClick={handleTestModeCheck}
                  />
                }
                label={t(
                  'orders.newOrder.DietConfigurationSection.testDays',
                  'Zamówienie testowe'
                )}
              />
              {!isAllowedTestDays && (
                <Tooltip
                  title={
                    <h4>
                      {t(
                        'orders.newOrder.DietConfigurationSection.testDays.tooltip',
                        'Ten klient posiada już zakupioną dietę, dlatego tryb zamówienia testowego jest dla niego niedostępny'
                      )}
                    </h4>
                  }
                  placement="right"
                >
                  <Info className={classes.infoIcon} />
                </Tooltip>
              )}
            </div>
          )}
        </BoxSection>
        <BoxSection
          title={t('orders.deliveryAddress', '$$Adres dostawy')}
          style={{ margin: '15px 0 0' }}
        >
          <DeliveryAddressSection
            diet={diet}
            classes={propClasses}
            updateDiet={updateDiet}
            pickUpPoints={pickUpPoints}
            deliveryTypes={deliveryTypes}
            selectedCustomer={selectedCustomer}
            customerAddresses={customerAddresses}
            updateCustomerAddresses={updateCustomerAddresses}
          />
        </BoxSection>
      </div>
      <div className={classes.dietConfigurationSectionsWrapper}>
        <BoxSection title={t('orders.duration', '$$Czas trwania')}>
          <CalendarSection
            diet={diet}
            brandInfo={brandInfo}
            classes={propClasses}
            updateDiet={updateDiet}
            calendarSettings={calendarSettings}
            setSelectedOptions={setSelectedOptions}
          />
        </BoxSection>

        <div className={classes.flex}>
          <BoxSection
            title={t('orders.additionalMealTypes', '$$Dodatkowe porcje')}
            style={{ margin: '15px 5px 0  0' }}
          >
            <AdditionalMealTypeSection
              calendarSettings={calendarSettings}
              diet={diet}
              classes={propClasses}
              updateDiet={updateDiet}
              allowedMealTypes={allowedMealTypes}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              selectedDietAdditionalCalendar={selectedDietAdditionalCalendar}
              setSelectedDietAdditionalCalendar={
                setSelectedDietAdditionalCalendar
              }
              editAdditionalMealTypes={editAdditionalMealTypes}
              setEditAdditionalMealTypes={setEditAdditionalMealTypes}
            />
          </BoxSection>

          <SecuredView role={ROLE_SHOW_ADDON} returnNull={true}>
            <BoxSection
              title={t('orders.addons', '$$Dodatki')}
              style={{ margin: '15px 0 0 5px' }}
            >
              <AddonsSection
                diet={diet}
                classes={propClasses}
                updateDiet={updateDiet}
                availableAddons={availableAddons}
              />
            </BoxSection>
          </SecuredView>
        </div>
      </div>

      <div className={classes.dietConfigurationSectionsWrapper}>
        <DietSummarySection
          diet={diet}
          dietCost={dietCost}
          dietIndex={dietIndex}
        />
      </div>
    </div>
  );
};

export default withToast(DietConfigurationSection);
