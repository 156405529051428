import React, { Component } from 'react';
import defaultState from './defaultState';
import withStyles from '@material-ui/core/styles/withStyles';
import { withToast } from 'material-ui-toast-redux';
import { combineStyles, errorNotificationMapObject } from 'helpers/helpers';
import { connect } from 'react-redux';
import { put } from 'helpers/apiHelpers';
import { refreshLogin } from 'actions/Auth';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';

import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';

import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Muted from 'components/Typography/Muted';

import FormLabel from '@material-ui/core/FormLabel';

import { withTranslation } from 'react-i18next';

import userPhotoTemplate from 'assets/img/new_logo.png';
import { ReactPhoneNumberAdapter } from '../../components/ReactPhoneNumberAdapter/ReactPhoneNumberAdapter';
import CardWrapper from 'components/Card/CardWrapper';
import TOAST_DURATIONS from 'helpers/toastDurations';

class MyProfile extends Component {
  state = {
    ...defaultState,
    passwordError: '',
    dataLoaded: false,
  };

  componentDidMount() {
    this.setState({
      firstName: this.props.user.firstName,
      lastName: this.props.user.lastName,
      phone: this.props.user.phone,
      phoneCountryCode: this.props.user.phoneCountryCode,
      email: this.props.user.email.toLowerCase(),
      image: this.props.user.image,
      imageUrl: this.props.user.imageUrl,
      dataLoaded: true,
    });
  }

  handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handlePhoneNumberChange = (phone, phoneCountryCode) => {
    this.setState({ phone, phoneCountryCode });
  };

  validateEmail = () => {
    return this.state.email.includes('@');
  };

  validatePassword = () => {
    return this.state.plainPasswordMatch === this.state.plainPassword;
  };

  validateForm = () => {
    const { t } = this.props;
    if (!this.validateEmail()) {
      this.props.openToast({
        messages: [
          t('myCompany.toast.incorrectEmail', 'Nieprawidłowy adres email'),
        ],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
      return;
    }

    if (!this.validatePassword()) {
      this.props.openToast({
        messages: [t('errors.passwordsDontMatch', 'Hasła nie są takie same')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });

      return;
    }

    if (
      !this.state.firstName ||
      !this.state.lastName ||
      !this.state.phone ||
      !this.state.email
    ) {
      this.props.openToast({
        messages: [
          t(
            'errors.fillAllRequiredFields',
            'Wypelnij wszystkie obowiązkowe pola oznaczone gwiazdką'
          ),
        ],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });

      return;
    }
    return true;
  };

  handleSubmit = async () => {
    const { t } = this.props;
    if (!this.validateForm()) {
      return;
    }
    const data = {
      type: 'EMPLOYEE',
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: {
        number: this.state.phone,
        countryCode: this.state.phoneCountryCode,
      },
      email: this.state.email,
      image: this.state.image,
      plainPassword:
        this.state.plainPassword !== '' ? this.state.plainPassword : null,
    };

    await put(this.props.user.id, data).then(
      () => {
        this.props.openToast({
          messages: [t('success.dataUpdated', 'Zaktualizowano dane')],
          type: 'success',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
        this.props.refreshLogin();
      },
      error => {
        const { propertyPath, message } = error.response.data.violations[0];
        if (propertyPath === 'email' || propertyPath === 'plainPassword') {
          const prefix = errorNotificationMapObject[propertyPath];
          this.props.openToast({
            messages: [`${prefix}: ${message}`],
            type: 'error',
            autoHideDuration: TOAST_DURATIONS.SM,
          });
          return false;
        } else {
          this.props.openToast({
            messages: [
              'Coś poszło nie tak. Upewnij się, że formularz został poprawnie wypełniony', //errors.checkFormAgain
            ],
            type: 'error',
            autoHideDuration: TOAST_DURATIONS.SM,
          });
          return false;
        }
      }
    );
  };

  getImage = (stateName, data) => {
    this.setState({
      [stateName]: data?.['@id'] || null,
      [`${stateName}Url`]: data?.contentUrl || null,
    });
  };

  removeImage = stateName => {
    this.setState({
      [stateName]: null,
      [`${stateName}Url`]: null,
    });
  };

  // translate
  render() {
    const { classes } = this.props;
    const {
      firstName,
      lastName,
      phone,
      phoneCountryCode,
      email,
      plainPassword,
      plainPasswordMatch,
      imageUrl,
    } = this.state;

    if (!this.state.dataLoaded) return null;

    return (
      <form>
        <GridContainer style={{ marginTop: '15px' }}>
          <GridItem xs={12}>
            <CardWrapper title={'Mój profil'}>
              <CardBody>
                <GridContainer>
                  <GridItem md={6}>
                    <FormTextInput
                      label="Imię *"
                      classes={classes}
                      name="firstName"
                      value={firstName}
                      handleChange={this.handleInputChange}
                      inputSize={12}
                    />
                    <FormTextInput
                      label="Nazwisko *"
                      classes={classes}
                      name="lastName"
                      value={lastName}
                      handleChange={this.handleInputChange}
                      inputSize={12}
                    />
                    <ReactPhoneNumberAdapter
                      label="Telefon  *"
                      name="phone"
                      value={{ number: phone, countryCode: phoneCountryCode }}
                      onChange={this.handlePhoneNumberChange}
                      inputSize={12}
                    />
                    <FormTextInput
                      label="Adres e-mail *"
                      classes={classes}
                      name="email"
                      value={email}
                      handleChange={this.handleInputChange}
                      inputSize={12}
                    />
                    <FormTextInput
                      label="Nowe hasło:"
                      classes={classes}
                      name="plainPassword"
                      value={plainPassword}
                      type="password"
                      noAutoComplete
                      handleChange={this.handleInputChange}
                      inputSize={12}
                    />
                    <FormTextInput
                      label="Powtórz nowe hasło:"
                      classes={classes}
                      name="plainPasswordMatch"
                      value={plainPasswordMatch}
                      success={
                        plainPassword !== '' &&
                        plainPassword === plainPasswordMatch
                      }
                      error={!(plainPassword === plainPasswordMatch)}
                      type="password"
                      handleChange={this.handleInputChange}
                      inputSize={12}
                    />
                    <Muted>
                      Hasło powinno mieć przynajmniej 8 znaków, jedną duża
                      literę i cyfrę
                    </Muted>
                  </GridItem>
                  <GridItem md={6}>
                    <FormLabel
                      className={classes.labelHorizontal}
                      style={{ marginBottom: '20px' }}
                    >
                      Zdjęcie:
                    </FormLabel>
                    <FormImageUpload
                      classes={classes}
                      stateName="image"
                      getImage={this.getImage}
                      removeImage={this.removeImage}
                      previewUrl={imageUrl}
                      defaultImage={userPhotoTemplate}
                    />
                  </GridItem>
                </GridContainer>
                <FormControlButtons
                  classes={classes}
                  submitText="Zapisz"
                  handleSubmit={this.handleSubmit}
                />
              </CardBody>
            </CardWrapper>
          </GridItem>
        </GridContainer>
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.Auth.user,
  };
};

const mapDispatchToProps = {
  refreshLogin,
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToast(withStyles(combinedStyles)(withTranslation()(MyProfile))));
