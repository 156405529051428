import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  FormControlLabel,
  FormLabel,
  Tooltip,
} from '@material-ui/core';
import { Check, Info } from '@material-ui/icons';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import SelectInput from 'components/FormSelect/SelectInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from '../../../components/CustomButtons/Button';
import { post } from '../../../helpers/apiHelpers';
import { withToast } from 'material-ui-toast-redux';
import TOAST_DURATIONS from 'helpers/toastDurations';

const termsSupportTypes = [
  {
    value: 'STANDARD',
    translationKey: 'brands.newBrandForm.salesMango.mode.standard',
  },
  {
    value: 'TAG',
    translationKey: 'brands.newBrandForm.salesMango.mode.tag',
  },
  {
    value: 'COOKIEBOT',
    translationKey: 'brands.newBrandForm.salesMango.mode.cookiebot',
  },
];

const SalesManagoModule = ({
  classes,
  handleModuleUpdate,
  module,
  openToast,
}) => {
  const { t } = useTranslation();
  const [isSyncing, setIsSyncing] = useState(false);

  const handleClientsSync = async () => {
    setIsSyncing(true);
    try {
      await post('/sales-manago/client-sync');
      openToast({
        messages: [
          t(
            'brands.newBrandForm.thulium.syncContacts.successMessage',
            'Żądanie zostało wysłane. Synchronizacja klientów to proces który może chwilę potrwać'
          ),
        ],
        type: 'success',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    } catch {
    } finally {
      setIsSyncing(false);
    }
  };

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          replace: 'PayUModule',
        })}
      </div>
    );
  }

  return !module ? (
    <div>
      {t('brands.newBrandForm.moduleNotLoaded', {
        defaultValue: '{{moduleName}} not loaded.',
        replace: { moduleName: __filename.split('.')[0] },
      })}
    </div>
  ) : (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={module.enabled}
                onChange={e =>
                  handleModuleUpdate({
                    ...module,
                    enabled: !module.enabled,
                  })
                }
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            label={t(
              'brands.newBrandForm.useSalesMangoLabel',
              'Używaj Sales Manago'
            )}
          />

          <Button disabled={isSyncing} onClick={handleClientsSync}>
            {t('brands.newBrandForm.thulium.syncContacts', 'Synchronizuj')}
          </Button>
          <Tooltip
            title={
              <h4>
                {t(
                  'brands.newBrandForm.thulium.syncContacts.tooltip',
                  '$$Synchronizacja klientów może chwilę zająć'
                )}
              </h4>
            }
            placement="top"
          >
            <Info fontSize={'small'} style={{ color: 'gray' }} />
          </Tooltip>
        </GridItem>
        <GridItem xs={12} md={12}>
          <FormTextInput
            label={t('brands.newBrandForm.salesMango.endpoint', 'Endpoint')}
            classes={classes}
            name="salesManagoEndpoint"
            value={module.endpoint}
            maxLength={64}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                endpoint: e.target.value,
              })
            }
            inputSize={12}
          />
          <FormTextInput
            label={t('brands.newBrandForm.salesMango.clientId', 'ID klienta')}
            classes={classes}
            name="salesManagoClientId"
            value={module.clientId}
            maxLength={64}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                clientId: e.target.value,
              })
            }
            inputSize={12}
          />
          <FormTextInput
            label="API Secret"
            classes={classes}
            name="salesManagoApiSecret"
            value={module.apiSecret}
            maxLength={64}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                apiSecret: e.target.value,
              })
            }
            inputSize={12}
          />
          <FormTextInput
            label="MicroSite Key"
            classes={classes}
            name="salesManagoApiKey"
            value={module.apiKey}
            maxLength={64}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                apiKey: e.target.value,
              })
            }
            inputSize={12}
          />
        </GridItem>
        <GridItem xs={12} md={12}>
          <FormTextInput
            label={t(
              'brands.newBrandForm.salesMango.email',
              'Sales Manago E-mail'
            )}
            classes={classes}
            name="salesManagoEmail"
            value={module.email}
            maxLength={64}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                email: e.target.value,
              })
            }
            inputSize={12}
          />

          <FormTextInput
            label={
              <div style={{ display: 'flex' }}>
                <span>
                  {t('brands.newBrandForm.salesMango.location', 'Lokalizacja')}
                </span>
                <div
                  style={{
                    marginLeft: '10px',
                    display: 'inline-block',
                  }}
                >
                  <Tooltip
                    title={
                      <div>
                        <h4>
                          {t(
                            'brands.newBrandForm.salesMango.locationTooltip',
                            'Wprowadź nazwę cateringu jaka ma pokazywać się w SalesManago'
                          )}
                        </h4>
                      </div>
                    }
                    placement="right"
                  >
                    <Info fontSize={'small'} />
                  </Tooltip>
                </div>
              </div>
            }
            classes={classes}
            name="salesManagoName"
            value={module.name}
            maxLength={64}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                name: e.target.value,
              })
            }
            inputSize={12}
          />
          <FormLabel className={classes.labelHorizontal}>
            {t('brands.newBrandForm.salesMango.activationMode')}
          </FormLabel>
          <SelectInput
            noGrid
            classes={classes}
            mapBy="label"
            trackBy="value"
            name="salesManagoTermsSupportType"
            value={module.termsSupportType}
            options={termsSupportTypes.map(({ value, translationKey }) => {
              return { value, label: t(translationKey) };
            })}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                termsSupportType: e.target.value,
              })
            }
            size={12}
          />
          <GridItem sm={12}>
            <FormTextInput
              label={t(
                'brands.newBrandForm.salesMango.tagForConsents',
                'Tag dla zgód'
              )}
              classes={classes}
              name="salesManagoTermsTag"
              value={module.termsTag}
              maxLength={64}
              handleChange={e =>
                handleModuleUpdate({
                  ...module,
                  email: e.target.value,
                })
              }
              inputSize={12}
            />
            <FormLabel className={classes.labelHorizontal}>
              {t('brands.newBrandForm.salesMango.describe')}
            </FormLabel>
            <SelectInput
              noGrid
              classes={classes}
              mapBy="label"
              trackBy="value"
              name="salesManagoOrderEventType"
              value={module.orderEventType}
              options={[
                {
                  label: 'Zamówione diety jako zdarzenia zewnętrzne',
                  value: 'EXTERNAL_EVENT',
                },
                {
                  label: 'Zamówione diety jako produkty z product feed',
                  value: 'PRODUCT_FEED',
                },
              ]}
              handleChange={e =>
                handleModuleUpdate({
                  ...module,
                  orderEventType: e.target.value,
                })
              }
              size={6}
            />
          </GridItem>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default withToast(SalesManagoModule);
