import PropTypes from 'prop-types';
import React from 'react';
import { withToast } from 'material-ui-toast-redux';
import Datetime from 'react-datetime';
import { connect } from 'react-redux';
import axios from 'helpers/gastro';
//styles
import { combineStyles, isGranted } from 'helpers/helpers';
import { isDatepickerValidDay } from 'helpers/dateHelpers';
//components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import SelectAll from 'components/SelectAll';

import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import { Dialog, DialogContent } from '@material-ui/core';
import AccessDenied from '../../components/Security/AccessDenied';
import { get } from '../../helpers/apiHelpers';
import { ROLE_SHOW_SUB_BRAND } from '../../helpers/roles';
import ReportConfigurator from '../../components/Report/ReportConfigurator';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import Archive from '../../components/Archive/Archive';
import MultiplierCalculator from 'components/MultiplierCalculator/MultiplierCalculator';
import CardWrapper from 'components/Card/CardWrapper';
import DownloadReportButtons from './DownloadReportButtons/DownloadReportButtons';
import TOAST_DURATIONS from 'helpers/toastDurations';

class BrandDateSubbrandsReport extends React.Component {
  state = {
    selectedBrands: [],
    subBrands: [],
    selectedSubBrands: [],
    date: '',
    dateFrom: '',
    dateTo: '',
    multiplier: 1,
    isGenerating: false,
    multiplierCalculatorOpen: false,
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  validateDate = () => {
    return this.props.useDateRange
      ? this.state.dateFrom && this.state.dateTo
      : this.state.date;
  };

  validateBrands = () => {
    if (this.props.validateBrands) {
      return this.state.selectedBrands.length !== 0;
    }

    return true;
  };

  fetchSubBrands = async selected => {
    const selectInBrands = selected.length
      ? selected.map(brand => brand.value).filter(val => val !== '*')
      : [];

    let params = {};
    if (selectInBrands.length > 0) {
      params.selectInBrands = selectInBrands;
    }
    const response = await get('/sub-brands', params);

    const allSubBrandsElement = this.state.selectedSubBrands.find(
      subBrand => subBrand.value === '*'
    );

    const subBrands = response['hydra:member'];

    const selectedSubBrands = !!allSubBrandsElement
      ? subBrands.map(({ name: label, '@id': value }) => ({ label, value }))
      : [];

    return this.setState({
      subBrands,
      selectedSubBrands: [allSubBrandsElement, ...selectedSubBrands],
    });
  };

  componentDidMount() {
    if (isGranted(ROLE_SHOW_SUB_BRAND)) {
      this.fetchSubBrands([]);
    }
  }

  handleGenerate = (mimeType, lang) => {
    this.setState({ isGenerating: true });
    if (!this.validateDate()) {
      this.setState({ isGenerating: false });
      return this.props.openToast({
        messages: [this.props.t('reports.sDate')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }
    if (!this.validateBrands()) {
      this.setState({ isGenerating: false });
      return this.props.openToast({
        messages: [this.props.t('reports.selectBrand')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }

    const baseParams = {
      multiplier: this.state.multiplier,
      subBrands:
        this.state.selectedSubBrands.map(subBrand => subBrand?.value) || null,
    };
    const { eachRecipeOnNewPage } = this.props;
    let additionalParams = {};
    if (typeof this.props.additionalParams === 'object') {
      additionalParams = this.props.additionalParams;
    }

    if (typeof eachRecipeOnNewPage === 'boolean') {
      additionalParams.eachRecipeOnNewPage = eachRecipeOnNewPage;
    }
    const params = this.props.onlySubBrands
      ? {
          ...baseParams,
          ...additionalParams,
          date: this.state.date,
        }
      : {
          ...baseParams,
          ...additionalParams,
          ...(this.props.useDateRange
            ? {
                dateFrom: this.state.dateFrom,
                dateTo: this.state.dateTo,
              }
            : { date: this.state.date }),
          brands: this.state.selectedBrands.map(brand => brand.value),
        };

    axios
      .get(this.props.url, {
        responseType: 'blob',
        params: params,
        headers: { accept: mimeType },
        forcedLanguage: lang,
      })
      .then(
        response => {
          if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `${this.props.fileName(this.state)}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
            this.setState({ isGenerating: false });
          } else {
            alert(this.props.t('reports.noAccess'));
          }
        },
        error => {
          this.props.openToast({
            messages: [
              this.props.t('reports.cannotGenerate'),
              error.response.data['hydra:description'],
            ],
            type: 'error',
            autoHideDuration: TOAST_DURATIONS.SM,
          });
          this.setState({ isGenerating: false });
        }
      )
      .finally(() => {
        this.setState({ isGenerating: false });
      });
  };

  render() {
    const { classes, title, company, reportConfiguration } = this.props;
    const companyId = parseInt(company.split('/').pop());
    const reportKey = reportConfiguration?.report || false;
    const useSubBrands = this.props.useSubBrands !== false;
    const fields = reportConfiguration?.fields;

    return isGranted(this.props.role) ? (
      <>
        <CardWrapper
          title={
            <>
              {title}
              {reportKey && (
                <ReportConfigurator
                  companyId={companyId}
                  report={reportKey}
                  fields={fields}
                />
              )}
            </>
          }
          style={{ marginTop: '20px' }}
        >
          <Dialog open={this.state.isGenerating}>
            <DialogContent>
              <div style={{ textAlign: 'center' }}>
                <h1>{this.props.t('reports.generate')}</h1>
                <CircularProgress />
              </div>
            </DialogContent>
          </Dialog>

          <CardBody>
            <GridContainer justify="flex-start">
              <GridItem sm={this.props.showMultiplier === true ? 2 : 4}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginBottom: '2px' }}
                >
                  {this.props.t('reports.selectBrands')}
                </FormLabel>
                <SelectAll
                  className="input-select--brand"
                  options={this.props.brands}
                  trackBy={'@id'}
                  mapBy={'name'}
                  optionSelected={this.state.selectedBrands}
                  handleChange={selected => {
                    this.setState({
                      selectedBrands: selected,
                      subBrands: [],
                      selectedSubBrands: [],
                    });
                    isGranted(ROLE_SHOW_SUB_BRAND) &&
                      this.fetchSubBrands(selected);
                  }}
                />
              </GridItem>
              {this.props.showMultiplier === true && (
                <GridItem sm={2}>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginBottom: '5px' }}
                  >
                    {this.props.t('common.multiplier')}
                  </FormLabel>
                  <FormControl fullWidth>
                    <FormTextInput
                      className="input-text--multiplier"
                      classes={classes}
                      name="multiplier"
                      value={this.state.multiplier}
                      type={'number'}
                      handleChange={this.handleChange}
                      inputSize={12}
                      onFocus={() =>
                        this.setState({ multiplierCalculatorOpen: true })
                      }
                      inputRef={node => {
                        this.anchorElement = node;
                      }}
                    />
                    <MultiplierCalculator
                      classes={classes}
                      url={'/statistics/ecommerce/bags/day/'}
                      open={this.state.multiplierCalculatorOpen}
                      anchorElement={this.anchorElement}
                      brandIds={this.state.selectedBrands}
                      handleChange={(name, value) =>
                        this.handleChange({
                          target: {
                            name: name,
                            value: value,
                          },
                        })
                      }
                    />
                  </FormControl>
                </GridItem>
              )}
              {isGranted(ROLE_SHOW_SUB_BRAND) && useSubBrands && (
                <GridItem sm={4}>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginBottom: '2px' }}
                  >
                    {this.props.t('reports.selectSubbrands')}
                  </FormLabel>
                  <SelectAll
                    className="input-select--subbrand"
                    options={this.state.subBrands}
                    trackBy={'@id'}
                    mapBy={'name'}
                    optionSelected={this.state.selectedSubBrands}
                    customLabel={subBrand => {
                      const fullBrand = this.props.brands.find(
                        brand => brand['@id'] === subBrand.brand
                      );
                      const brandName = fullBrand && fullBrand.name;
                      const subBrandName = subBrand.name;
                      const label = subBrandName;
                      const labelWithBrandName = `${label} (${brandName})`;

                      return brandName ? labelWithBrandName : label;
                    }}
                    handleChange={selected => {
                      this.setState({
                        selectedSubBrands: selected,
                      });
                    }}
                  />
                </GridItem>
              )}
              {this.props.useDateRange && (
                <>
                  <GridItem sm={useSubBrands ? 2 : 4}>
                    <FormLabel
                      className={classes.labelHorizontal}
                      style={{ marginBottom: '5px' }}
                    >
                      {this.props.t('reports.dateFrom')}
                    </FormLabel>
                    <FormControl fullWidth>
                      <Datetime
                        className="input-datetime--from"
                        isValidDate={isDatepickerValidDay}
                        timeFormat={false}
                        dateFormat={moment.localeData().longDateFormat('L')}
                        closeOnSelect={true}
                        value={new moment(this.state.dateFrom)}
                        onChange={ev =>
                          this.setState({ dateFrom: ev.format('YYYY-MM-DD') })
                        }
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem sm={useSubBrands ? 2 : 4}>
                    <FormLabel
                      className={classes.labelHorizontal}
                      style={{ marginBottom: '5px' }}
                    >
                      {this.props.t('reports.dateTo')}
                    </FormLabel>
                    <FormControl fullWidth>
                      <Datetime
                        className="input-datetime--date-to"
                        isValidDate={isDatepickerValidDay}
                        timeFormat={false}
                        dateFormat={moment.localeData().longDateFormat('L')}
                        closeOnSelect={true}
                        value={new moment(this.state.dateTo)}
                        onChange={ev =>
                          this.setState({ dateTo: ev.format('YYYY-MM-DD') })
                        }
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </GridItem>
                </>
              )}
              {!this.props.useDateRange && (
                <>
                  <GridItem sm={useSubBrands ? 4 : 8}>
                    <FormLabel
                      className={classes.labelHorizontal}
                      style={{ marginBottom: '5px' }}
                    >
                      {this.props.t('reports.selectDate')}
                    </FormLabel>
                    <FormControl fullWidth>
                      <Datetime
                        className="input-datetime--date"
                        isValidDate={isDatepickerValidDay}
                        timeFormat={false}
                        dateFormat={moment.localeData().longDateFormat('L')}
                        closeOnSelect={true}
                        value={new moment(this.state.date)}
                        onChange={ev =>
                          this.setState({ date: ev.format('YYYY-MM-DD') })
                        }
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </GridItem>
                </>
              )}
              {this.props.children}
            </GridContainer>
            <GridContainer justify="flex-end">
              <GridItem>
                <DownloadReportButtons
                  isSalesMarketLangViewEnabled={
                    this.props.isSalesMarketLangViewEnabled
                  }
                  mimeTypes={[this.props.mimeType]}
                  handleGenerate={this.handleGenerate}
                  areButtonsDisabled={this.state.isGenerating}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </CardWrapper>
        <Card>
          <CardBody>
            <Archive
              type={['REPORT']}
              reportName={this.props.archiveReportNames}
            />
          </CardBody>
        </Card>
      </>
    ) : (
      <AccessDenied />
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({
  brands: state.Auth.user.brands,
  company: state.Auth.user.company,
});

BrandDateSubbrandsReport.propTypes = {
  brands: PropTypes.array,
  classes: PropTypes.any,
  archiveReportNames: PropTypes.arrayOf(PropTypes.string),
  fileName: PropTypes.func.isRequired,
  showMultiplier: PropTypes.bool,
  validateBrands: PropTypes.bool,
  useDateRange: PropTypes.bool,
  useSubBrands: PropTypes.bool,
  mimeType: PropTypes.string.isRequired,
  openToast: PropTypes.any,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  isSalesMarketLangViewEnabled: PropTypes.bool,
};

BrandDateSubbrandsReport.defaultProps = {
  isSalesMarketLangViewEnabled: false,
};

const enhance = compose(
  connect(mapStateToProps, null),
  withToast,
  withStyles(combinedStyles),
  withTranslation()
);

export default enhance(BrandDateSubbrandsReport);
