import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles';

import EXTERNAL_URLS from 'constants/externalUrls';
import InfoWithTooltip from 'components/InfoWithTooltip/InfoWithTooltip';

const useStyles = makeStyles({
  root: { display: 'flex', alignItems: 'center' },
  title: { marginRight: '0.2em' },
});

const ListHeader = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span className={classes.title}>{t('list.recipesList')}</span>
      <InfoWithTooltip
        title={t('list.recipesListInfo')}
        link={EXTERNAL_URLS.recipes}
        size="s"
      />
    </div>
  );
};

export default ListHeader;
