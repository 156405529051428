import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'components/CustomButtons/Button.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import makeStyles from '@material-ui/styles/makeStyles';
import classNames from 'classnames';

const useStyles = makeStyles({
  margin: { margin: '5px' },
});

const FormControlButtons = ({
  classes: propClasses,
  cancelPath,
  submitText,
  discardText,
  submitColor,
  handleSubmit,
  submitDisabled,
  secondarySubmitText,
  secondarySubmitDisabled,
  handleSecondarySubmit,
  customButtonsLeft = [],
  customButtonsRight = [],
  dataCy,
  tertiarySubmitText,
  handleTertiarySubmit,
  tertiarySubmitDisabled = false,
}) => {
  const classes = useStyles();
  return (
    <GridContainer justify="flex-end">
      <GridItem data-cy={dataCy ? dataCy : '__buttons_add_and_cancel'}>
        {customButtonsLeft.map(({ label, ...props }) => (
          <Button {...props}>{label}</Button>
        ))}
        {cancelPath && (
          <Link to={cancelPath}>
            <Button
              className={classNames(propClasses.marginRight, classes.margin)}
            >
              {discardText}
            </Button>
          </Link>
        )}
        {tertiarySubmitText && (
          <Button
            color={submitColor || 'success'}
            className={classNames(propClasses.marginLeft, classes.margin)}
            onClick={handleTertiarySubmit}
            disabled={tertiarySubmitDisabled}
          >
            {tertiarySubmitText}
          </Button>
        )}

        {secondarySubmitText && (
          <Button
            color={submitColor || 'success'}
            className={classNames(propClasses.marginLeft, classes.margin)}
            onClick={handleSecondarySubmit}
            disabled={secondarySubmitDisabled ? secondarySubmitDisabled : false}
          >
            {secondarySubmitText}
          </Button>
        )}

        {submitText && (
          <Button
            color={submitColor || 'success'}
            className={propClasses.marginLeft}
            onClick={handleSubmit}
            disabled={submitDisabled ? submitDisabled : false}
          >
            {submitText}
          </Button>
        )}
        {customButtonsRight.map(({ label, ...props }) => (
          <Button {...props}>{label}</Button>
        ))}
      </GridItem>
    </GridContainer>
  );
};

export default FormControlButtons;
