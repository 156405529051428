import React from 'react';
import defaultState from './defaultState';
import { compose } from 'recompose';
import { withToast } from 'material-ui-toast-redux';
// API Connection
import { get, post, put } from 'helpers/apiHelpers';
import { fetchIngredients } from 'actions/Ingredients';
import { fetchRecipes } from 'actions/Recipes';
import { connect } from 'react-redux';
import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';
import { withTranslation } from 'react-i18next';
import {
  compareTranslatableValues,
  isTranslatableLangEmpty,
  isTranslatableValuesObjectEmpty,
} from 'hooks/redux/Translatable/useTranslatableLanguages';
import TOAST_DURATIONS from 'helpers/toastDurations';

const MealFormContainer = WrappedComponent =>
  class MealForm extends React.Component {
    state = {
      ...defaultState,
      allergens: [],
      checkedDishToResize: [],
      copyMealTypeFrom: {},
      copyMealTypeTo: [],
      urlSlug: '',
      metaTitle: '',
      metaDescription: '',
      dishDescription: '',
      friendlyName: '',
      currentBrandPageSettingsId: null,
      initialized: false,
      copyMode: false,
    };

    isEditing = this.props.match.params.id;
    editId = null;
    defaultSelectedBrands = []; // for switching between advanced and regular view

    fetchData = async dishId => {
      this.setState({
        isLoading: true,
      });

      if (dishId) {
        this.isEditing = true;
      }

      if (this.props.location.search.includes('savedMeal')) {
        const savedMeal = JSON.parse(localStorage.getItem('savedMeal'));

        this.setState({
          ...savedMeal,
          isLoading: false,
          initialized: true,
        });
        localStorage.removeItem('savedMeal');
      } else {
        const editDish = this.isEditing
          ? await get(
              `dishes/${
                dishId ? dishId : this.props.match.params.id
              }?translations=true`
            )
          : null;

        const dishBrands = this.props.brands.map(el => el['@id']);
        const currentDishBrands = editDish
          ? editDish.brands.map(brand => brand['@id'])
          : null;

        const currentDishMealTypeBrands = editDish
          ? editDish.types.map(type => ({ ...type.brand }))
          : null;

        // ⬇️ 😢
        if (this.isEditing) {
          if (editDish.currentBrandPageSettings) {
            this.setState(prevState => ({
              ...prevState,
              currentBrandPageSettingsId:
                editDish.currentBrandPageSettings['@id'],
              metaTitle: editDish.currentBrandPageSettings.seo.title,
              metaDescription:
                editDish.currentBrandPageSettings.seo.description,
              urlSlug: editDish.currentBrandPageSettings.pageSettings.slug,
              dishDescription:
                editDish.currentBrandPageSettings.pageSettings.content,
              friendlyName:
                editDish.currentBrandPageSettings.pageSettings.title,
            }));
          }

          const newSizesEditDish = editDish.sizes
            .filter(({ mealType }) =>
              editDish.types.find(type => type['@id'] === mealType)
            )
            .map(({ ingredients, mealType, ...rest }) => {
              const newIngredients = ingredients.map(item => {
                if (item.recipe) {
                  const component = editDish.components.find(
                    component =>
                      component.recipe &&
                      component.recipe['@id'] === item.recipe
                  );

                  return {
                    ...item,
                    recipe: component.recipe,
                  };
                } else {
                  const component = editDish.components.find(
                    component =>
                      component.ingredient &&
                      component.ingredient['@id'] === item.ingredient
                  );

                  return {
                    ...item,
                    ingredient: component?.ingredient,
                  };
                }
              });

              return {
                ...rest,
                ingredients: newIngredients,
                mealType: editDish.types.find(type => type['@id'] === mealType),
              };
            });

          editDish.sizes = newSizesEditDish;
        }
        // ⬆️ 😢

        const ingredients =
          editDish && editDish.sizes.length > 0
            ? editDish.sizes[0]?.ingredients
            : [];

        const selectInBrandsProperty = editDish
          ? {
              selectInBrands: currentDishBrands,
            }
          : {};

        if (this.isEditing) {
          editDish.sizes = editDish
            ? editDish.sizes.filter(dishSize => {
                return dishBrands.includes(dishSize.mealType.brand['@id']);
              })
            : [];

          editDish.types = editDish
            ? editDish.types.filter(type => {
                return dishBrands.includes(type.brand['@id']);
              })
            : [];
        }

        const promises = [
          get('cached/meal-types', {
            pagination: false,
            ...selectInBrandsProperty,
          }),
          get('serving-suggestions', { pagination: false }),
          get('stowages', { pagination: false }),
          get('tags', { pagination: false }),
          get('sizes', { pagination: false }),
          get('containers', { pagination: false }),
          get('eco-containers', { pagination: false }),
          get('home-requirements', { pagination: false }),
          get('client-tags', { pagination: false }),
        ];
        Promise.all(promises).then(
          ([
            { 'hydra:member': mealTypes },
            { 'hydra:member': servingSuggestions },
            { 'hydra:member': stowages },
            { 'hydra:member': tags },
            { 'hydra:member': mealSizes },
            { 'hydra:member': containers },
            { 'hydra:member': ecoContainers },
            { 'hydra:member': homeRequirements },
            { 'hydra:member': clientTags },
          ]) => {
            let dishToEdit = this.state.dish;
            let selectedIngredients = [];
            let allergens = [];
            const checkedDishToResize = [];

            if (this.isEditing) {
              dishToEdit = editDish;
              selectedIngredients =
                editDish && editDish.sizes.length > 0
                  ? editDish?.components?.map(el => ({
                      ingredient: el.ingredient,
                      recipe: el.recipe,
                    }))
                  : [];

              ingredients.map(item => {
                const id =
                  (item.ingredient && item.ingredient['@id']) ||
                  (item.recipe && item.recipe['@id']);
                checkedDishToResize.push(id);
                return null;
              });

              allergens = ingredients.reduce((acc, item) => {
                const allergens = item.recipe
                  ? item.recipe.allergens
                  : item.ingredient.allergens;
                const namesAllergens = allergens.map(({ value }) => value);

                return [...acc, ...namesAllergens];
              }, []);

              editDish.types = editDish.types
                .map(el => {
                  if (mealTypes.find(type => type['@id'] === el['@id'])) {
                    return mealTypes.find(type => type['@id'] === el['@id']);
                  }

                  return false;
                })
                .filter(Boolean); // mealTypes
            }

            let tmpSizes = [];

            const findSizeInDish = (mealType, size, arrayOfSizes) => {
              return arrayOfSizes.find(el => {
                return (
                  el.mealType &&
                  el.size &&
                  el.mealType['@id'] === mealType['@id'] &&
                  el.size === size['@id']
                );
              });
            };

            const findSizeInDishBoth = (
              mealType,
              size,
              selectedIngredients
            ) => {
              let sizeObj = findSizeInDish(mealType, size, tmpSizes);

              let editedSize = findSizeInDish(mealType, size, dishToEdit.sizes);
              if (!sizeObj) {
                sizeObj = { ...editedSize, ingredients: [] };

                tmpSizes.push(sizeObj);
              }

              if (!editedSize) {
                sizeObj = {
                  ingredients: [],
                  mealType,
                  size: size['@id'],
                };

                tmpSizes.push(sizeObj);
              }

              selectedIngredients.forEach(si => {
                const isRecipe = si.recipe !== null;

                const ingredients = editedSize ? editedSize.ingredients : [];

                let ingredient = ingredients.find(i => {
                  if (isRecipe) {
                    return i.recipe && i.recipe['@id'] === si.recipe['@id'];
                  } else {
                    return (
                      i.ingredient &&
                      i.ingredient['@id'] === si.ingredient['@id']
                    );
                  }
                });

                if (!ingredient) {
                  ingredient = { ...si, quantity: 0 };
                }

                sizeObj.ingredients.push(ingredient);
              });

              return sizeObj;
            };

            dishToEdit.types.forEach(mealType => {
              mealType.sizes.forEach(({ size }) => {
                findSizeInDishBoth(mealType, size, selectedIngredients);
              });
            });

            dishToEdit.sizes = tmpSizes.filter(size => size.mealType);

            dishToEdit.nameForClientInitial = dishToEdit.nameForClient;

            const newState = {
              mealTypes,
              servingSuggestions,
              stowages,
              tags,
              clientTags,
              mealSizes,
              containers,
              ecoContainers,
              homeRequirements,
              savedDishBrands: currentDishBrands,
              savedDishMealTypeBrands: currentDishMealTypeBrands,
              allergens,
              productionImage: dishToEdit.productionImage?.['@id'] || null,
              productionImageUrl:
                dishToEdit.productionImage?.contentUrl || null,
              pdfImage: dishToEdit.pdfImage?.['@id'] || null,
              pdfImageUrl: dishToEdit.pdfImage?.contentUrl || null,
              clientImage: dishToEdit.clientImage?.['@id'] || null,
              clientImageUrl: dishToEdit.clientImage?.contentUrl || null,
              dish: dishToEdit,
              disabledTypes: dishToEdit.types,
              selectedIngredients,
              isLoading: false,
              checkedDishToResize,
              forceDisableInShop: dishToEdit?.forceDisableInShop ?? false,
              initialized: true,
            };

            this.defaultSelectedBrands = dishToEdit?.brands ?? [];

            return this.setState(newState);
          }
        );
      }
    };

    componentDidMount() {
      this.fetchData();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.location.pathname !== this.props.location.pathname) {
        this.fetchData(this.editId);
      }
    }

    handleSelect = event => {
      const key = event.target.name;
      const value = event.target.value;

      if (this.state.dish[key] === value) {
        return this.setState(prevState => {
          const dishModified = {
            ...prevState.dish,
            [key]: null,
          };
          return {
            ...prevState,
            dish: dishModified,
          };
        });
      }

      this.setState(prevState => {
        const dishModified = {
          ...prevState.dish,
          [key]: value,
        };
        return {
          ...prevState,
          dish: dishModified,
        };
      });
    };

    handleChange = event => {
      const key = event.target.name;
      const value = event.target.value;

      this.setState(prevState => {
        const dishModified = {
          ...prevState.dish,
          [key]: value,
        };
        return {
          ...prevState,
          dish: dishModified,
        };
      });
    };

    handleCopySizeChange = (stateKey, value) => {
      this.setState(prevState => ({
        ...prevState,
        [stateKey]: value,
        copyMode: true,
      }));
    };

    handleCopySize = () => {
      const { copyMealTypeFrom, copyMealTypeTo, dish } = this.state;
      const copyMealTypeFromId = copyMealTypeFrom.value['@id'];
      const copyMealTypeToIds = copyMealTypeTo.map(({ value }) => value['@id']);

      const newDishState = JSON.parse(JSON.stringify(dish));
      // find type by @id
      const dishMealTypeFrom = dish.types.find(
        item => item['@id'] === copyMealTypeFromId
      );
      const dishMealTypesTo = dish.types.filter(item => {
        return copyMealTypeToIds.includes(item['@id']);
      });

      // map all mealTypes
      dishMealTypesTo.map(dishMealTypeTo => {
        // map all sizes
        dishMealTypeTo.sizes.map(({ calories, size }) => {
          // find in sizes, size equal calories
          const dishMealTypeEqualCalories = dishMealTypeFrom.sizes.find(
            item => item.calories === calories
          );

          if (dishMealTypeEqualCalories) {
            // get size by @id size key
            const sizeTo = newDishState.sizes.find(
              item =>
                item.size === size['@id'] &&
                item.mealType['@id'] === dishMealTypeTo['@id']
            );
            const sizeFrom = newDishState.sizes.find(
              item =>
                item.size === dishMealTypeEqualCalories.size['@id'] &&
                item.mealType['@id'] === dishMealTypeFrom['@id']
            );
            // map all ingredients and find in sizeFrom the same ingredient
            sizeTo.ingredients.map(sizeToIngredient => {
              const ingredientFrom = sizeFrom.ingredients.find(
                sizeFromIngredient => {
                  if (
                    sizeFromIngredient.ingredient &&
                    sizeToIngredient.ingredient
                  ) {
                    return (
                      sizeFromIngredient.ingredient['@id'] ===
                      sizeToIngredient.ingredient['@id']
                    );
                  }
                  if (sizeFromIngredient.recipe && sizeToIngredient.recipe) {
                    return (
                      sizeFromIngredient.recipe['@id'] ===
                      sizeToIngredient.recipe['@id']
                    );
                  }
                  return null;
                }
              );
              sizeToIngredient.quantity = ingredientFrom.quantity;
              return null;
            });
            sizeTo.container = sizeFrom.container;
            sizeTo.ecoContainer = sizeFrom.ecoContainer;
            sizeTo.name = sizeFrom.name;
          }
          return null;
        });

        this.setState(prevState => ({
          ...prevState,
          dish: { ...newDishState },
        }));
        return null;
      });
    };

    handleTagChange = e => {
      this.setState(prevState => {
        return {
          dish: {
            ...prevState.dish,
            tags: e,
          },
        };
      });
    };

    getImage = (stateName, data) => {
      this.setState({
        [stateName]: data?.['@id'] || null,
        [`${stateName}Url`]: data?.contentUrl || null,
      });
    };

    removeImage = stateName => {
      this.setState({
        [stateName]: null,
        [`${stateName}Url`]: null,
      });
    };

    handleBrand = (event, items) => {
      this.setState(prevState => {
        const types = [
          ...prevState.dish.types.filter(type =>
            items.find(item => item['@id'] === type.brand['@id'])
          ),
        ];
        const sizes = [
          ...prevState.dish.sizes.filter(size =>
            types.find(type => type['@id'] === size.mealType['@id'])
          ),
        ];

        return {
          ...prevState,
          dish: {
            ...prevState.dish,
            brands: items,
            types: types,
            sizes: sizes,
          },
        };
      });

      this.fetchMealTypes(items);
    };

    fetchMealTypes = async selected => {
      const selectInBrands = selected.length
        ? selected.map(brand => brand['@id'])
        : [];

      const response = await get('/meal-types', {
        selectInBrands,
        pagination: false,
      });

      this.setState(prevState => ({
        ...prevState,
        mealTypes: response['hydra:member'],
      }));
    };

    handleMealType = async obj => {
      const sizes = [];
      obj.forEach(mt => {
        const mealTypeSizes = mt.sizes.map(el => ({
          ...el,
          mealType: mt,
        }));
        sizes.push(...mealTypeSizes);
      });

      let dish = this.buildCorrectStructure(
        { ...this.state.dish, types: obj },
        sizes,
        this.state.selectedIngredients
      );

      this.setState(prev => ({
        ...prev,
        dish: dish,
      }));
    };

    buildCorrectStructure = (dish, sizes, dishIngredients) => {
      sizes.forEach(size => {
        let dishSize = dish.sizes.find(
          dishSize =>
            dishSize.size === size.size['@id'] &&
            dishSize.mealType['@id'] === size.mealType['@id']
        );

        if (!dishSize) {
          dishSize = {
            name: null,
            size: size.size['@id'],
            container: null,
            ecoContainer: null,
            mealType: size.mealType,
            ingredients: [],
          };

          dish.sizes.push(dishSize);
        }

        dishIngredients.forEach(dishIngredient => {
          const exist =
            typeof dishSize.ingredients.find(ingredient => {
              const dishIngredientIsRecipe = dishIngredient.recipe !== null;
              const ingredientIsRecipe = ingredient.recipe !== null;

              if (dishIngredientIsRecipe !== ingredientIsRecipe) {
                return false;
              }

              if (dishIngredientIsRecipe) {
                return (
                  dishIngredient.recipe['@id'] === ingredient.recipe['@id']
                );
              }

              if (!dishIngredientIsRecipe) {
                return (
                  dishIngredient.ingredient['@id'] ===
                  ingredient.ingredient['@id']
                );
              }

              return false;
            }) !== 'undefined';

          if (!exist) {
            dishSize.ingredients.push({
              ...dishIngredient,
              quantity: 0,
            });
          }
        });
      });

      const tmpTable = dishIngredients.map(el => {
        return el.recipe === null ? el.ingredient['@id'] : el.recipe['@id'];
      });
      const tmpTypeIds = dish.types.map(el => el['@id']);

      dish.sizes = dish.sizes
        .filter(dishSize => {
          return tmpTypeIds.includes(dishSize.mealType['@id']);
        })
        .map(size => {
          size.ingredients = size.ingredients.filter(el => {
            const id =
              el.recipe === null ? el.ingredient['@id'] : el.recipe['@id'];

            return tmpTable.includes(id);
          });
          return size;
        });

      return dish;
    };
    //handle MealTypes end

    //handleQuantityChange
    handleQuantityChange = (val, dishSize, rowIngredientId) => {
      if (isNaN(val)) {
        return;
      }
      let dish = this.state.dish;

      let ingredient = dish.sizes
        .find(s => {
          return (
            s.mealType['@id'] === dishSize.mealType['@id'] &&
            s.size === dishSize.size
          );
        })
        .ingredients.find(dishIngredient => {
          const key = dishIngredient.recipe === null ? 'ingredient' : 'recipe';

          return dishIngredient[key]['@id'] === rowIngredientId;
        });

      ingredient.quantity = val;

      this.setState(prev => {
        return { ...prev, dish };
      });
    };

    scaleDishSize = (dishSizes, mealTypeSizes) => {
      const { dish, checkedDishToResize } = this.state;
      // deep cloning
      const newDishState = JSON.parse(JSON.stringify(dish)); // {}
      const newDishSizes = JSON.parse(JSON.stringify(dishSizes)); // []
      const dishSizeObj = mealTypeSizes[0]; // default first column
      const mealTypeSizeId = dishSizeObj.size['@id'];
      const scalingSize = newDishSizes.find(
        ({ size: sizeId }) => sizeId === mealTypeSizeId
      );
      const { ingredients: scalingSizeIngredients } = scalingSize;

      newDishSizes
        .filter(({ size: sizeId }) => sizeId !== mealTypeSizeId)
        .map(({ size: dishSizeId, mealType: { '@id': mealTypeId } }, index) => {
          const currentSizeCalories = mealTypeSizes.find(
            ({ size }) => size['@id'] === dishSizeId
          ).calories; // e.g. XS calories => 200

          const currentStateDishSize = newDishState.sizes.find(
            ({ mealType, size }) =>
              size === dishSizeId && mealType['@id'] === mealTypeId
          );

          const dishQuantityMultiplier = parseFloat(
            (currentSizeCalories / dishSizeObj.calories).toFixed(2)
          );

          currentStateDishSize.ingredients.map(ingredient => {
            const scalingDishQuantity = scalingSizeIngredients.find(item => {
              if (item.ingredient) {
                return (
                  item.ingredient['@id'] ===
                  (ingredient.ingredient && ingredient.ingredient['@id'])
                );
              } else {
                return (
                  item.recipe['@id'] ===
                  (ingredient.recipe && ingredient.recipe['@id'])
                );
              }
            }).quantity;

            return (ingredient.quantity = checkedDishToResize.includes(
              (ingredient.ingredient && ingredient.ingredient['@id']) ||
                (ingredient.recipe && ingredient.recipe['@id'])
            )
              ? scalingDishQuantity * dishQuantityMultiplier
              : scalingDishQuantity);
          });
          return null;
        });

      this.setState(prevState => ({
        ...prevState,
        shouldRescale: true,
        dish: { ...newDishState },
      }));
    };

    setShouldRescale = () => {
      this.setState({
        shouldRescale: !this.state.shouldRescale,
      });
    };

    setDishToResize = dishId => {
      this.setState(prevState => {
        const checkedDishToResize = prevState.checkedDishToResize.includes(
          dishId
        )
          ? prevState.checkedDishToResize.filter(
              currentDishId => currentDishId !== dishId
            )
          : [...prevState.checkedDishToResize, dishId];

        return {
          checkedDishToResize,
        };
      });
    };

    handleSizeNameChange = (val, dishSize) => {
      let dish = this.state.dish;
      let size = dish.sizes.find(s => {
        return (
          s.mealType['@id'] === dishSize.mealType['@id'] &&
          s.size === dishSize.size
        );
      });
      size.name = val;
      this.setState(prev => {
        return { ...prev, dish };
      });
    };

    handleSizeShopAvailabilityChange = (val, size) => {
      let dish = this.state.dish;
      let dishSize = dish.sizes.find(
        dishSize =>
          dishSize.mealType['@id'] === size.mealType['@id'] &&
          dishSize.size === size.size
      );

      dishSize.allowedInShop = val;

      this.setState(prevState => {
        return { ...prevState, dish };
      });
    };

    handleSizeShopAvailabilityChangeRow = dishSizes => {
      const { dish } = this.state;
      const ishChecked = dishSizes.every(({ allowedInShop }) => allowedInShop);
      const checkDishSizezIds = dishSizes.map(({ id }) => id);

      const newStateSizes = dish.sizes.map(size => {
        const allowedInShop = checkDishSizezIds.includes(size.id)
          ? !ishChecked
          : size.allowedInShop;

        return { ...size, allowedInShop };
      });

      this.setState(prevState => {
        return {
          ...prevState,
          dish: {
            ...prevState.dish,
            sizes: newStateSizes,
          },
        };
      });
    };

    handleChangeForceDisableInShop = forceDisableInShop => {
      this.setState({ forceDisableInShop });
    };

    handleContainerChange = (ev, obj, size) => {
      const value = ev.target.value;

      let dish = this.state.dish;
      let dishSize = dish.sizes.find(
        dishSize =>
          dishSize.mealType['@id'] === size.mealType['@id'] &&
          dishSize.size === size.size
      );

      dishSize.container = value;

      this.setState(prevState => {
        return { ...prevState, dish };
      });
    };

    handlePriceChange = (ev, size) => {
      let dish = this.state.dish;
      let dishSize = dish.sizes.find(
        dishSize =>
          dishSize.mealType['@id'] === size.mealType['@id'] &&
          dishSize.size === size.size
      );

      const divideCharacter = ev.target.value.includes(',') ? ',' : '.';

      const [integer, fraction] = ev.target.value.split(divideCharacter);

      if (fraction?.length > 2) {
        dishSize.costInShop = `${integer}${divideCharacter}${fraction.slice(
          0,
          2
        )}`;
      } else {
        dishSize.costInShop = ev.target.value;
      }

      this.setState(prevState => {
        return { ...prevState, dish };
      });
    };

    handleEcoContainerChange = (ev, obj, size) => {
      const value = ev.target.value;

      let dish = this.state.dish;
      let dishSize = dish.sizes.find(
        dishSize =>
          dishSize.mealType['@id'] === size.mealType['@id'] &&
          dishSize.size === size.size
      );

      dishSize.ecoContainer = value;

      this.setState(prevState => {
        return { ...prevState, dish };
      });
    };

    //New recipe or ingredient func
    addMeal = itemToAdd => {
      const alreadyAdded =
        typeof this.state.selectedIngredients.find(selIngredient => {
          let key;
          if (selIngredient.recipe === null) {
            key = 'ingredient';
          } else {
            key = 'recipe';
          }
          return selIngredient[key]['@id'] === itemToAdd['@id'];
        }) !== 'undefined';

      if (alreadyAdded) {
        return this.props.openToast({
          messages: [
            this.props.t(
              'errors.ingredientAlreadyAdded',
              'Ten składnik dania został już dodany'
            ),
          ],
          type: 'error',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      }

      let dishIngredient;

      if (itemToAdd['@type'] === 'Ingredient') {
        dishIngredient = {
          ingredient: itemToAdd,
          recipe: null,
        };
      } else {
        dishIngredient = {
          ingredient: null,
          recipe: itemToAdd,
        };
      }

      const sizes = [];
      const selectedIngredients = [
        ...this.state.selectedIngredients,
        dishIngredient,
      ];

      this.state.dish.types.forEach(mt => {
        const mealTypeSizes = mt.sizes.map(el => ({ ...el, mealType: mt }));
        sizes.push(...mealTypeSizes);
      });

      let dish = this.buildCorrectStructure(
        this.state.dish,
        sizes,
        selectedIngredients
      );

      const { allergens } = itemToAdd;

      this.setState(prevState => ({
        ...prevState,
        selectedIngredients: selectedIngredients,
        dish: dish,
        allergens: [...this.state.allergens, ...allergens.map(el => el.value)],
        checkedDishToResize: [
          ...prevState.checkedDishToResize,
          itemToAdd['@id'],
        ],
      }));
    };

    handleRemove = dishIngredient => {
      const { ingredient, recipe } = dishIngredient;
      const selectedIngredients = [...this.state.selectedIngredients];
      const newSelectedIngredients = selectedIngredients.filter(ingredient => {
        const dishIngredientIsRecipe = dishIngredient.recipe !== null;
        const ingredientIsRecipe = ingredient.recipe !== null;

        if (dishIngredientIsRecipe !== ingredientIsRecipe) {
          return true;
        }

        if (dishIngredientIsRecipe) {
          return dishIngredient.recipe['@id'] !== ingredient.recipe['@id'];
        }

        if (!dishIngredientIsRecipe) {
          return (
            dishIngredient.ingredient['@id'] !== ingredient.ingredient['@id']
          );
        }
        return null;
      });

      // 😢
      const allergensToRemove = recipe
        ? recipe.allergens
          ? recipe.allergens
          : recipe.ingredients.reduce(
              (acc, { ingredient: { allergens } }) => [...acc, ...allergens],
              []
            )
        : ingredient.allergens;
      const allergensStrings = allergensToRemove.map(({ value }) => value);
      const newAllergens = [
        ...this.state.allergens.filter(
          allergen => !allergensStrings.includes(allergen)
        ),
      ];
      const sizes = [];

      this.state.dish.types.forEach(mt => {
        const mealTypeSizes = mt.sizes.map(el => ({ ...el, mealType: mt }));
        sizes.push(...mealTypeSizes);
      });

      let dish = this.buildCorrectStructure(
        this.state.dish,
        sizes,
        newSelectedIngredients
      );

      this.setState(prevState => {
        const checkedDishToResize = prevState.checkedDishToResize.filter(
          id =>
            id !== (ingredient && ingredient['@id']) ||
            (recipe && recipe['@id'])
        );

        return {
          ...prevState,
          selectedIngredients: newSelectedIngredients,
          dish: dish,
          allergens: newAllergens,
          checkedDishToResize,
        };
      });
    };

    getFilteredIngredients = async e => {
      return this.props.fetchIngredients({
        pageSize: 30,
        page: 0,
        sorted: [],
        filtered: [
          {
            id: '_orX',
            value: [[{ id: e, name: e, workName: e }]],
          },
        ],
      });
    };

    getFilteredRecipes = async e => {
      return this.props.fetchRecipes({
        pageSize: 30,
        page: 0,
        sorted: [],
        filtered: [
          {
            id: '_orX',
            value: [[{ id: e, name: e, workName: e }]],
          },
        ],
      });
    };

    validateObligatoryFields = data => {
      return (
        data.nameForClient !== '' &&
        //data.servingSuggestion !== null &&
        data.types.length > 0
      );
    };

    validateIngredientsLength = () => {
      return this.state.selectedIngredients.length > 0;
    };

    validateIngredientsQuantities = data => {
      let isValid = true;
      data.sizes.forEach(size => {
        size.ingredients.forEach(ingredient => {
          if (ingredient.quantity === '' || isNaN(ingredient.quantity)) {
            isValid = false;
          }
        });
      });
      return isValid;
    };

    validateAdvancedMealFormIngredients = () => {
      if (this.state.mealTypeIngredientsSum !== null) return true; // If ingredients was validated once, then proceed save

      const mealTypeIngredientsSum = this.state.dish?.sizes.reduce(
        (acc, curr) => {
          return {
            ...acc,
            [curr.mealType['@id']]: {
              ...(acc[curr.mealType['@id']] ? acc[curr.mealType['@id']] : {}),
              ...curr.ingredients.reduce(
                (acc, curr) => {
                  return {
                    ...acc,
                    [curr.ingredient?.id ?? curr.recipe?.id]:
                      parseFloat(curr.quantity) +
                      (acc[curr.ingredient?.id ?? curr.recipe?.id] ?? 0),
                  };
                },
                acc[curr.mealType['@id']] ? acc[curr.mealType['@id']] : {}
              ),
            },
          };
        },
        {}
      );

      const isAnyIgredientEmpty = Object.values(mealTypeIngredientsSum).some(
        mealTypeIngredient =>
          Object.values(mealTypeIngredient).some(ingredient => ingredient === 0)
      );

      this.setState({
        mealTypeIngredientsSum,
      });

      return !isAnyIgredientEmpty;
    };

    showValidationAlert = message => {
      return this.props.openToast({
        messages: [message],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    };

    saveToLs = () => {
      const mealToSave = JSON.parse(JSON.stringify(this.state));

      mealToSave.dish['@id'] = null;
      mealToSave.dish.sizes.forEach(size => {
        size['@id'] = null;
        size.ingredients.forEach(ingredient => (ingredient['@id'] = null));
      });

      localStorage.setItem('savedMeal', JSON.stringify(mealToSave));
    };

    setClientsSteps = steps => {
      this.setState({ clientSteps: Object.values(steps) });
    };

    handleCmsInputChange = event => {
      this.setState(prevState => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
    };

    handleSubmit = (e, withClose) => {
      let data = {
        ...this.state.dish,
        clientTags: (this.state?.dish?.clientTags ?? []).map(
          clientTag => clientTag?.['@id'] ?? clientTag
        ),
        nameForClient: this.state.dish.nameForClient,
        brands: this.state.dish.brands.map(el => el['@id']),
        productionImage: this.state.productionImage,
        pdfImage: this.state.pdfImage,
        preparingEstimatedMinutes: +this.state.dish.preparingEstimatedMinutes,
        clientSteps: this.state.clientSteps,
        clientImage: this.state.clientImage,
        forceDisableInShop: this.state.forceDisableInShop,
        currentBrandPageSettings: {
          seo: {
            title: this.state.metaTitle,
            description: this.state.metaDescription,
          },
          pageSettings: {
            slug: this.state.urlSlug,
            content: this.state.dishDescription,
            title: this.state.friendlyName,
          },
        },
      };

      if (this.state.currentBrandPageSettingsId) {
        data.currentBrandPageSettings['@id'] =
          this.state.currentBrandPageSettingsId;
      }

      data.types = data.types.map(type => type['@id']);
      data.sizes = data.sizes.map(size => {
        const costInShop = (() => {
          switch (typeof size.costInShop) {
            case 'number':
              return size.costInShop;
            case 'string':
              return parseFloat(size.costInShop?.replace(',', '.'));
            default:
              return null;
          }
        })();
        return {
          ...size,
          name:
            isTranslatableValuesObjectEmpty(size.name) ||
            isTranslatableLangEmpty(size.name, this.props.translatableLang)
              ? null
              : compareTranslatableValues(
                  size.name,
                  data.nameForClientInitial
                ) &&
                !compareTranslatableValues(
                  data.nameForClient,
                  data.nameForClientInitial
                )
              ? data.nameForClient
              : size.name,
          mealType: size.mealType['@id'],
          costInShop,
          ingredients: size.ingredients.map(ingredient => {
            return {
              ...ingredient,
              ingredient:
                ingredient.ingredient === null
                  ? null
                  : ingredient.ingredient['@id'],
              recipe:
                ingredient.recipe === null ? null : ingredient.recipe['@id'],
              quantity: parseFloat(ingredient.quantity),
            };
          }),
        };
      });

      if (!this.validateObligatoryFields(data)) {
        return this.showValidationAlert(
          this.props.t(
            'rolesForm.mandatoryFields',
            'Wypełnij wszystkie obowiązkowe pola'
          )
        );
      }

      if (!this.validateIngredientsLength()) {
        return this.showValidationAlert(
          'Danie musi zawierać przynajmniej 1 składnik'
        );
      }

      if (!this.validateAdvancedMealFormIngredients()) {
        return this.showValidationAlert(
          this.props.t(
            'rolesForm.requiredIngredients',
            'Danie zawiera jeden lub więcej nieuzupełnionych składników. Proszę o ponowną próbę zapisu, jeżeli tak ma pozostać.'
          )
        );
      }

      if (!this.validateIngredientsQuantities(data)) {
        return this.showValidationAlert(
          'Wpisz ilości dla wszystkich składników'
        );
      }

      this.setState({ isSubmitting: true });

      if (this.isEditing) {
        put(`/dishes/${this.props.match.params.id}`, data)
          .then(response => {
            this.setState({
              isSubmitting: false,
              disabledTypes: this.state.dish.types,
            });

            !withClose && window.location.reload();
            withClose && this.props.history.push('/admin/meals');
          })
          .catch(error => {
            this.setState({ isSubmitting: false });
          });
      } else {
        post('/dishes', data)
          .then(response => {
            this.setState(defaultState);
            if (!withClose) {
              this.editId = response.id;
              this.props.history.push(`/admin/meals/edit/${response.id}`);
            }
            withClose && this.props.history.push('/admin/meals');
          })
          .catch(error => {
            this.setState({ isSubmitting: false });
          });
      }
    };

    getStarRate = rateAVG => {
      let stars = [];
      let fullStars = (rateAVG || 0).toFixed(0);
      let borderedStars = 5 - (rateAVG || 0).toFixed(0);

      while (fullStars-- > 0) {
        stars.push(<Star style={{ color: '#DECF00' }} />);
      }

      while (borderedStars-- > 0) {
        stars.push(<StarBorder style={{ color: '#DECF00' }} />);
      }

      return (
        <div style={{ marginLeft: '-5px' }}>
          {stars.map((el, key) => (
            <span key={key}>{el}</span>
          ))}
        </div>
      );
    };

    changeCreationFlow = isAdvancedCreation => {
      const brands = isAdvancedCreation
        ? this.props.brands
        : this.defaultSelectedBrands;

      if (!this.isEditing) {
        const type = isAdvancedCreation ? [this.state.mealTypes[0]] : [];
        this.handleMealType(type);
      }

      if (isAdvancedCreation) {
        this.defaultSelectedBrands = this.state.dish.brands;
      }

      this.setState(prevState => ({
        dish: { ...prevState.dish, brands },
        isAdvancedCreation,
      }));
    };

    render() {
      return (
        <WrappedComponent
          {...this}
          {...this.props}
          {...this.state}
          setSubmitting={isSubmitting => this.setState({ isSubmitting })}
        />
      );
    }
  };

const mapStateToProps = state => ({
  ingredients: state.Ingredients.ingredients,
  recipes: state.Recipes.recipes,
  selectedBrand: state.Auth.selectedBrand,
  brands: state.Auth.user.brands,
  translatableLang: state.Translatable.selectedLanguage || 'pl',
});

const mapDispatchToProps = {
  fetchIngredients,
  fetchRecipes,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
  withToast,
  MealFormContainer
);
