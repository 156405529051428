import React from 'react';
import roles, { ROLE_SHOW_REPORT_PURCHASED_ADDONS } from 'helpers/roles';
import moment from 'moment';
import { compose } from 'redux';
import { withToast } from 'material-ui-toast-redux';
import Datetime from 'react-datetime';
import { connect } from 'react-redux';
import axios from 'helpers/gastro';
import { Tooltip } from '@material-ui/core';
//styles
import { combineStyles, isGranted } from 'helpers/helpers';
import { isDatepickerValidDay } from 'helpers/dateHelpers';
//components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import SelectAll from 'components/SelectAll';

import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import { Dialog, DialogContent } from '@material-ui/core';
import AccessDenied from '../../components/Security/AccessDenied';
import { withTranslation } from 'react-i18next';
import Archive from '../../components/Archive/Archive';
import { fetchZoneCategories } from '../../actions/ZoneCategories';
import getReportFileName from 'helpers/getReportFileName';
import { Info } from '@material-ui/icons';
import CardWrapper from 'components/Card/CardWrapper';
import DownloadReportButtons from './DownloadReportButtons/DownloadReportButtons';
import MIME_TYPES from './constants/mimeTypes';
import TOAST_DURATIONS from 'helpers/toastDurations';

// translate
class AddonsReport extends React.Component {
  state = {
    selectedBrands: [],
    dateFrom: '',
    dateTo: '',
    isGenerating: false,
    selectedZoneCategories: [],
  };

  url = 'reports/purchased-addons';
  getFileName = getReportFileName(this.props.t('views.addons.addons.list'));

  componentDidMount = async () => {
    if (isGranted(roles.ROLE_SHOW_ZONE_CATEGORY)) {
      await this.props.fetchZoneCategories();
    }
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  validateDate = () => {
    return this.state.dateFrom && this.state.dateTo;
  };

  validateBrands = () => {
    return this.state.selectedBrands.length !== 0;
  };

  handleGenerate = (mimeType, lang) => {
    const { t } = this.props;
    this.setState({ isGenerating: true });
    if (!this.validateDate()) {
      this.setState({ isGenerating: false });
      return this.props.openToast({
        messages: [
          t(
            'errors.raportRequiresDateRange',
            'Wybierz daty, dla których chcesz wygenerować raport'
          ),
        ],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }
    if (!this.validateBrands()) {
      this.setState({ isGenerating: false });
      return this.props.openToast({
        messages: [
          t('errors.raportRequireBrand', 'Wybierz przynajmniej jedną markę'),
        ],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }

    const params = {
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
      brands: this.state.selectedBrands.map(brand => brand.value),
      zoneCategories: this.state.selectedZoneCategories.map(
        zoneCategory => zoneCategory.value
      ),
    };

    axios
      .get(this.url, {
        responseType: 'blob',
        params: params,
        headers: { accept: mimeType },
        forcedLanguage: lang,
      })
      .then(
        response => {
          if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `${this.getFileName(this.state)}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
            this.setState({ isGenerating: false });
          } else {
            alert(
              t(
                'reports.noAccess',
                'Nie posiadasz uprawnień do wygenerowania tego raportu. Skontaktuj się z administratorem.'
              )
            );
            this.setState({ isGenerating: false });
          }
        },
        error => {
          this.props.openToast({
            messages: [
              t('reports.cannotGenerate', 'Nie udało się wygenerować raportu'),
              error.response.data['hydra:description'],
            ],
            type: 'error',
            autoHideDuration: TOAST_DURATIONS.SM,
          });
          this.setState({ isGenerating: false });
        }
      )
      .finally(() => {
        this.setState({ isGenerating: false });
      });
  };

  // translate
  render() {
    const { classes, t } = this.props;

    return isGranted(ROLE_SHOW_REPORT_PURCHASED_ADDONS) ? (
      <>
        <CardWrapper
          title={t(
            'reports.reportPurchasedAddons',
            'Wygeneruj raport - Lista sprzedanych dodatków'
          )}
        >
          <Dialog open={this.state.isGenerating}>
            <DialogContent>
              <div style={{ textAlign: 'center' }}>
                <h1>{t('reports.generate', 'Trwa generowanie raportu')}</h1>
                <CircularProgress />
              </div>
            </DialogContent>
          </Dialog>

          <CardBody>
            <GridContainer>
              <GridItem sm={4}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginBottom: '2px' }}
                >
                  {t('reports.selectBrands', 'Wybierz marki')} *
                </FormLabel>
                <SelectAll
                  options={this.props.brands}
                  trackBy={['@id']}
                  mapBy={'name'}
                  optionSelected={this.state.selectedBrands}
                  handleChange={selected => {
                    this.setState({
                      selectedBrands: selected,
                    });
                  }}
                />
              </GridItem>
              <GridItem sm={4}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginBottom: '5px' }}
                >
                  {t('reports.selectDate1', 'Wybierz datę początkową')} *
                </FormLabel>
                <FormControl fullWidth>
                  <Datetime
                    isValidDate={isDatepickerValidDay}
                    timeFormat={false}
                    dateFormat={moment.localeData().longDateFormat('L')}
                    closeOnSelect={true}
                    value={new moment(this.state.dateFrom)}
                    onChange={ev =>
                      this.setState({ dateFrom: ev.format('YYYY-MM-DD') })
                    }
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem sm={4}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginBottom: '5px' }}
                >
                  {t('reports.selectDate2', 'Wybierz datę końcową')}*
                </FormLabel>
                <FormControl fullWidth>
                  <Datetime
                    isValidDate={isDatepickerValidDay}
                    timeFormat={false}
                    dateFormat={moment.localeData().longDateFormat('L')}
                    closeOnSelect={true}
                    value={new moment(this.state.dateTo)}
                    onChange={ev =>
                      this.setState({ dateTo: ev.format('YYYY-MM-DD') })
                    }
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem sm={4}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginBottom: '5px' }}
                >
                  {this.props.t('reports.selectZoneCategories')}
                  <Tooltip
                    title={
                      <h4>
                        {t(
                          'reports.selectZoneCategories.tooltip',
                          '$$Pozostaw puste by wygenerować raport uwzględniający punkty odbioru'
                        )}
                      </h4>
                    }
                    placement="top"
                  >
                    <Info
                      fontSize={'small'}
                      style={{ marginLeft: '5px', color: 'gray' }}
                    />
                  </Tooltip>
                </FormLabel>
                <SelectAll
                  options={this.props.zoneCategories}
                  trackBy={'@id'}
                  mapBy={'name'}
                  optionSelected={this.state.selectedZoneCategories}
                  handleChange={selected => {
                    this.setState({
                      selectedZoneCategories: selected,
                    });
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="flex-end">
              <GridItem>
                <DownloadReportButtons
                  isSalesMarketLangViewEnabled={true}
                  mimeTypes={[MIME_TYPES.xlsx]}
                  handleGenerate={this.handleGenerate}
                  areButtonsDisabled={this.state.isGenerating}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </CardWrapper>
        <Card>
          <CardBody>
            <Archive type={['REPORT']} reportName={['PURCHASED_ADDONS']} />
          </CardBody>
        </Card>
      </>
    ) : (
      <AccessDenied />
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({
  brands: state.Auth.user.brands,
  zoneCategories: state.ZoneCategories.zoneCategories,
});

const mapDispatchToProps = dispatch => ({
  fetchZoneCategories: () => dispatch(fetchZoneCategories()),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(combinedStyles),
  withToast,
  withTranslation()
);

export default enhance(AddonsReport);
