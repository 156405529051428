import { Component } from 'react';
import defaultState from './defaultState';
import { withRouter } from 'react-router-dom';
import { withToast } from 'material-ui-toast-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { errorNotificationMapObject, postCodeValidator } from 'helpers/helpers';
import { fetchUser, clearUser } from 'actions/Users';
import { post, put, get } from 'helpers/apiHelpers';

import FormTextInput from 'components/FormTextInput/FormTextInput';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { Divider } from '@material-ui/core';
import SelectInput from 'components/FormSelect/SelectInput';
import _set from 'lodash/set';
import { ReactPhoneNumberAdapter } from 'components/ReactPhoneNumberAdapter/ReactPhoneNumberAdapter';
import {
  fetchSexes,
  fetchHowKnow,
  fetchSmsTagsForClients,
} from 'actions/Dictionary';
import RecordFieldHistory from '../../../../components/History/RecordFieldHistory';
import LogView from '../../../../components/History/LogView';
import Datetime from 'react-datetime';
import moment from 'moment';
import FormControlStickyButton from 'components/FormControlStickyButton/FormControlStickyButton';
import TOAST_DURATIONS from 'helpers/toastDurations';
class GeneralInfo extends Component {
  state = {
    ...defaultState,
    passwordError: '',
    loading: false,
    fetching: true,
    birthday: null,
  };

  userId = this.props.match.params.id;
  isEdit = this.props.location.pathname.includes('edit');

  async componentDidMount() {
    const selectedBrand = this.props.brands.find(
      brand => brand.id === this.props.brand
    );
    get(selectedBrand['@id']).then(res => {
      this.setState(prev => ({
        ...prev,
        marketingTermsContent: res.marketingTermsContent,
      }));
    });

    await Promise.all([
      this.props.fetchSexes(),
      this.props.fetchHowKnow(),
      this.props.fetchSmsTagsForClients(),
    ]);

    const { dictionary } = this.props;

    let user = {
      ...defaultState,
      sexOptions: dictionary.sexes,
      knowAboutUsOptions: dictionary.howKnow,
      marketingTerms: true,
    };
    if (this.userId) {
      const givenUser = await this.props.fetchUser(this.userId);
      let knowAboutUs;
      let knowAboutUsOther;

      if (
        dictionary.howKnow.some(
          option => option.value === givenUser.knowAboutUs
        )
      ) {
        knowAboutUs = givenUser.knowAboutUs;
      } else {
        knowAboutUs = this.props.t('clients.fromYouKnow.other.label');
        knowAboutUsOther = givenUser.knowAboutUs;
      }

      user = {
        firstName: givenUser.firstName,
        lastName: givenUser.lastName,
        phone: givenUser.phone,
        email: givenUser.email,
        gender: givenUser.gender?.['@id'], //iri
        sexOptions: dictionary.sexes,
        hasPhoneNumber: !!givenUser?.phone?.number,
        invoiceCompany: givenUser.invoiceCompany,
        invoiceVatNumber: givenUser.invoiceVatNumber,
        invoiceAddressCity: givenUser.invoiceAddressCity,
        invoiceAddressBuildNumber: givenUser.invoiceAddressBuildNumber,
        invoiceAddressPlaceNumber: givenUser.invoiceAddressPlaceNumber,
        invoiceAddressPostCode: givenUser.invoiceAddressPostCode,
        invoiceAddressStreet: givenUser.invoiceAddressStreet,
        invoiceComments: givenUser.invoiceComments,
        referenceCode: givenUser.referenceCode,
        knowAboutUs: knowAboutUs,
        knowAboutUsOptions: dictionary.howKnow,
        knowAboutUsOther: knowAboutUsOther,
        marketingTerms: givenUser.marketingTerms ? true : false,
        messagesTerms: givenUser.messagesTerms ? true : false,
        smsClientTags: givenUser.smsClientTags,
        birthday: givenUser?.birthday ?? null,
      };
      this.props.getUserFullName(
        `${givenUser.firstName} ${givenUser.lastName}`
      );
    }

    this.setState({
      ...user,
      fetching: false,
    });
  }
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleInputChange = event => {
    this.setState(state => _set(state, event.target.name, event.target.value));
  };
  handlePhoneChange = (number, countryCode) => {
    this.setState(prevState => ({
      ...prevState,
      phone: {
        ...prevState.phone,
        number,
        countryCode,
      },
    }));
  };

  validatePassword = () => {
    return this.state.plainPasswordMatch === this.state.plainPassword;
  };

  validateEmail = () => {
    return this.state.email.includes('@');
  };

  validateForm = () => {
    if (!this.validateEmail()) {
      this.props.openToast({
        messages: [
          this.props.t(
            'brands.newBrandForm.helpText.incorrect',
            'Nieprawidłowy adres email'
          ),
        ],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
      return false;
    }

    if (!this.validatePassword()) {
      this.props.openToast({
        messages: [
          this.props.t('errors.passwordsDontMatch', 'Hasła nie są takie same'),
        ],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
      return false;
    }

    if (this.isEdit) {
      if (!this.state.firstName || !this.state.lastName || !this.state.email) {
        this.props.openToast({
          messages: [
            this.props.t(
              'errors.fillAllRequiredFields',
              'Wypelnij wszystkie obowiązkowe pola oznaczone gwiazdką'
            ),
          ],
          type: 'error',
          autoHideDuration: TOAST_DURATIONS.SM,
        });

        return false;
      }
    } else if (
      !this.state.firstName ||
      !this.state.lastName ||
      !this.state.email ||
      !this.state.plainPassword ||
      !this.validatePassword()
    ) {
      this.props.openToast({
        messages: [
          this.props.t(
            'errors.fillAllRequiredFields',
            'Wypelnij wszystkie obowiązkowe pola oznaczone gwiazdką'
          ),
        ],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
      return false;
    }

    return true;
  };

  handleSubmit = () => {
    if (!this.validateForm()) {
      return;
    }

    this.setState({
      loading: true,
    });

    const data = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone:
        !this.state.hasPhoneNumber && !this.state.phone?.number
          ? null
          : this.state.phone,
      email: this.state.email.toLowerCase(),
      plainPassword:
        this.state.plainPassword !== '' ? this.state.plainPassword : null,
      gender: this.state.gender || null,
      invoiceVatNumber: this.state.invoiceVatNumber,
      invoiceCompany: this.state.invoiceCompany,
      invoiceAddressCity: this.state.invoiceAddressCity,
      invoiceAddressBuildNumber: this.state.invoiceAddressBuildNumber,
      invoiceAddressPlaceNumber: this.state.invoiceAddressPlaceNumber,
      invoiceAddressPostCode: this.state.invoiceAddressPostCode,
      invoiceAddressStreet: this.state.invoiceAddressStreet,
      invoiceComments: this.state.invoiceComments,
      knowAboutUs:
        this.state.knowAboutUs ===
        this.props.t('clients.fromYouKnow.other.label')
          ? this.state.knowAboutUsOther
          : this.state.knowAboutUs,
      marketingTerms: this.state.marketingTerms,
      messagesTerms: this.state.messagesTerms,
      smsClientTags: this.state.smsClientTags.map(el => el?.['@id'] ?? el),
      birthday: this.state.birthday,
    };

    const action = this.isEdit
      ? put(`/clients/${this.userId}`, data)
      : post('/clients', data);

    action.then(
      newUser => {
        this.setState({
          loading: false,
        });

        if (this.isEdit) {
          return this.props.history.push('/admin/clients');
        } else {
          this.props.openToast({
            messages: [
              'Stworzono klienta. Teraz możesz dodać adres i edytować skarbonkę',
            ],
            type: 'success',
            autoHideDuration: TOAST_DURATIONS.SM,
          });

          if (typeof this.props.onAfterSubmit === 'function') {
            return this.props.onAfterSubmit(newUser);
          }

          return this.props.history.push(
            `/admin/clients/edit/${newUser?.id}?openAddresses`
          );
        }
      },
      error => {
        const { propertyPath, message } =
          error?.response?.data?.violations?.[0];
        if (propertyPath === 'email' || propertyPath === 'plainPassword') {
          this.setState({
            loading: false,
          });
          return this.props.openToast({
            messages: [
              `${errorNotificationMapObject[propertyPath]}: ${message}`,
            ],
            type: 'error',
            autoHideDuration: TOAST_DURATIONS.SM,
          });
        } else if (propertyPath === 'invoiceVatNumber') {
          this.setState({
            loading: false,
          });
          return this.props.openToast({
            messages: [message],
            type: 'error',
            autoHideDuration: TOAST_DURATIONS.SM,
          });
        } else {
          this.setState({
            loading: false,
          });
          return this.props.openToast({
            messages: [
              message, //errors.checkFormAgain
            ],
            type: 'error',
            autoHideDuration: TOAST_DURATIONS.SM,
          });
        }
      }
    );
  };

  render() {
    const { classes, addNewUserOnNewOrderForm = false } = this.props;
    const {
      firstName,
      lastName,
      phone,
      email,
      plainPassword,
      plainPasswordMatch,
      gender,
      sexOptions,
      invoiceCompany,
      invoiceVatNumber,
      invoiceAddressCity,
      invoiceAddressBuildNumber,
      invoiceAddressPlaceNumber,
      invoiceAddressPostCode,
      invoiceAddressStreet,
      invoiceComments,
      referenceCode: givenReferenceCode,
      knowAboutUsOptions,
      knowAboutUs,
      knowAboutUsOther,
    } = this.state;

    const { t } = this.props;
    const referenceCode =
      givenReferenceCode === null ? '' : givenReferenceCode.code;

    return (
      <form
        style={{ opacity: this.state.fetching ? 0 : 1, transition: '0.5s all' }}
      >
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem sm={4}>
                <h4>{this.props.t('clients.basicInformation')}</h4>
                <FormTextInput
                  label={this.props.t('clients.name') + ' *'}
                  classes={classes}
                  name="firstName"
                  value={firstName}
                  handleChange={this.handleInputChange}
                  inputSize={12}
                  maxLength={64}
                />
                <FormTextInput
                  label={this.props.t('clients.surname') + ' *'}
                  classes={classes}
                  name="lastName"
                  value={lastName}
                  handleChange={this.handleInputChange}
                  inputSize={12}
                  maxLength={64}
                />
                <ReactPhoneNumberAdapter
                  label={this.props.t('clients.phone')}
                  name="phone"
                  value={phone}
                  onChange={this.handlePhoneChange}
                  inputSize={12}
                />
                <SelectInput
                  dataCy="select__gender"
                  classes={classes}
                  label={this.props.t('clients.gender')}
                  options={sexOptions}
                  value={gender}
                  mapBy="value"
                  trackBy="@id"
                  name="gender"
                  handleChange={this.handleInputChange}
                  id="gender"
                  size={12}
                />
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginBottom: '5px' }}
                >
                  {this.props.t('clients.birthdayDate', 'Data urodzenia')}
                </FormLabel>
                <Datetime
                  dateFormat={moment.localeData().longDateFormat('L')}
                  timeFormat={false}
                  value={new moment(this.state.birthday)}
                  onChange={e =>
                    this.setState({ birthday: e.format('YYYY-MM-DD') })
                  }
                  inputProps={{
                    readOnly: true,
                  }}
                  locale="pl"
                />
                <FormTextInput
                  label={this.props.t('clients.refCode')}
                  style={{ marginTop: '2px' }}
                  classes={classes}
                  name="email"
                  value={referenceCode}
                  handleChange={this.handleInputChange}
                  inputSize={12}
                  disabled
                />
                <SelectInput
                  classes={classes}
                  label={this.props.t('clients.fromYouKnow')}
                  options={[
                    ...knowAboutUsOptions,
                    { value: t('clients.fromYouKnow.other.label') },
                  ]}
                  value={knowAboutUs}
                  mapBy="value"
                  trackBy="value"
                  name="knowAboutUs"
                  handleChange={this.handleInputChange}
                  id="knowAboutUs"
                  size={12}
                  dataCy="select__knowAboutUs"
                />
                {knowAboutUs === t('clients.fromYouKnow.other.label') && (
                  <FormTextInput
                    disabled={
                      knowAboutUs !== t('clients.fromYouKnow.other.label')
                    }
                    multiline
                    label={t('clients.fromYouKnow.other.label')}
                    classes={classes}
                    name="knowAboutUsOther"
                    value={knowAboutUsOther}
                    handleChange={this.handleInputChange}
                    inputSize={12}
                    placeholder={t('clients.fromYouKnow.other.placeholder')}
                  />
                )}
                <FormTextInput
                  label={this.props.t('clients.emailAddress') + ' *'}
                  classes={classes}
                  name="email"
                  value={email}
                  noAutoComplete
                  handleChange={this.handleInputChange}
                  inputSize={12}
                />
                <FormTextInput
                  label={
                    this.isEdit
                      ? this.props.t('clients.newPassword')
                      : this.props.t('clients.password') + ' *'
                  }
                  classes={classes}
                  name="plainPassword"
                  value={plainPassword}
                  type="password"
                  noAutoComplete
                  handleChange={this.handleInputChange}
                  inputSize={12}
                />
                <FormTextInput
                  label={
                    this.isEdit
                      ? this.props.t('clients.repeatNewPassword')
                      : this.props.t('clients.repeatPassword') + ' *'
                  }
                  classes={classes}
                  name="plainPasswordMatch"
                  value={plainPasswordMatch}
                  success={
                    this.state.plainPassword !== '' &&
                    this.state.plainPassword === this.state.plainPasswordMatch
                  }
                  error={
                    !(
                      this.state.plainPassword === this.state.plainPasswordMatch
                    )
                  }
                  type="password"
                  handleChange={this.handleInputChange}
                  inputSize={12}
                />
              </GridItem>
              <GridItem sm={4}>
                <h4>{this.props.t('clients.invoiceData')}</h4>
                <FormTextInput
                  label={this.props.t('clients.companyName')}
                  classes={classes}
                  name="invoiceCompany"
                  value={invoiceCompany}
                  handleChange={this.handleInputChange}
                  inputSize={12}
                />
                <FormTextInput
                  label={this.props.t('clients.taxId')}
                  classes={classes}
                  name="invoiceVatNumber"
                  value={invoiceVatNumber}
                  handleChange={this.handleInputChange}
                  inputSize={12}
                />
                <FormTextInput
                  label={this.props.t('clients.city')}
                  classes={classes}
                  name="invoiceAddressCity"
                  value={invoiceAddressCity}
                  handleChange={this.handleInputChange}
                  inputSize={12}
                />
                <FormTextInput
                  label={this.props.t('clients.postCode')}
                  classes={classes}
                  error={!postCodeValidator(invoiceAddressPostCode)}
                  success={postCodeValidator(invoiceAddressPostCode)}
                  value={invoiceAddressPostCode}
                  onChange={this.handleInputChange}
                  name="invoiceAddressPostCode"
                  inputSize={12}
                />
                <FormTextInput
                  label={this.props.t('clients.street')}
                  classes={classes}
                  name="invoiceAddressStreet"
                  value={invoiceAddressStreet}
                  handleChange={this.handleInputChange}
                  inputSize={12}
                />
                <FormTextInput
                  label={this.props.t('clients.buildingNo')}
                  classes={classes}
                  name="invoiceAddressBuildNumber"
                  value={invoiceAddressBuildNumber}
                  handleChange={this.handleInputChange}
                  inputSize={12}
                />
                <FormTextInput
                  label={this.props.t('clients.localNo')}
                  classes={classes}
                  name="invoiceAddressPlaceNumber"
                  value={invoiceAddressPlaceNumber}
                  handleChange={this.handleInputChange}
                  inputSize={12}
                />
                <FormTextInput
                  style={{ marginTop: '6px' }}
                  label={this.props.t('clients.notesToInvoice')}
                  classes={classes}
                  name="invoiceComments"
                  value={invoiceComments}
                  handleChange={this.handleInputChange}
                  inputSize={12}
                />
              </GridItem>
              <GridItem sm={4}>
                <h4>{this.props.t('clients.userAgree')}</h4>
                <FormLabel
                  style={{ marginTop: '10px' }}
                  className={classes.labelHorizontal}
                >
                  {this.props.t('clients.mark')}
                </FormLabel>
                <Divider style={{ marginBottom: '15px' }} />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.marketingTerms}
                      onChange={e => {
                        this.setState({
                          marketingTerms: !this.state.marketingTerms,
                        });
                      }}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  label={
                    this.state.marketingTermsContent
                      ? this.state.marketingTermsContent
                      : this.props.t('clients.agreeMarketing')
                  }
                />
                <FormLabel
                  style={{ marginTop: '20px' }}
                  className={classes.labelHorizontal}
                >
                  {this.props.t('clients.messagesTerms')}
                </FormLabel>
                <Divider style={{ marginBottom: '15px' }} />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.messagesTerms}
                      onChange={e => {
                        this.setState({
                          messagesTerms: !this.state.messagesTerms,
                        });
                      }}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  label={
                    this.state.messagesTermsContent
                      ? this.state.messagesTermsContent
                      : this.props.t('clients.messagesTerms')
                  }
                />
                <FormLabel
                  style={{ marginTop: '20px' }}
                  className={classes.labelHorizontal}
                >
                  {this.props.t('clients.smsClientTags', 'Tagi sms')}
                </FormLabel>
                <Divider style={{ marginBottom: '15px' }} />
                <SelectInput
                  noGrid
                  classes={classes}
                  mapBy="value"
                  trackBy="@id"
                  name="smsClientTags"
                  multiple={true}
                  value={this.state.smsClientTags}
                  options={this.props.smsClientTags}
                  disabled={false}
                  handleChange={event => {
                    this.handleChange(event);
                  }}
                  customStyle={{ width: '50px' }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>

          <CardBody>
            {this.userId && (
              <>
                <RecordFieldHistory
                  iri={`/clients/${this.userId}`}
                  defaultField="email"
                  fields={[
                    {
                      name: this.props.t('clients.emailAddress'),
                      val: 'email',
                    },
                    {
                      name: this.props.t('clients.phone'),
                      val: 'phone.number',
                    },
                    {
                      name: this.props.t('clients.agreeMarketing'),
                      val: 'marketingTerms',
                    },
                  ]}
                />
              </>
            )}
          </CardBody>
        </Card>
        {addNewUserOnNewOrderForm && (
          <FormControlButtons
            t={this.props.t}
            classes={classes}
            discardText={this.props.t('form.cancel')}
            submitText={this.props.t('form.save')}
            cancelPath={this.props.cancelPath}
            handleSubmit={this.handleSubmit}
            history={false}
            customOffsetSmall={'13px'}
            customOffsetLarge={'13px'}
            isFixedToBottom={true}
          />
        )}
        {!this.isEdit && !addNewUserOnNewOrderForm && (
          <FormControlStickyButton
            t={this.props.t}
            classes={classes}
            discardText={this.props.t('form.cancel')}
            submitText={this.props.t('form.save')}
            cancelPath={this.props.cancelPath}
            handleSubmit={this.handleSubmit}
            history={false}
            customOffsetSmall={'13px'}
            customOffsetLarge={'13px'}
            isFixedToBottom={true}
          />
        )}
        {this.isEdit && (
          <LogView
            t={this.props.t}
            classes={classes}
            discardText={this.props.t('form.cancel')}
            submitText={this.props.t('form.save')}
            cancelPath={this.props.cancelPath}
            handleSubmit={this.handleSubmit}
            iri={`/clients/${this.userId}`}
            customOffsetSmall={'43px'}
            customOffsetLarge={'43px'}
          />
        )}
      </form>
    );
  }
}

const mapStateToProps = state => ({
  user: state.Users.user,
  brands: state.Auth.user.brands,
  brand: state.Auth.selectedBrand,
  selectedRegionForValidations: state.Brands.selectedRegionForValidations,
  dictionary: state.Dictionary,
  smsClientTags: state.Dictionary.smsClientTags,
});

const mapDispatchToProps = {
  fetchUser,
  clearUser,
  fetchSexes,
  fetchHowKnow,
  fetchSmsTagsForClients,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withToast,
  withRouter,
  withTranslation()
);

export default enhance(GeneralInfo);
